import React from "react";
import { TextField, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineSharp";

const TagInput = ({ label, id, tags = [], setTags }) => {
  return (
    <div>
      <Autocomplete
        multiple
        id={id}
        options={[]}
        value={tags}
        freeSolo
        name="skills"
        onChange={(e) => {
          if (e.target.value && e.target.value.length < 1) return null;
          if (e.target.value && e.target.value.length > 50) return null;
          if (tags.length > 4) {
            return null;
          }
          let sk = tags;
          sk.push(e.target.value);
          setTags([...sk]);
        }}
        renderTags={() => {}}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            helperText="Write tags related to content then press enter, max 5 tags allowed."
            size="medium"
            label={label}
          />
        )}
      />
      <div className="py-2">
        {tags &&
          tags.length > 0 &&
          tags.map((option, index) => {
            if (option === undefined) return null;
            const label = option.title ?? option;
            return (
              <Chip
                key={label + index + ""}
                label={label}
                clickable
                color="primary"
                className="m-1"
                size="small"
                variant="outlined"
                deleteIcon={<RemoveIcon fontSize="small" />}
                onDelete={() => {
                  let sk = tags;
                  let nsk = sk.filter((entry) => entry !== option);
                  setTags([...nsk]);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TagInput;
