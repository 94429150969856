const initState = {
  authError: null,
  isAuthenticated: false,
  signInExpired: false,
  user: {},
  profilePic: null,
  signinLogs: null,
  user_leaderboard: null,
  msg: "",
  institute_leaderboard: null,
  msg2: "",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        authError: null,
        isAuthenticated: true,
        user: action.payload,
        signInExpired: false,
      };
    case "BECOME_INSTRUCTOR":
      return {
        ...state,
        user: { ...state.user, role: "instructor" },
      };
    case "CREATE_INSTITUTE":
      let owner = action.payload;
      let tm = state.user;
      tm.owner = owner;
      return {
        ...state,
        user: { ...tm },
      };
    case "PRIEST_STATUS_CHANGE":
      let tmp2 = state.user;
      return {
        ...state,
        user: {
          ...tmp2,
          priestStatus:
            action.payload.api === "joinAsPriest" ? "request" : "none",
        },
      };
    case "DELETE_INSTITUTE":
      let tmp = state.user;
      tmp.owner = null;
      return {
        ...state,
        user: { ...tmp },
      };
    case "SIGNIN_ERROR":
      return {
        ...state,
        authError: "Login Error",
        isAuthenticated: false,
        signInExpired: false,
        user: {},
      };
    case "SIGNIN_EXPIRED":
      return {
        ...state,
        isAuthenticated: false,
        signInExpired: true,
        user: {},
        authError: null,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "SIGNOUT_USER":
      return {
        ...state,
        authError: null,
        isAuthenticated: false,
        user: {},
        profilePic: null,
        signInExpired: false,
      };
    case "EDIT_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "SET_PROFILE":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case "SET_PROFILE_PIC":
      return {
        ...state,
        profilePic: action.payload,
      };
    case "SET_ENROLLED_COURSES":
      var user = state.user.list_of_course_enroll.push(action.payload.courseId);
      return {
        ...state,
        user,
      };
    case "SIGNIN_LOGS":
      let logs = action.payload.logs.reverse();
      return {
        ...state,
        signinLogs: { ...action.payload, logs },
      };
    case "USER_BETA_ACCESS":
      let usr = state.user;
      return {
        ...state,
        user: { ...usr, beta: action.payload, betaStatus: false },
      };
    case "USER_LEADERBOARD":
      return {
        ...state,
        user_leaderboard: [...action.payload],
      };
    case "LOAD_USER_LEADERBOARD":
      if (action.payload.length === 0) {
        return {
          ...state,
          msg: "no more data",
        };
      } else {
        return {
          ...state,
          user_leaderboard: [...state.user_leaderboard, ...action.payload],
        };
      }
    case "INSTITUTE_LEADERBOARD":
      return {
        ...state,
        institute_leaderboard: [...action.payload],
      };
    case "LOAD_INSTITUTE_LEADERBOARD":
      if (action.payload.length === 0) {
        return {
          ...state,
          msg2: "no more data",
        };
      } else {
        return {
          ...state,
          institute_leaderboard: [
            ...state.institute_leaderboard,
            ...action.payload,
          ],
        };
      }
    case "LIVE_DARSHAN_WATCH_SCORE":
      return {
        ...state,
        user: { ...action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
