const initState = {
  temples: [],
  channels: [],
  liveDarshan: [],
  temple: {
    id: "",
    name: "",
    videoUrl: "",
    description: "",
    thumbnail: null,
  },
  channelMsg: "",
  msg: "",
};
const templeReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ALL_TEMPLES":
      if (action.payload.length > 0) {
        // below if condition to remove duplicate data
        let temp = state.temples;
        let act = action.payload;
        let t = temp.concat(act);
        let temp2b = t.filter(
          (v, i, a) => a.findIndex((t) => t._id === v._id) === i
        );
        return {
          ...state,
          temples: temp2b,
          msg: "",
        };
      } else {
        return {
          ...state,
          msg: "No More data",
        };
      }
    case "LIVE_DARSHAN":
      return {
        ...state,
        liveDarshan: [...action.payload],
      };
    case "ADD_TEMPLE":
      let temp = [];
      temp = [action.payload, ...state.temples];
      return {
        ...state,
        temples: temp,
        temple: {
          id: "",
          name: "",
          videoUrl: "",
          description: "",
          thumbnail: null,
        },
      };
    case "EDIT_TEMPLE":
      return {
        ...state,
        temple: {
          name: action.payload.name,
          videoUrl: action.payload.videoUrl,
          description: action.payload.description,
          id: action.payload._id,
          thumbnail: action.payload.thumbnail,
        },
      };
    case "CLEAR_EDIT_TEMPLE":
      return {
        ...state,
        temple: {
          id: "",
          name: "",
          videoUrl: "",
          description: "",
          thumbnail: null,
        },
      };
    case "EDIT_TEMPLE_UPDATE":
      console.log(action.payload, state.temples);
      let temp1 = state.temples.filter((i) => {
        return i._id !== action.payload._id;
      });
      return {
        ...state,
        temples: [action.payload, ...temp1],
        temple: {
          id: "",
          name: "",
          videoUrl: "",
          description: "",
          thumbnail: null,
        },
      };
    case "DELETE_TEMPLE":
      let temp2 = state.temples.filter((i) => {
        return i._id !== action.payload;
      });
      return {
        ...state,
        temples: temp2,
      };
    case "ADD_CHANNEL":
      let old = state.channels;
      //console.log(old, action.payload);
      return {
        ...state,
        channels: [action.payload, ...old],
      };
    case "CHANNEL_LIST":
      if (action.payload.length > 0) {
        // below if condition to remove duplicate data
        let temp2 = state.channels;
        let act2 = action.payload;
        let t2 = temp2.concat(act2);
        let temp2b2 = t2.filter(
          (v, i, a) => a.findIndex((t) => t._id === v._id) === i
        );

        return {
          ...state,
          channels: temp2b2,
          channelMsg: "",
        };
      } else {
        console.log("tests");
        return {
          ...state,
          channelMsg: "No More data",
        };
      }
    case "CHANNEL_DELETE":
      let chn = state.channels.filter((ch) => ch._id !== action.payload);
      return {
        ...state,
        channels: [...chn],
      };
    default:
      return state;
  }
};

export default templeReducer;
