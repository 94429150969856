const initState = {
  list_of_features: null,
  feedback: [],
  msg: "",
};

const betaProgramReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_FEATURES":
      return {
        ...state,
        list_of_features: payload,
      };
    case "LOAD_MORE_FEATURES":
      let tmp = [...state.list_of_features, ...payload];
      let msg = "";
      if (payload.length === 0) {
        msg = "No more data";
      }
      return {
        ...state,
        list_of_features: [...tmp],
        msg: msg,
      };
    case "REGISTER_FEATURE":
      let tmp2 = [payload, ...state.list_of_features];
      return { ...state, list_of_features: [...tmp2] };
    case "FEATURE_STATUS":
      let tmp3 = state.list_of_features;
      let i = tmp3.findIndex((el) => el._id === payload.featureId);
      if (i !== -1) {
        tmp3[i].status = payload.status;
        return { ...state, list_of_features: [...tmp3] };
      } else {
        return { ...state };
      }
    case "FEATURE_DELETE":
      let tmp4 = state.list_of_features.filter((el) => el._id !== payload);
      return {
        ...state,
        list_of_features: [...tmp4],
      };
    case "FEATURE_FEEDBACK":
      return {
        ...state,
        feedback: [...payload],
      };
    case "LOAD_MORE_FEATURE_FEEDBACK":
      let tmp5 = [...state.feedback, ...payload];
      let msg2 = "";
      if (payload.length === 0) {
        msg2 = "No more data";
      }
      return {
        ...state,
        feedback: [...tmp5],
        msg2: msg2,
      };
    default:
      return state;
  }
};

export default betaProgramReducer;
