import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../../payment/Cart";
import { NavLink, Link } from "react-router-dom";
import { signOut } from "../../../store/actions/authActions";
import BootstrapTooltip from "../../layout/Tooltip";

import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FaceIcon from "@material-ui/icons/Face";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CameraFrontOutlinedIcon from "@material-ui/icons/CameraFrontOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import EventIcon from "@material-ui/icons/Event";
import TuneIcon from "@material-ui/icons/Tune";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const AuthNav = () => {
  const authorization = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="collapse navbar-collapse" id="ganpatiNavbar">
        <ul className="navbar-nav ml-auto">
          {authorization.isAuthenticated &&
            authorization.user.role !== "moderator" && (
              <li className="nav-item">
                <BootstrapTooltip title="Cart" interactive>
                  <NavLink className="nav-link" to="/checkout">
                    <Cart />
                  </NavLink>
                </BootstrapTooltip>
              </li>
            )}
          <li className="nav-item">
            <BootstrapTooltip title="Dashboard" interactive>
              <NavLink className="nav-link text-primary" to="/dashboard">
                <DashboardOutlinedIcon />
              </NavLink>
            </BootstrapTooltip>
          </li>
          {authorization.isAuthenticated && authorization.user.role === "user" && (
            <li className="nav-item">
              <BootstrapTooltip title="Hire Priest" interactive>
                <NavLink className="nav-link text-primary" to="/hirePriest">
                  <CameraFrontOutlinedIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          )}
          {authorization.isAuthenticated &&
          authorization.user.role === "user" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Become instructor" interactive>
                <NavLink className="nav-link text-primary" to="/benefits">
                  <NewReleasesOutlinedIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization &&
          authorization.user.role === "instructor" &&
          !authorization.user.owner &&
          !authorization.user.institution ? (
            <li className="nav-item">
              <BootstrapTooltip title="Create Institution" interactive>
                <NavLink
                  className="nav-link text-primary"
                  to="/institution/create"
                >
                  <LocalLibraryOutlinedIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {(authorization && authorization.user.role === "admin") ||
          authorization.user.role === "instructor" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Create Course" interactive>
                <NavLink className="nav-link text-primary" to={`/createcourse`}>
                  <AddCircleOutlineIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization && authorization.user.role === "instructor" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Create Blog" interactive>
                <NavLink className="nav-link text-primary" to={`/blogs/create`}>
                  <i className="fas fa-blog fa-lg pt-2"></i>
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {(authorization && authorization.user.role === "user") ||
          authorization.user.role === "instructor" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Find yoga buddy" interactive>
                <NavLink className="nav-link text-primary" to="/findyogabuddy">
                  <WhatshotIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization &&
          (authorization.user.role === "instructor" ||
            authorization.user.role === "admin") ? (
            <li className="nav-item">
              <BootstrapTooltip title="New Event" interactive>
                <NavLink className="nav-link text-primary" to={`/events/new`}>
                  <EventIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization.user.role === "admin" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Management" interactive>
                <NavLink
                  className="nav-link text-primary"
                  to={`/admin/overview`}
                >
                  <TuneIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization.isAuthenticated &&
            authorization.user.role === "instructor" &&
            authorization.user.priestStatus === "accept" && (
              <li className="nav-item">
                <BootstrapTooltip title="Client chats" interactive>
                  <NavLink className="nav-link text-primary" to="/clientchats">
                    <ForumOutlinedIcon />
                  </NavLink>
                </BootstrapTooltip>
              </li>
            )}
          {authorization.user.role === "moderator" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Management" interactive>
                <NavLink
                  className="nav-link text-primary"
                  to={`/moderator/dashboard`}
                >
                  <TuneIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          {authorization.user.role === "instructor" ? (
            <li className="nav-item">
              <BootstrapTooltip title="Management" interactive>
                <NavLink
                  className="nav-link text-primary"
                  to={`/instructor/dashboard`}
                >
                  <TuneIcon />
                </NavLink>
              </BootstrapTooltip>
            </li>
          ) : null}
          <li className="nav-item">
            <BootstrapTooltip title="Leaderboard" interactive>
              <NavLink
                className="nav-link text-primary"
                to={`/leaderboard/${authorization.user._id}`}
              >
                <AssessmentOutlinedIcon />
              </NavLink>
            </BootstrapTooltip>
          </li>
          {authorization.isAuthenticated &&
            authorization.user.role !== "moderator" && (
              <li className="nav-item">
                <BootstrapTooltip title="Purchase History" interactive>
                  <NavLink className="nav-link text-primary" to="/transactions">
                    <AccountBalanceWalletIcon />
                  </NavLink>
                </BootstrapTooltip>
              </li>
            )}
          <li className="nav-item">
            <BootstrapTooltip title="Profile" interactive>
              <NavLink className="nav-link text-primary" to={`/profile`}>
                <FaceIcon />
              </NavLink>
            </BootstrapTooltip>
          </li>
          <li className="nav-item">
            <BootstrapTooltip title="Sign Out" interactive>
              <Link
                className="nav-link text-primary"
                to={"/"}
                onClick={() => dispatch(signOut())}
              >
                <ExitToAppIcon />
              </Link>
            </BootstrapTooltip>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default AuthNav;
