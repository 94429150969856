import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";

import NotFound from "./components/404";

import PrivateRoute from "./components/auth/routes/PrivateRoute";
import AdminRoute from "./components/auth/routes/AdminRoute";
import InstructorRoute from "./components/auth/routes/InstructorRoute";
import BothRoute from "./components/auth/routes/BothRoute";
import ModeratorRoute from "./components/auth/routes/ModeratorRoute";

import AdminEditCourse from "./components/admin/course/AdminEditCourse";
import InstructorEditCourse from "./components/instructor/courses/InstructorEditCourse";

//* code splitter only load that code which currently in use
const HomePage = lazy(() => import("./components/main/HomePage"));
const About = lazy(() => import("./components/main/About"));
const Contact = lazy(() => import("./components/main/Contact"));
const SignUp = lazy(() => import("./components/auth/SignUp"));
const SignIn = lazy(() => import("./components/auth/SignIn"));
const GotoEmail = lazy(() => import("./components/auth/GotoEmail"));
const Activate = lazy(() => import("./components/auth/Activate"));
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/auth/ResetPassword"));
const EditUser = lazy(() => import("./components/user/EditUser"));
const Profile = lazy(() => import("./components/user/Profile"));
const AdminDashboardLayout = lazy(() =>
  import("./components/admin/AdminDashboardLayout")
);
const CreateCourse = lazy(() =>
  import("./components/course/courseManage/CreateCourse")
);
const DetailsCourseView = lazy(() =>
  import("./components/course/DetailsCourseView")
);
const CertificateVerify = lazy(() =>
  import("./components/course/CertificateVerify")
);
const CourseView = lazy(() => import("./components/course/CourseView"));
const LeaderBoard = lazy(() => import("./components/user/LeaderBoard"));
const PublicProfile = lazy(() => import("./components/user/PublicProfile"));
const InstructorBenefit = lazy(() =>
  import("./components/instructor/InstructorBenefit")
);
const InstructorCourseView = lazy(() =>
  import("./components/instructor/InstructorCourseView")
);
const InstructorVideoPage = lazy(() =>
  import("./components/instructor/contentInterface")
);
const SearchCourses = lazy(() => import("./components/course/SearchCourses"));
const Checkout = lazy(() => import("./components/payment/Checkout"));
const PurchaseHistory = lazy(() => import("./components/user/PurchaseHistory"));
const Donate = lazy(() => import("./components/payment/Donate"));
const InstructorDashboardLayout = lazy(() =>
  import("./components/instructor/DashboardLayout")
);
const CreateBlog = lazy(() =>
  import("./components/instructor/blogs/CreateBlog")
);
const BlogList = lazy(() => import("./components/instructor/blogs/BlogList"));
const BlogFullView = lazy(() =>
  import("./components/instructor/blogs/BlogFullView")
);
const BlogPrivateView = lazy(() =>
  import("./components/instructor/blogs/BlogPrivateView")
);
const EditBlog = lazy(() => import("./components/instructor/blogs/EditBlog"));
const CreateInstitution = lazy(() =>
  import("./components/instructor/institution/CreateInstitution")
);
const EditInstitution = lazy(() =>
  import("./components/instructor/institution/EditInstitution")
);
const InstitutionDetails = lazy(() =>
  import("./components/instructor/institution/InstitutionDetails")
);
const Receipt = lazy(() => import("./components/payment/Receipt"));
const Quiz = lazy(() => import("./components/exams/Quiz"));
const Result = lazy(() => import("./components/exams/Result"));
const Exam = lazy(() => import("./components/exams/Exam"));
const Privacy = lazy(() => import("./components/policy/PrivacyPolicy"));
const TermsAndCondition = lazy(() =>
  import("./components/policy/TermsAndCondition")
);
const PriestList = lazy(() => import("./components/priest/PriestList"));
const ModeratorDashboardLayout = lazy(() =>
  import("./components/moderator/DashboardLayout")
);
const PriestChatBox = lazy(() => import("./components/priest/PriestChatBox"));
const ChatBox = lazy(() => import("./components/priest/ChatBox"));
const PriestBenefit = lazy(() => import("./components/priest/PriestBenefits"));
const CreateEvent = lazy(() => import("./components/events/CreateEvent"));
const EditEvent = lazy(() => import("./components/events/EditEvent"));
const SingleEvent = lazy(() => import("./components/events/SingleEvent"));
const FindBuddy = lazy(() => import("./components/yogabuddy/FindBuddy"));
const VideoInterface = lazy(() => import("./components/contentInterface"));
const Dashboard = lazy(() => import("./components/dashboard"));
const ViewShareableCard = lazy(() =>
  import("./components/shareableCard/ViewShareableCard")
);

const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        {/** Public Routes without login as well as logged in */}
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/signup" component={SignUp} />
        <Route path="/signin" component={SignIn} />
        <Route path="/priestbenefits" component={PriestBenefit} />
        <Route path="/courseview/:courseId" component={CourseView} />
        <Route path="/verify" component={CertificateVerify} />
        <Route path="/gotoemail" component={GotoEmail} />
        <Route path="/auth/activate/:token" component={Activate} />
        <Route path="/auth/password/forgot" component={ForgotPassword} />
        <Route path="/auth/password/reset/:token" component={ResetPassword} />
        <Route path="/search" component={SearchCourses} />
        <Route path="/benefits" component={InstructorBenefit} />
        <Route path="/donate" component={Donate} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/tandc" component={TermsAndCondition} />
        <Route exact path="/blogs" component={BlogList} />
        <Route path="/blogview/:blogId" component={BlogFullView} />
        <Route path="/institute/:instituteId" component={InstitutionDetails} />
        <Route path="/events/view/:eventId" component={SingleEvent} />

        {/** Private Routes by logged in user*/}
        <PrivateRoute path="/publicprofile/:userid" component={PublicProfile} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/findyogabuddy" component={FindBuddy} />
        <PrivateRoute path="/payment/receipt" component={Receipt} />
        <PrivateRoute path="/chatbox/:priestId" component={ChatBox} />
        <PrivateRoute path="/clientchats" component={PriestChatBox} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/transactions" component={PurchaseHistory} />
        <PrivateRoute path="/checkout" component={Checkout} />
        <PrivateRoute path="/hirePriest" component={PriestList} />
        <PrivateRoute path="/editUser" component={EditUser} />
        <PrivateRoute path="/leaderboard/:userId" component={LeaderBoard} />
        <PrivateRoute path="/course/:courseId" component={DetailsCourseView} />
        <PrivateRoute
          path="/contentPlayer/:courseId"
          component={VideoInterface}
        />
        <PrivateRoute path="/exam/quiz" component={Quiz} />
        {/** Result Page need quizId in location Props */}
        <PrivateRoute path="/result" component={Result} />
        <PrivateRoute path="/exam/conduct/student" component={Exam} />
        <PrivateRoute
          path="/shareableCard/view/:cardUrl"
          component={ViewShareableCard}
        />

        {/**Both Routes either admin or instructor */}
        <BothRoute path="/createcourse" component={CreateCourse} />
        <BothRoute
          path="/instructor/view/:courseId"
          component={InstructorCourseView}
        />
        <BothRoute
          path="/instructor/contentPlayer/:courseId"
          component={InstructorVideoPage}
        />
        <BothRoute
          path="/instructorEditCourse/:userId/:courseId"
          component={InstructorEditCourse}
        />
        <BothRoute path="/events/new" component={CreateEvent} />
        <BothRoute path="/events/edit/:eventId" component={EditEvent} />

        {/** Admin Routes */}
        <AdminRoute path="/admin/overview" component={AdminDashboardLayout} />
        <AdminRoute
          path="/editCourse/:userId/:courseId"
          component={AdminEditCourse}
        />

        {/** Moderator Routes */}
        <ModeratorRoute
          path="/moderator/dashboard"
          component={ModeratorDashboardLayout}
        />

        {/**Instructor Routes */}
        <InstructorRoute
          path="/instructor/dashboard"
          component={InstructorDashboardLayout}
        />
        <InstructorRoute
          component={BlogPrivateView}
          path="/blogprivateview/:blogId"
        />
        <InstructorRoute path="/blogs/create" component={CreateBlog} />
        <InstructorRoute path="/blog/edit/:blogId" component={EditBlog} />
        <InstructorRoute
          path="/institution/create"
          component={CreateInstitution}
        />
        <InstructorRoute
          path="/institution/edit/:instituteId"
          component={EditInstitution}
        />

        {/** 404 Route */}
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Router;
