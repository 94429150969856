const initState = {
  priestList: null,
  msg: "",
  chatHistory: null,
  messages: null,
  chatBlockStatus: false,
  chatBlockedBy: null,
  noChatHistory: false,
  chatUsers: null,
  msg2: "", //? no chat users
};

const priestReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_PRIEST":
      return {
        ...state,
        priestList: [...payload],
      };
    case "LOAD_MORE_PRIEST":
      let msg1 = "";
      if (payload.length === 0) {
        msg1 = "No More Data";
      }
      return {
        ...state,
        priestList: [...state.priestList, ...payload],
        msg: msg1,
      };
    case "CHAT_HISTORY":
      let messages = payload.messages;
      let block = payload.block;
      // console.log(payload.blockedBy);
      let blockedBy = payload.blockedBy;
      payload.messages = undefined;
      payload.blockedBy = undefined;
      payload.block = undefined;
      //console.log(blockedBy);
      return {
        ...state,
        chatHistory: payload,
        chatBlockStatus: block,
        chatBlockedBy: blockedBy,
        messages: [...messages],
        noChatHistory: false,
      };
    case "NO_CHAT_HISTORY":
      return {
        ...state,
        noChatHistory: true,
        chatHistory: null,
        messages: null,
      };
    case "FIRST_SAVE_CHAT":
      let messages2 = payload.messages;
      let block2 = payload.block;
      let blockedBy2 = payload.blockedBy;
      payload.messages = undefined;
      payload.blockedBy = undefined;
      payload.block = undefined;
      return {
        ...state,
        chatHistory: payload,
        chatBlockStatus: block2,
        chatBlockedBy: blockedBy2,
        messages: [...messages2],
        noChatHistory: false,
      };
    case "SAVE_CHAT":
      return {
        ...state,
        messages: [...state.messages, payload],
        noChatHistory: false,
      };
    case "GET_CHAT_USERS":
      return {
        ...state,
        chatUsers: [...payload],
      };
    case "LOAD_CHAT_USERS":
      if (payload.length > 0) {
        return {
          ...state,
          chatUsers: [...state.chatUsers, ...payload],
        };
      } else {
        return {
          ...state,
          msg2: "No more data",
        };
      }
    case "BLOCK_CHAT":
      let tmp2 = state.chatUsers;
      if (tmp2) {
        let index = tmp2.findIndex((chat) => chat._id === payload.chatId);
        if (index !== -1) {
          tmp2[index].block = payload.block === true ? true : false;
          tmp2[index].blockedBy = payload.userId;
        }
        return {
          ...state,
          chatBlockStatus: payload.block === true ? true : false,
          chatBlockedBy: payload.userId,
          chatUsers: [...tmp2],
        };
      } else {
        return {
          ...state,
          chatBlockStatus: payload.block === true ? true : false,
          chatBlockedBy: payload.userId,
        };
      }
    case "CLEAR_CHAT_HISTORY":
      console.log("chat_histroy cleared");
      return {
        ...state,
        chatHistory: null,
        messages: null,
        chatBlockStatus: false,
        chatBlockedBy: null,
        noChatHistory: false,
      };
    default:
      return state;
  }
};

export default priestReducer;
