import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Chip,
  ButtonGroup,
  Select,
  TextField,
  MenuItem,
  Fade,
  IconButton,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SendIcon from "@material-ui/icons/Send";
import AssistantPhotoOutlinedIcon from "@material-ui/icons/AssistantPhotoOutlined";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AddIcon from "@material-ui/icons/Add";
import { Status, Confirm } from "../../layout/Alerts";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector, useDispatch } from "react-redux";
import {
  createExam,
  getExamByInstructor,
  deleteExam,
} from "../../../store/actions/examAction";
import axios from "axios";

const CreateExam = ({ courseId }) => {
  const exam = useSelector((state) => state.exam.exam);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const [disable, setDisable] = useState(false);

  const [data, setData] = useState({
    examTitle: "",
    totalMarks: 1,
    passingMarks: 1,
    questions: [],
    duration: 1,
  });

  const [question, setQuestion] = useState({
    sequenceNo: 1,
    question: "",
    questionType: "text",
    correctAnswer: "",
    hasAttachment: "no",
    attachment: null,
    fileName: "",
    answerType: "text",
    mark: 1,
  });

  const [usedSequence, setUsedSequence] = useState([]);

  useEffect(() => {
    if (exam === null) {
      dispatch(getExamByInstructor({ courseId }));
      setData({
        examTitle: "",
        totalMarks: 1,
        passingMarks: 1,
        questions: [],
        duration: 1,
      });
      setQuestion({
        sequenceNo: 1,
        question: "",
        questionType: "text",
        hasAttachment: "no",
        correctAnswer: "",
        attachment: null,
        fileName: "",
        answerType: "text",
        mark: 1,
      });
      setUsedSequence([]);
    } else {
      setData({
        examTitle: exam.examTitle,
        totalMarks: exam.totalMarks,
        passingMarks: exam.passingMarks,
        questions: exam.questions,
        duration: exam.duration,
      });
      let nextMaxSeq =
        exam.questions[exam.questions.length - 1] !== undefined
          ? Number(exam.questions[exam.questions.length - 1].sequenceNo) + 1
          : 1;
      setQuestion({ ...question, sequenceNo: nextMaxSeq });
      let tmp = [];
      exam.questions.length > 0 &&
        exam.questions.forEach((que) => {
          tmp.push(que.sequenceNo);
        });
      setUsedSequence(tmp);
    } // eslint-disable-next-line
  }, [courseId, exam]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleChange1 = (e) => {
    if (e.target.name === "hasAttachment") {
      setQuestion({
        ...question,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "answerType") {
      setQuestion({
        ...question,
        [e.target.name]: e.target.value,
      });
    } else {
      setQuestion({
        ...question,
        [e.target.id]: e.target.value,
      });
    }
  };

  const fileChange = (e) => {
    if (e.target.files[0] === undefined) {
      Status({ text: "Please Select a file", type: "warning" });
      return;
    }
    const fsize = e.target.files[0].size;
    const type = e.target.files[0].type;
    const file = Math.round(fsize / 1024);

    if (
      type !== "application/pdf" &&
      type !== "image/png" &&
      type !== "image/jpeg"
    ) {
      Status({
        text: "<h5>Only PDF, TXT, PNG, JPEG file allowed.</h5>",
        type: "warning",
      });
      return;
    }
    if (file >= 4096) {
      Status({
        text: "File too Big, please select a file less than 4mb",
        type: "warning",
      });
      return;
    } else {
      setQuestion({
        ...question,
        attachment: e.target.files[0],
        fileName: e.target.files[0].name,
      });
    }
  };

  const addQuestion = (e) => {
    e.preventDefault();
    if (usedSequence.includes(Number(question.sequenceNo))) {
      Status({
        text: `<h5>Question No ${question.sequenceNo} is already used.</h5>`,
        type: "warning",
      });
      return;
    }
    if (question.question.trim().length < 5 || question.question.length > 350) {
      Status({
        text: `<h5>Question length must be between 5 to 350 characters.</h5>`,
        type: "warning",
      });
      return;
    }
    if (question.hasAttachment === "yes" && question.attachment === null) {
      Status({
        text: `<h5>Please add valid format question attachment.</h5>`,
        type: "warning",
      });
      return;
    }
    if (question.answerType === "mcq" && options.length < 2) {
      Status({
        text: `<h5>Please add atleast 2 options for mcq question.</h5>`,
        type: "warning",
      });
      return;
    }
    if (
      question.correctAnswer.trim().length < 1 ||
      question.correctAnswer.length > 350
    ) {
      Status({
        text: `<h5>There must be correct answer and have length 1 to 350.</h5>`,
        type: "warning",
      });
      return;
    }
    if (
      question.answerType === "mcq" &&
      !options.includes(question.correctAnswer)
    ) {
      Status({
        text: `<h5>Option must have correct answer</h5>`,
        type: "warning",
      });
      return;
    }
    setUsedSequence([...usedSequence, Number(question.sequenceNo)]);

    let tmp = {
      ...question,
      hasAttachment: question.hasAttachment === "yes" ? true : false,
      options: options,
    };
    let tmp2 = data.questions;
    tmp2.push(tmp);
    function compare(a, b) {
      const s1 = Number(a.sequenceNo);
      const s2 = Number(b.sequenceNo);
      let comparison = 0;
      if (s1 > s2) {
        comparison = 1;
      } else if (s1 < s2) {
        comparison = -1;
      }
      return comparison;
    }
    let tmp3 = tmp2.sort(compare);
    setData({ ...data, questions: tmp3 });
    let nextMaxSeq = Number(tmp3[tmp3.length - 1].sequenceNo) + 1;
    setQuestion({
      sequenceNo: nextMaxSeq,
      question: "",
      questionType: "text",
      hasAttachment: "no",
      correctAnswer: "",
      attachment: null,
      fileName: "",
      answerType: "text",
      mark: 1,
    });
    setOptions([]);
  };

  const deleteQuestion = async (sequenceNo) => {
    let ask = await Confirm({
      text: "<h5>Do you want to remove this question?</h5>",
    });
    if (ask === true) {
      //remove last sequence from used state
      let last = data.questions[data.questions.length - 1].sequenceNo;
      let tmp = usedSequence.filter((u) => u !== Number(last));
      setUsedSequence(tmp);
      //remove question from set of question
      let tmp2 = data.questions.filter(
        (ques) => Number(ques.sequenceNo) !== Number(sequenceNo)
      );
      // re-arrange question by sequence no
      tmp2.forEach((ques) => {
        if (Number(ques.sequenceNo) > Number(sequenceNo)) {
          ques.sequenceNo = Number(ques.sequenceNo) - 1;
        }
      });
      let nextMaxSeq =
        tmp2.length > 0 ? Number(tmp2[tmp2.length - 1].sequenceNo) + 1 : 1;
      setData({ ...data, questions: tmp2 });
      setQuestion({ ...question, sequenceNo: nextMaxSeq });
    }
  };

  const editQuestion = async (ques) => {
    let tmp = data.questions.filter(
      (q) => Number(q.sequenceNo) !== Number(ques.sequenceNo)
    );
    let tmp2 = usedSequence.filter((u) => u !== Number(ques.sequenceNo));
    if (tmp) {
      setQuestion({
        sequenceNo: ques.sequenceNo,
        question: ques.question,
        questionType: "text",
        hasAttachment: ques.hasAttachment === true ? "yes" : "no",
        attachment: ques.attachment,
        fileName: ques.fileName,
        correctAnswer: ques.correctAnswer,
        answerType: ques.answerType,
        mark: ques.mark,
      });
      if (ques.answerType === "mcq") {
        setOptions(ques.options);
      }
      setUsedSequence(tmp2);
      setData({ ...data, questions: tmp });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    setDisable(true);
    if (data.examTitle.trim().length < 4 || data.examTitle.length > 100) {
      Status({
        text: `<h5>Exam Title must be between 4 to 100 characters.</h5>`,
        type: "warning",
      });
      setDisable(false);
      return;
    }
    if (Number(data.passingMarks) > Number(data.totalMarks)) {
      Status({
        text: `<h5>Passing marks ${data.passingMarks} must be less then total marks ${data.totalMarks}.</h5>`,
        type: "warning",
      });
      setDisable(false);
      return;
    }

    if (data.questions.length < 1) {
      Status({
        text: `<h5>There must be atleast 1 question in exam.</h5>`,
        type: "warning",
      });
      setDisable(false);
      return;
    }
    if (data.duration < 1 || data.duration > 240) {
      Status({
        text: `<h5>Exam Duration must be between 1 min to 240 mins.</h5>`,
        type: "warning",
      });
      setDisable(false);
      return;
    }
    //? prepare data in valid format
    let attachments = new FormData();
    let questions = []; //? remove attachment from questions and make it seperate
    let marksCount = 0;
    let check = 0; // use to check that formData in empty or not
    data.questions.forEach((qu) => {
      //qu.attachment.path===undefined ,so that all new select file don't have this field, only those should added
      if (qu.hasAttachment === true && qu.attachment.path === undefined) {
        check = check + 1;
        attachments.append(
          "attachment",
          qu.attachment,
          qu.sequenceNo + "--" + qu.fileName
        );
        let obj = {
          ...qu,
        };
        marksCount = marksCount + Number(qu.mark);
        delete obj.hasAttachment;
        delete obj.fileName;
        delete obj.attachment;
        questions.push(obj);
      } else {
        marksCount = marksCount + Number(qu.mark);
        questions.push(qu);
      }
    });
    let examData = { ...data, questions, attempts: 4 };
    if (Number(marksCount) !== Number(data.totalMarks)) {
      Status({
        text: `<h5>Total Marks ${data.totalMarks} not equal to sum of marks of each question ${marksCount}.</h5>`,
        type: "warning",
      });
      setDisable(false);
      return;
    }
    if (check === 0) {
      //empty formData don't pass it
      dispatch(
        createExam({ examData, attachments: null, courseId, setDisable })
      );
    } else {
      dispatch(createExam({ examData, attachments, courseId, setDisable }));
    }
    setData({
      examTitle: "",
      totalMarks: 1,
      passingMarks: 1,
      questions: [],
      duration: 1,
    });
    setUsedSequence([]);
    setQuestion({
      sequenceNo: 1,
      question: "",
      questionType: "text",
      hasAttachment: "no",
      correctAnswer: "",
      attachment: null,
      fileName: "",
      answerType: "text",
      mark: 1,
    });
    setOptions([]);
  };

  const removeExam = async (examId) => {
    let ask = await Confirm({
      text: "<h5>Do you want to delete this exam from course?</h5>",
      type: "warning",
    });
    if (ask && examId) {
      dispatch(deleteExam(examId));
    }
  };

  return (
    <div>
      <div align="center">
        <Typography gutterBottom variant="h6">
          Add Exam
        </Typography>
        <Typography gutterBottom component="small" variant="caption">
          This is course final assessment exam, max attempt is 2 times.
        </Typography>
        {exam !== null ? (
          <div className="my-2">
            <button
              onClick={() => removeExam(exam._id)}
              className="btn btn-sm btn-outline-primary"
            >
              Remove Exam <RemoveIcon fontSize="small" />
            </button>
          </div>
        ) : null}
      </div>
      <form onSubmit={submit}>
        <div className="card">
          <div className="card-header">
            <FormControl fullWidth>
              <InputLabel htmlFor="examTitle">Exam Title</InputLabel>
              <Input
                id="examTitle"
                name="examTitle"
                type="text"
                value={data.examTitle}
                onChange={handleChange}
                required
                endAdornment={
                  <InputAdornment position="end">
                    {100 - (data.examTitle.length ?? 0)}{" "}
                    <TitleOutlinedIcon fontSize="small" />
                  </InputAdornment>
                }
                inputProps={{ minLength: 4, maxLength: 100 }}
              />
            </FormControl>
            <div className="my-2 row">
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="totalMarks">Total Marks</InputLabel>
                  <Input
                    id="totalMarks"
                    name="totalMarks"
                    type="number"
                    required
                    value={data.totalMarks}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <AssistantPhotoOutlinedIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{ min: 1, max: 1000 }}
                  />
                </FormControl>
              </div>
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="passingMarks">Passing Marks</InputLabel>
                  <Input
                    id="passingMarks"
                    name="passingMarks"
                    type="number"
                    required
                    value={data.passingMarks}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <EmojiEventsOutlinedIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{ min: 1, max: 1000 }}
                  />
                </FormControl>
              </div>
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="duration">Exam Duration</InputLabel>
                  <Input
                    id="duration"
                    name="duration"
                    type="number"
                    required
                    value={data.duration}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <TimerOutlinedIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{ min: 0, max: 240 }}
                  />
                </FormControl>
                <FormHelperText>
                  Exam Duration in minutes. Max is 240 mins
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="sequenceNo">Question No</InputLabel>
                  <Input
                    id="sequenceNo"
                    name="sequenceNo"
                    type="number"
                    required
                    value={question.sequenceNo}
                    onChange={handleChange1}
                    startAdornment={
                      <InputAdornment position="start">
                        <FormatListNumberedIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{ min: 1, max: 1000 }}
                  />
                </FormControl>
              </div>
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="mark">Question Weight</InputLabel>
                  <Input
                    id="mark"
                    name="mark"
                    type="number"
                    required
                    value={question.mark}
                    placeholder="question marks"
                    onChange={handleChange1}
                    startAdornment={
                      <InputAdornment position="start">
                        <DonutLargeIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{ min: 1, max: 1000 }}
                  />
                </FormControl>
              </div>
              <div className="mb-2 col-md-4">
                <FormControl fullWidth>
                  <InputLabel htmlFor="hasAttachment">
                    File Attachment
                  </InputLabel>
                  <Select
                    required
                    startAdornment={
                      <InputAdornment position="start">
                        <AttachFileIcon fontSize="small" />
                      </InputAdornment>
                    }
                    value={question.hasAttachment}
                    onChange={handleChange1}
                    id="hasAttachment"
                    name="hasAttachment"
                  >
                    <MenuItem value="no">No</MenuItem>
                    <MenuItem value="yes">Yes</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {question.hasAttachment === "yes" ? (
              <div className="mx-3">
                <FormControl className="my-2" fullWidth>
                  <InputLabel htmlFor="attachment">Attachment</InputLabel>
                  <Input
                    type="file"
                    required
                    accept="application/pdf,image/png,image/jpeg"
                    id="attachment"
                    onChange={fileChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <AttachFileIcon fontSize="small" />
                      </InputAdornment>
                    }
                    placeholder="Upload PDF and TXT File only"
                  />
                </FormControl>
              </div>
            ) : null}
            <div className="mx-3">
              <FormControl className="my-2" fullWidth>
                <InputLabel htmlFor="question">Question</InputLabel>
                <Input
                  id="question"
                  name="question"
                  multiline
                  value={question.question}
                  onChange={handleChange1}
                  rows="3"
                  placeholder="Write question."
                  endAdornment={
                    <InputAdornment position="end">
                      {350 - (question.question.length ?? 0)}{" "}
                      <HelpOutlineIcon fontSize="small" />
                    </InputAdornment>
                  }
                  inputProps={{ minLength: 5, maxLength: 350 }}
                />
              </FormControl>
            </div>
            <div className="row">
              <div className="mb-2 col-md-9">
                <FormControl fullWidth>
                  <InputLabel htmlFor="correctAnswer">
                    Correct Answer
                  </InputLabel>
                  <Input
                    id="correctAnswer"
                    name="correctAnswer"
                    type="text"
                    value={question.correctAnswer}
                    onChange={handleChange1}
                    endAdornment={
                      <InputAdornment position="end">
                        {350 - (question.correctAnswer.length ?? 0)}
                      </InputAdornment>
                    }
                    inputProps={{ minLength: 1, maxLength: 350 }}
                  />
                </FormControl>
              </div>
              <div className="mb-2 col-md-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="answerType">Answer Type</InputLabel>
                  <Select
                    name="answerType"
                    id="answerType"
                    value={question.answerType}
                    onChange={handleChange1}
                    startAdornment={
                      <InputAdornment position="start">
                        <MergeTypeIcon fontSize="small" />
                      </InputAdornment>
                    }
                    required
                  >
                    <MenuItem value="text">TEXT</MenuItem>
                    <MenuItem value="mcq">MCQ</MenuItem>
                    {/* <MenuItem value="file">FILE</MenuItem> */}
                  </Select>
                  <FormHelperText>
                    It tell how student answer the question.
                  </FormHelperText>
                </FormControl>
              </div>
              {question.answerType === "mcq" ? (
                <div className="mb-2 col-md-12">
                  <InputLabel htmlFor="options">MCQ Options</InputLabel>
                  <Autocomplete
                    multiple
                    id="options"
                    options={[]}
                    value={options}
                    freeSolo
                    disabled={options.length === 5 ? true : false}
                    name="options"
                    onChange={(e) => {
                      let opt = options;
                      opt.push(e.target.value);
                      setOptions([...opt]);
                    }}
                    renderTags={() => {}}
                    renderInput={(params) => (
                      <TextField
                        type="text"
                        {...params}
                        variant="standard"
                        placeholder="Type Option then Press Enter"
                      />
                    )}
                  />
                  <FormHelperText>
                    Atmost five option allowed and must include correct answer
                  </FormHelperText>
                  {options.length > 0 &&
                    options.map((opt, i) => (
                      <Chip
                        key={opt + i + ""}
                        label={i + 1 + " ). " + opt}
                        clickable
                        color="primary"
                        className="m-1"
                        variant="outlined"
                        deleteIcon={<RemoveIcon fontSize="small" />}
                        onDelete={() => {
                          let sk = options;
                          let nsk = sk.filter((entry) => entry !== opt);
                          setOptions(nsk);
                        }}
                      />
                    ))}
                </div>
              ) : null}
            </div>
            <br />
            <button
              className="mx-3 btn btn-sm btn-outline-info"
              onClick={addQuestion}
            >
              <AddIcon fontSize="small" /> Add Question
            </button>
          </div>
          <div className="card-footer">
            <button
              disabled={disable}
              type="submit"
              className="btn btn-sm btn-outline-primary"
            >
              Submit <SendIcon fontSize="small" />
            </button>
          </div>
        </div>
      </form>
      <div className="my-2">
        {data.questions &&
          data.questions.map((ques, i) => (
            <QuestionCard
              ques={ques}
              key={i}
              editQuestion={editQuestion}
              deleteQuestion={deleteQuestion}
            />
          ))}
      </div>
    </div>
  );
};

const QuestionCard = ({ ques, editQuestion, deleteQuestion }) => {
  const download = async (file) => {
    let res = await axios({
      url: `/api/exam/getAttachment/${file.path.split("/")[2]}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "Bearer " + localStorage.jwtToken,
      },
    });
    //console.log(file.data);
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Fade in={true} timeout={980}>
      <div className="my-2 card">
        <div className="card-header">
          <div className="form-row">
            <div className="col-sm-11">
              <Typography gutterBottom variant="subtitle2">
                {ques.sequenceNo}). {ques.question}
              </Typography>
            </div>
            <div className="col-sm-1">
              <ButtonGroup variant="text" color="primary" size="small">
                <IconButton onClick={() => editQuestion(ques)} size="small">
                  <EditIcon fontSize="small" className="text-info" />
                </IconButton>
                <IconButton
                  onClick={() => deleteQuestion(ques.sequenceNo)}
                  size="small"
                >
                  <HighlightOffIcon fontSize="small" className="text-primary" />
                </IconButton>
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-sm-4">
              <Typography gutterBottom variant="subtitle2">
                Question Weight: {ques.mark}
              </Typography>
            </div>
            <div className="col-sm-4">
              <Typography gutterBottom variant="subtitle2">
                Has Attachment: {ques.hasAttachment ? "YES" : "NO"}
              </Typography>
              {ques.hasAttachment && (
                <Typography gutterBottom variant="caption">
                  {ques.fileName ?? (
                    <span
                      role="button"
                      onClick={() => download(ques.attachment)}
                    >
                      {ques.attachment.name}
                    </span>
                  )}
                </Typography>
              )}
            </div>
            <div className="col-sm-4">
              <Typography gutterBottom variant="subtitle2">
                Answer Type: {ques.answerType.toUpperCase()}
              </Typography>
            </div>
          </div>
          {ques.answerType === "mcq" ? (
            <div>
              <Typography gutterBottom variant="subtitle2">
                MCQ Options
              </Typography>
              <ol>
                {ques.options.map((opt, it) => (
                  <Typography
                    key={it}
                    component="li"
                    gutterBottom
                    variant="subtitle2"
                  >
                    {opt}
                  </Typography>
                ))}
              </ol>
            </div>
          ) : null}
        </div>
      </div>
    </Fade>
  );
};

export default CreateExam;
