import axios from "axios";
import { Status } from "../../components/layout/Alerts";
import GAEvent from "../../helpers/GAEvent";
import storageCheck from "../../helpers/storageCheck";

async function getCourses(dispatch, skip, newCache = undefined) {
  try {
    let res = await axios.get(`/api/course/list_of_course?skip=${skip}`);
    if (res) {
      console.log("fetch list of course");
      dispatch({
        type: "SET_ALL_COURSES",
        payload: res.data,
      });
      if (newCache !== undefined) {
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            date: new Date(),
          }),
        };
        newCache.put(
          `/courseList`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getListOfCourse = (skip = 0) => (dispatch) => {
  (async () => {
    try {
      //? since most callable api then check cache limit here
      storageCheck();
      if ("caches" in window) {
        const request = new Request(`/courseList`);
        const newCache = await caches.open("courses");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 2 minutes
          if (Date.now() > date.getTime() + 1000 * 60 * 2) {
            console.log("course list cache Expired!");
            newCache.delete(request);
            getCourses(dispatch, skip, newCache);
          } else {
            console.log("cache course list");
            let data = await response.json();
            dispatch({
              type: "SET_ALL_COURSES",
              payload: data,
            });
          }
        } else {
          getCourses(dispatch, skip, newCache);
        }
      } else {
        getCourses(dispatch, skip);
      }
    } catch (error) {
      getCourses(dispatch, skip);
    }
  })();
};

export const getListOfCourseInstructor = (userId, sort, type, skip = 0) => (
  dispatch
) => {
  axios
    .get(
      `/api/instructor/courseList/${userId}?skip=${skip}&sort=${Number(
        sort
      )}&type=${type}`
    )
    .then((crs) => {
      //console.log(crs.data);
      if (skip > 0) {
        dispatch({
          type: "LOAD_INSTRUCTOR_COURSES",
          payload: crs.data,
        });
      } else {
        dispatch({
          type: "SET_INSTRUCTOR_COURSES",
          payload: crs.data,
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const getCourseDetails = (userId, courseId) => (dispatch) => {
  axios({
    method: "post",
    url: `/api/course/getDetails/${userId}`,
    data: { courseId },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: "SET_COURSE_DETAILS",
        payload: res.data,
      });
      //? since most callable api then check cache limit here
      storageCheck();
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    });
};

export const adminCourseDetails = (courseId) => (dispatch) => {
  console.log(1);
  axios({
    method: "get",
    url: `/api/admin/courseDetail/${courseId}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      dispatch({
        type: "SET_ADMIN_COURSE_DETAILS",
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const instructorCourseDetails = (courseId) => (dispatch) => {
  axios({
    method: "get",
    url: `/api/instructor/courseDetail/${courseId}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: "SET_INSTRUCTOR_COURSE_DETAILS",
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const clearOldDetails = () => (dispatch) => {
  dispatch({ type: "CLEAR_OLD_COURSE_DATA" });
};

export const setHits = ({ userId, commentId, type }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/comment/hits/${userId}`,
        method: "patch",
        headers: {
          "Content-Type": "application/json",
        },
        data: { commentId, type },
      });
      if (res.data) {
        dispatch({ type: "CHANGE_HITS", payload: res.data });
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data) {
        Status({ text: `<h5>${err.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong</h55>", type: "error" });
      }
    }
  })();
};

export const checkCertificate = (courseId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/activity/courseCompleted/${courseId}`,
        method: "get",
      });
      if (res.data.status) {
        dispatch({
          type: "CERTIFICATE_CHECK",
          payload: true,
        });
      } else {
        dispatch({ type: "CERTIFICATE_CHECK", payload: false });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: "CERTIFICATE_CHECK", payload: false });
    }
  })();
};

async function getSubjectList(dispatch, newCache = undefined) {
  try {
    let res = await axios("/api/search/list");
    if (res.data) {
      console.log("fetch subject list");
      dispatch({ type: "LIST", payload: res.data });
      if (newCache !== undefined) {
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            date: new Date(),
          }),
        };
        newCache.put(
          `/subjectList`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const subjectList = () => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = new Request(`/subjectList`);
        const newCache = await caches.open("course-details");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 3 minutes
          if (Date.now() > date.getTime() + 1000 * 60 * 3) {
            console.log("subject cache Expired!");
            newCache.delete(request);
            getSubjectList(dispatch, newCache);
          } else {
            console.log("cache subject list");
            let data = await response.json();
            dispatch({ type: "LIST", payload: data });
          }
        } else {
          getSubjectList(dispatch, newCache);
        }
      } else {
        getSubjectList(dispatch);
      }
    } catch (error) {
      console.log(error);
      getSubjectList(dispatch);
    }
  })();
};

export const getSearchCourse = ({ subject, cost, language, keyword }) => (
  dispatch
) => {
  (async () => {
    try {
      let res = await axios.post("/api/course/search", {
        subject,
        cost,
        language,
        keyword,
      });
      if (res) {
        GAEvent({
          category: "Search",
          action: `Course Search`,
          label: `keyword: ${keyword ?? ""}, subject: ${
            subject ?? ""
          }, language: ${language ?? ""}, cost: ${cost ?? ""}`,
        });
        if (res.data.length === 0) {
          dispatch({ type: "NO_COURSE_FOUND" });
        } else {
          dispatch({ type: "COURSE_FOUND", payload: res.data });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "NO_COURSE_FOUND" });
    }
  })();
};

export const createCourse = (
  newCourse,
  thumbnailData,
  history,
  attachment,
  setDisable
) => (dispatch) => {
  axios({
    method: "post",
    url: "/api/course/create",
    data: newCourse,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if ("caches" in window) {
        caches.delete("course-category").catch((err) => console.log(err));
        caches.delete("course-details").catch((err) => console.log(err));
        caches.delete("course-imgs").catch((err) => console.log(err));
        caches.delete("courses").catch((err) => console.log(err));
      }
      axios({
        method: "post",
        url: `/api/course/newThumbnail/${res.data.courseID}`,
        data: thumbnailData,
      })
        .then((r) => {
          GAEvent({
            category: "Course",
            action: "New course is created having Id: " + res.data.courseID,
          });
          if (attachment !== null) {
            axios({
              method: "post",
              url: `/api/course/newAttachment/${res.data.courseID}`,
              data: attachment,
            }).then((r2) => {
              console.log(r2);
              dispatch({
                type: "SET_NEW_COURSE",
                payload: r.data.data,
              });
              setDisable(false);
              Status({
                text: "<h5>Course Created! but waiting for approval.</h5>",
                type: "success",
              });
              history.push(
                `/instructor/view/${r.data.data.slugUrl && r.data.data._id}`
              );
            });
          } else {
            dispatch({
              type: "SET_NEW_COURSE",
              payload: r.data.data,
            });
            setDisable(false);
            Status({
              text: "<h5>Course Created! but waiting for approval.</h5>",
              type: "success",
            });
            history.push(
              `/instructor/view/${r.data.data.slugUrl ?? r.data.data._id}`
            );
          }
        })
        .catch((er) => {
          console.log(er.response, "image upload failed");
          dispatch({
            type: "SET_NEW_COURSE",
            payload: res.data.data,
          });
          setDisable(false);
          Status({
            text: "<h5>Course Created! but waiting for approval.</h5>",
            type: "success",
          });
          history.push(
            `/instructor/view/${res.data.data.slugUrl ?? res.data.data._id}`
          );
        });
    })
    .catch((err) => {
      console.log(err.response);
      setDisable();
      if (err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    });
};

export const updateCourse = ({
  history,
  editedCourse,
  courseId,
  thumbnailData,
  attachment,
  setDisable,
  videosToDelete,
}) => (dispatch) => {
  axios({
    method: "put",
    url: `/api/course/update/${courseId}`,
    data: editedCourse,
  })
    .then((res) => {
      if ("caches" in window) {
        caches.delete("course-category").catch((err) => console.log(err));
        caches.delete("course-details").catch((err) => console.log(err));
        caches.delete("course-imgs").catch((err) => console.log(err));
        caches.delete("courses").catch((err) => console.log(err));
      }

      bulkObjectDelete(videosToDelete);
      if (thumbnailData) {
        axios({
          method: "post",
          url: `/api/course/newThumbnail/${courseId}`,
          data: thumbnailData,
        })
          .then((r) => {
            if (attachment !== null) {
              axios({
                method: "post",
                url: `/api/course/newAttachment/${courseId}`,
                data: attachment,
              })
                .then((r2) => {
                  dispatch({
                    type: "UPDATE_COURSE",
                    payload: res.data.crs,
                  });
                  setDisable(false);
                  Status({
                    text: "<h5>Course Updated! Waiting for approval.</h5>",
                    type: "success",
                  });
                  history.push(
                    `/instructor/view/${r.data.data.slugUrl ?? r.data.data._id}`
                  );
                })
                .catch((er3) => {
                  console.log(er3);
                  dispatch({
                    type: "UPDATE_COURSE",
                    payload: res.data.crs,
                  });
                  setDisable(false);
                  Status({
                    text:
                      "<h5>Course Updated! but failed to update attachment. Waiting for approval.</h5>",
                    type: "success",
                  });
                  history.push(
                    `/instructor/view/${r.data.data.slugUrl ?? r.data.data._id}`
                  );
                });
            } else {
              dispatch({
                type: "UPDATE_COURSE",
                payload: res.data.crs,
              });
              setDisable(false);
              Status({
                text: "<h5>Course Updated! Waiting for approval.</h5>",
                type: "success",
              });
              history.push(
                `/instructor/view/${r.data.data.slugUrl ?? r.data.data._id}`
              );
            }
          })
          .catch((err2) => {
            console.log(err2, "failed to update thumbnail");
            dispatch({
              type: "UPDATE_COURSE",
              payload: res.data.crs,
            });
            setDisable(false);
            Status({
              text:
                "<h5>Course Updated! but failed to update thumbnail. Waiting for approval.</h5>",
              type: "success",
            });
            history.push(
              `/instructor/view/${res.data.crs.slugUrl ?? res.data.crs._id}`
            );
          });
      } else {
        if (attachment !== null) {
          axios({
            method: "post",
            url: `/api/course/newAttachment/${courseId}`,
            data: attachment,
          })
            .then((r2) => {
              console.log(res.data);
              dispatch({
                type: "UPDATE_COURSE",
                payload: res.data.crs,
              });
              setDisable(false);
              Status({
                text: "<h5>Course Updated! Waiting for approval.</h5>",
                type: "success",
              });
              history.push(
                `/instructor/view/${res.data.crs.slugUrl ?? res.data.crs._id}`
              );
            })
            .catch((er) => {
              console.log(er, "failed to update attachment");
              dispatch({
                type: "UPDATE_COURSE",
                payload: res.data.crs,
              });
              setDisable(false);
              Status({
                text:
                  "<h5>Course Updated! but failed to update attachment. Waiting for approval.</h5>",
                type: "success",
              });
              history.push(
                `/instructor/view/${res.data.crs.slugUrl ?? res.data.crs._id}`
              );
            });
        } else {
          console.log(res.data);
          dispatch({
            type: "UPDATE_COURSE",
            payload: res.data.crs,
          });
          setDisable(false);
          Status({
            text: "<h5>Course Updated! Waiting for approval.</h5>",
            type: "success",
          });
          history.push(
            `/instructor/view/${res.data.crs.slugUrl ?? res.data.crs._id}`
          );
        }
      }
    })
    .catch((err) => {
      setDisable(false);
      if (err.response && err.response.data) {
        Status({ text: `<h5>${err.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
    });
};

export const deleteCourseByInstructor = (courseId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "delete",
        url: `/api/course/delete/${courseId}`,
      });
      if (res) {
        console.log(res.data);
        if ("caches" in window) {
          caches.open("course-imgs").then((cache) => {
            const request = new Request(`img_${courseId}`);
            cache.delete(request);
          });
          caches.delete("course-category");
          caches.delete("course-details");
          caches.delete("courses");
        }
        GAEvent({
          category: "Course",
          action: "Course having id: " + courseId + " is deleted.",
        });
        dispatch({ type: "DELETE_COURSE", payload: courseId });
        Status({ text: "<h5>Course delete successfully.</h5>", type: "info" });
      }
    } catch (err) {
      console.log(err);
      if (err && err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    }
  })();
};

export const getSignUrl = async ({
  fileName = "",
  fileType = "",
  filePath = "",
  accessType = "share",
}) => {
  try {
    let data = accessType === "upload" ? { fileName, fileType } : { filePath };
    let res = await axios.post(`/api/course/${accessType}/signurl`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    Status({ text: "<h5>Failed to upload this video.</h5>", type: "error" });
    return false;
  }
};

export const bulkObjectDelete = async (listToBeDeleted) => {
  try {
    console.log(listToBeDeleted);
    if (listToBeDeleted.length < 1) {
      return;
    }
    let res = await axios({
      method: "delete",
      url: "/api/course/upload/deletebulkobjects",
      data: { listToBeDeleted: listToBeDeleted },
    });
    if (res) {
      console.log(res.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteUploadedVideo = async (filePath) => {
  try {
    let res = await axios({
      method: "delete",
      url: "/api/course/upload/deleteobject",
      data: { filePath },
    });
    console.log(res.data);
    if (res) {
      return true;
    }
  } catch (error) {
    console.log(error);
    Status({ text: "<h5>Failed to delete video.</h5>", type: "error" });
    return false;
  }
};

export const getComments = ({ videoId, skip = 0 }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "get",
        url: `/api/comment/getComments/${videoId}?skip=${skip}`,
      });
      if (res) {
        //console.log(res.data);
        if (skip > 0) {
          dispatch({ type: "LOAD_MORE_COMMENTS", payload: res.data });
        } else {
          dispatch({ type: "GET_COMMENTS", payload: res.data });
        }
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const saveComment = ({
  videoId,
  comment,
  userId,
  commentType = "comment",
}) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "post",
        url: `/api/comment/createComment/${userId}`,
        data: {
          videoId,
          comment,
          commentType,
        },
      });
      if (res.data) {
        let comment = res.data.comments;
        comment.postedBy = {
          _id: res.data.user._id,
          name: res.data.user.name,
        };
        //console.log(comment);
        dispatch({ type: "POST_COMMENT", payload: comment });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const deleteComment = ({ commentId, userId }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "delete",
        url: `/api/comment/deleteComment/${userId}`,
        data: {
          commentId,
        },
      });
      if (res) {
        //console.log(res.data);
        dispatch({ type: "DELETE_COMMENT", payload: commentId });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};
