const initState = {
  userStats: { total: 0, data: [] },
  blogStats: { total: 0, data: [] },
  courseStats: { total: 0, data: [] },
  transactionStats: { total: 0, data: [] },
  instituteStats: [],
  users: null,
  comments: null,
  msg: "", // this will tell there is no more users left to fetch
  msg2: "", // for comments
  overlist3: false,
  sponsors: null,
  transactions: null,
  msg3: "",
  msg4: "",
  priests: null,
  msg5: "",
  instructorRequest: null,
  msg6: "",
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_USERS_STATS":
      return {
        ...state,
        userStats: {
          total: action.payload.totalUsers,
          data: action.payload.data,
        },
      };
    case "GET_INSTUTION_STATS":
      return {
        ...state,
        instituteStats: action.payload,
        overlist3: false,
      };
    case "GET_SPONSORS":
      return {
        ...state,
        sponsors: [...action.payload],
      };
    case "LOAD_MORE_SPONSORS":
      if (action.payload.length === 0) {
        return {
          ...state,
          msg4: "no more data",
        };
      } else {
        return {
          ...state,
          sponsors: [...state.sponsors, ...action.payload],
        };
      }
    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactions: [...action.payload],
      };
    case "LOAD_MORE_TRANSACTIONS":
      if (action.payload.length === 0) {
        return {
          ...state,
          msg3: "no more data",
        };
      } else {
        return {
          ...state,
          transactions: [...state.transactions, ...action.payload],
        };
      }
    case "LOAD_MORE_INSTITUTIONS":
      if (action.payload.length === 0) {
        return {
          ...state,
          overlist3: true,
        };
      } else {
        return {
          ...state,
          instituteStats: [...state.instituteStats, ...action.payload],
        };
      }
    case "GET_COURSES_STATS":
      return {
        ...state,
        courseStats: {
          total: action.payload.totalCourses,
          data: action.payload.data,
        },
      };
    case "GET_BLOGS_STATS":
      return {
        ...state,
        blogStats: {
          total: action.payload.totalBlogs,
          data: action.payload.data,
        },
      };
    case "GET_TRANSACTIONS_STATS":
      return {
        ...state,
        transactionStats: {
          total: action.payload.totalTransactions,
          data: action.payload.data,
        },
      };
    case "GET_USERS":
      return {
        ...state,
        users: [...action.payload],
      };
    case "BETA_ACCESS_STATUS":
      let index = state.users.findIndex(
        (el) => el._id === action.payload.userId
      );

      if (index !== -1) {
        state.users[index].beta = false;
        state.users[index].betaStatus = true;
      }
      return {
        ...state,
        users: [...state.users],
      };
    case "BETA_ACCESS_REVOKE":
      let index2 = state.users.findIndex(
        (el) => el._id === action.payload.userId
      );
      if (index2 !== -1) {
        state.users[index2].beta = false;
        state.users[index2].betaStatus = false;
      }
      return {
        ...state,
        users: [...state.users],
      };
    case "LOAD_MORE_USERS":
      let tmp3 = [...state.users, ...action.payload];
      let msg = "";
      if (action.payload.length === 0) {
        msg = "No more data";
      }
      return {
        ...state,
        users: [...tmp3],
        msg: msg,
      };
    case "COMMENT_LIST":
      return {
        ...state,
        comments: [...action.payload],
      };
    case "LOAD_COMMENT_LIST":
      let tmp4 = [...state.comments, ...action.payload];
      let msg2 = "";
      if (action.payload.length === 0) {
        msg2 = "No more data";
      }
      return {
        ...state,
        comments: [...tmp4],
        msg2: msg2,
      };
    case "DELETE_COMMENT_BY_ADMIN":
      let tmp5 = state.comments.filter((c) => c._id !== action.payload);
      return {
        ...state,
        comments: [...tmp5],
      };
    case "ENABLE_USER":
      let index3 = state.users.findIndex(
        (el) => el._id === action.payload.userId
      );
      if (index3 !== -1) {
        state.users[index3].disabled = false;
      }
      return {
        ...state,
        users: [...state.users],
      };
    case "DISABLE_USER":
      let index4 = state.users.findIndex(
        (el) => el._id === action.payload.userId
      );
      if (index4 !== -1) {
        state.users[index4].disabled = true;
      }
      return {
        ...state,
        users: [...state.users],
      };
    case "ACCOUNT_DELETE":
      let tmp6 = state.users.filter((el) => el._id !== action.payload.userId);
      return {
        ...state,
        users: [...tmp6],
      };
    case "MODERATOR_UPDATE":
      let index5 = state.users.findIndex(
        (usr) => usr._id === action.payload.userId
      );
      console.log(index5);
      let tmp7 = state.users;
      if (index5 !== -1) {
        tmp7[index5].role = action.payload.role;
      }
      console.log(tmp7);
      return {
        ...state,
        users: [...tmp7],
      };
    case "FETCH_PRIEST_LIST":
      return {
        ...state,
        priests: [...action.payload],
      };
    case "LOAD_MORE_PRIEST_LIST":
      let tmp8 = [...state.priests, ...action.payload];
      let msgs = "";
      if (action.payload.length === 0) {
        msgs = "No more data";
      }
      return {
        ...state,
        comments: [...tmp8],
        msg5: msgs,
      };
    case "FETCH_INSTRUCTOR_REQUEST":
      return {
        ...state,
        instructorRequest: [...action.payload],
      };
    case "LOAD_MORE_INSTRUCTOR_REQUEST":
      let tmp9 = [...state.instructorRequest, ...action.payload];
      let msgs1 = "";
      if (action.payload.length === 0) {
        msgs1 = "No more data";
      }
      return {
        ...state,
        instructorRequest: [...tmp9],
        msg6: msgs1,
      };
    case "GRANT_PRIEST":
      let index123 = state.instructorRequest.findIndex(
        (ins) => ins._id === action.payload.userId
      );
      let ins = state.instructorRequest[index123];
      ins.priestStatus = "accept";
      let tmp09 = state.instructorRequest.filter(
        (int) => int._id !== action.payload.userId
      );
      let tmp10 = state.priests;
      tmp10.push(ins);
      return {
        ...state,
        instructorRequest: [...tmp09],
        priests: [...tmp10],
      };
    case "REVOKE_PRIEST":
      let tmp019 = state.priests.filter(
        (inst) => inst._id !== action.payload.userId
      );
      return {
        ...state,
        priests: [...tmp019],
      };
    default:
      return state;
  }
};

export default adminReducer;
