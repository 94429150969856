import React from "react";
import { Typography } from "@material-ui/core";
import "./UploadInput.scss";

const UploadInput = ({ fileName, icon, handleFileChange, ...rest }) => {
  return (
    <div className="container-md my-2">
      <div className="center2">
        <label htmlFor="file" align="center">
          <Typography gutterBottom variant="subtitle2">
            Click or drag and drop a file in box
          </Typography>
          {fileName ? (
            <Typography gutterBottom variant="subtitle2">
              Selected: {fileName}
            </Typography>
          ) : null}
        </label>
        <div className="dropzone gmd-1">
          <div className="upload-icon">{icon}</div>
          <input
            type="file"
            id="file"
            {...rest}
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={handleFileChange}
            aria-describedby="file_upload"
            className="upload-input"
          />
        </div>
      </div>
    </div>
  );
};

export default UploadInput;
