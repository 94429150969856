import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

const AdminRoute = ({ component: Component, auth, ...rest }) => {
  let check;
  if (typeof localStorage !== "undefined") {
    check = localStorage.getItem("loggedIn") === "true" ? true : false;
  } else {
    check = false;
  }
  console.log(check, auth.isAuthenticated);
  if (check && auth.isAuthenticated === false) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <div className="my-2">
            <CircularProgress color="primary" />
          </div>
        )}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.isAuthenticated === true && auth.user.role === "admin" ? (
            <Component {...props} />
          ) : (
            check !== true && <Redirect to="/signin" />
          )
        }
      />
    );
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminRoute);
