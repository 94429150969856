import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputBase, IconButton, Paper } from "@material-ui/core";
import Category from "../Category";

import Brand from "../../../images/logo_trans2.png";
import SearchIcon from "@material-ui/icons/Search";

const NavHeader = () => {
  const [search, setSearch] = useState("");

  return (
    <React.Fragment>
      <div className="navbar-header">
        <Link className="navbar-brand" to="/">
          <img
            src={Brand}
            alt="brandlogo"
            className="ml-2 px-2 py-3 brand"
          ></img>
        </Link>
        <Category />
        <Paper
          component="div"
          style={{
            border: "1px solid rgba(245, 0, 87, 0.5)",
          }}
          elevation={0}
          variant="outlined"
          color="secondary"
          className="navbar-header d-none d-md-inline-block  text-primary rounded pl-2 ml-4"
        >
          <InputBase
            id="search"
            margin="dense"
            placeholder="  Search any course"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton
            type="submit"
            className="searchButton"
            onClick={(e) => {
              e.preventDefault();
              if (search.length > 0) {
                window.location = `/search?keyword=${search}`;
              }
            }}
            aria-label="search"
          >
            <SearchIcon className="text-primary" />
          </IconButton>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default NavHeader;
