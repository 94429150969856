import axios from "axios";

export const getPolicy = () => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = `/api/activity/policy/privacy`;
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        };
        const newCache = await caches.open("policy");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 1 hours
          if (Date.now() > date.getTime() + 1000 * 60 * 60) {
            console.log("privacy cache Expired!");
            newCache.delete(request);
            newCache
              .add(request, options)
              .then(async () => {
                const response2 = await newCache.match(request);
                if (response2) {
                  dispatch({
                    type: "PRIVACY",
                    payload: await response2.json(),
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: "PRIVACY", payload: "Failed to load" });
              });
          } else {
            dispatch({ type: "PRIVACY", payload: await response.json() });
          }
        } else {
          console.log("Not found in cache");
          newCache
            .add(request, options)
            .then(async () => {
              const response2 = await newCache.match(request);
              if (response2) {
                dispatch({ type: "PRIVACY", payload: await response2.json() });
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: "PRIVACY",
                payload: "Currently, there is no privacy policy.",
              });
            });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "PRIVACY",
        payload: "Currently, there is no privacy policy.",
      });
    }
  })();
};

export const getTAndC = () => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = `/api/activity/policy/terms`;
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        };
        const newCache = await caches.open("policy");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 1 hours
          if (Date.now() > date.getTime() + 1000 * 60 * 60) {
            console.log("terms cache Expired!");
            newCache.delete(request);
            newCache
              .add(request, options)
              .then(async () => {
                const response2 = await newCache.match(request);
                if (response2) {
                  dispatch({ type: "TERMS", payload: await response2.json() });
                }
              })
              .catch((err) => {
                console.log(err);
                dispatch({
                  type: "TERMS",
                  payload: "Currently, there is no terms and conditions.",
                });
              });
          } else {
            dispatch({ type: "TERMS", payload: await response.json() });
          }
        } else {
          newCache
            .add(request, options)
            .then(async () => {
              const response2 = await newCache.match(request);
              if (response2) {
                dispatch({ type: "TERMS", payload: await response2.json() });
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: "TERMS",
                payload: "Currently, there is no terms and conditions.",
              });
            });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "TERMS",
        payload: "Currently, there is no terms and conditions.",
      });
    }
  })();
};

export const getCourseGuidelines = () => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = `/api/activity/policy/course`;
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        };
        const newCache = await caches.open("policy");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 1 hours
          if (Date.now() > date.getTime() + 1000 * 60 * 60) {
            console.log("policy cache Expired!");
            newCache.delete(request);
            newCache
              .add(request, options)
              .then(async () => {
                const response2 = await newCache.match(request);
                if (response2) {
                  dispatch({
                    type: "COURSE_GUIDE",
                    payload: await response2.json(),
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                dispatch({
                  type: "COURSE_GUIDE",
                  payload: "Currently, there is no course guidelines.",
                });
              });
          } else {
            dispatch({ type: "COURSE_GUIDE", payload: await response.json() });
          }
        } else {
          newCache
            .add(request, options)
            .then(async () => {
              const response2 = await newCache.match(request);
              if (response2) {
                dispatch({
                  type: "COURSE_GUIDE",
                  payload: await response2.json(),
                });
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: "COURSE_GUIDE",
                payload: "Currently, there is no course guidelines.",
              });
            });
        }
      }
    } catch (error) {
      console.log(error);
      axios
        .get("/api/activity/policy/course")
        .then((res) => {
          if (res.data) {
            dispatch({
              type: "COURSE_GUIDE",
              payload: res.data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "COURSE_GUIDE",
            payload: "Currently, there is no course guidelines.",
          });
        });
    }
  })();
};
