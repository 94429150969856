import React, { useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ReactHtml from "raw-html-react";
import { getCourseGuidelines } from "../../store/actions/policyAction";
import { getDateAndDay } from "../layout/Date";
const CourseGuidelines = () => {
  const courseGuide = useSelector((state) => state.policy.courseGuide);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!courseGuide) {
      dispatch(getCourseGuidelines());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="my-3">
      <div className="container bg-white rounded py-2">
        <Typography align="center" gutterBottom variant="h6">
          Course Guidelines
        </Typography>
        {courseGuide !== undefined && courseGuide._id ? (
          <div>
            <ReactHtml
              html={courseGuide.content}
              componentMap={
                <Typography
                  paragraph
                  className="container courseFont"
                ></Typography>
              }
            ></ReactHtml>
            <Typography variant="overline">
              Updated on: {getDateAndDay(courseGuide.updatedAt)}
            </Typography>
          </div>
        ) : courseGuide && !courseGuide._id ? (
          <div align="center">
            <Typography gutterBottom variant="subtitle2">
              {courseGuide}
            </Typography>
          </div>
        ) : (
          <div align="center">
            <CircularProgress color="primary" variant="determinate" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseGuidelines;
