import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Typography, Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactHtml from "raw-html-react";

function TabPanel(props) {
  const { children, name, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, name) {
  return {
    id: `${name}-tab-${index}`,
    "aria-controls": `${name}-tabpanel-${index}`,
  };
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: ["rtl", "ltr"] }], // text direction
    [{ size: ["small", false, "large"] }], // custom dropdown
    [{ header: [5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "size",
  "header",
  "bold",
  "italic",
  "underline",
  "color",
  "background",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link",
  "font",
  "align",
  "clean",
];

const RichTextArea = ({
  name,
  label,
  placeholder,
  value,
  handleRTEChange,
  maxLength,
}) => {
  const [tab, setTab] = useState(0);
  return (
    <section className="my-2">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(e, val) => setTab(val)}
      >
        <Tab label="Editor" {...a11yProps(0, name)} />
        <Tab label="Preview" {...a11yProps(1, name)} />
      </Tabs>
      <TabPanel name={name} value={tab} index={0}>
        <div className="py-1">
          <label htmlFor={"content_" + name}>
            <span className="font-weight-bold">{label}</span>
          </label>
          <ReactQuill
            theme="snow"
            placeholder={placeholder}
            name={name}
            id={"content_" + name}
            modules={modules}
            formats={formats}
            value={value ?? ""}
            onChange={handleRTEChange}
          />
          <div className="text-right">
            {maxLength - (value.trim().length ?? 0)}
          </div>
        </div>
      </TabPanel>
      <TabPanel name={name} value={tab} index={1}>
        <div className="py-2">
          <ReactHtml
            html={value || placeholder}
            componentMap={<Typography paragraph></Typography>}
          ></ReactHtml>
        </div>
      </TabPanel>
    </section>
  );
};

export default RichTextArea;
