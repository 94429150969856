import React from "react";
import { NavLink } from "react-router-dom";
import BootstrapTooltip from "../../layout/Tooltip";
import { Typography } from "@material-ui/core";

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

const NonAuth = () => {
  return (
    <React.Fragment>
      <div className="collapse navbar-collapse" id="ganpatiNavbar">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item ml-2">
            <BootstrapTooltip title="Sign Up" interactive>
              <NavLink
                className="nav-link"
                style={{ paddingBottom: "4px" }}
                to="/signup"
              >
                <button
                  type="span"
                  className="btn btn-outline-primary btn-sm ripple lead"
                  style={{ borderRadius: "50px" }}
                >
                  <AccountCircleOutlinedIcon />{" "}
                  <Typography component="span" variant="body2">
                    Sign Up
                  </Typography>
                </button>
              </NavLink>
            </BootstrapTooltip>
          </li>
          <li className="nav-item">
            <BootstrapTooltip title="Sign In" interactive>
              <NavLink
                className="nav-link"
                style={{ paddingBottom: "4px" }}
                to="/signin"
              >
                <button
                  type="span"
                  className="btn btn-sm btn-primary ripple lead"
                  style={{ borderRadius: "50px", border: "none" }}
                >
                  <VpnKeyOutlinedIcon />{" "}
                  <Typography component="span" variant="body2">
                    Sign In
                  </Typography>
                </button>
              </NavLink>
            </BootstrapTooltip>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default NonAuth;
