import React from "react";
import Brand from "../../images/logo_trans1.webp";
import { Link } from "react-router-dom";
import { Typography, Modal, makeStyles } from "@material-ui/core";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    outline: "none",
    width: 400,
    backgroundColor: "#e5e4e2",
    borderRadius: "10px",
    padding: theme.spacing(2, 4, 3),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 4000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.paper}>
      <div className="text-dark">
        <Typography align="center" gutterBottom variant="body1">
          Resources Credits
        </Typography>
        <div>
          Icons And SVG Image made by{" "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com.
          </a>
        </div>
        <div>
          Some Icons taken from{" "}
          <a href="https://fontawesome.com/" title="FontAwesome">
            www.fontawesome.com.
          </a>
        </div>
        <div>
          Some Images taken from{" "}
          <a href="https://unsplash.com/" title="Unsplash">
            www.unsplash.com.
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="footer-des">
                <Link className="text-decoration-none" to="/">
                  <img src={Brand} alt="icon" className="img-fluid brand" />
                  <Typography
                    className="pt-3 text-white font-weight-bold courseFont"
                    variant="body1"
                    gutterBottom
                  >
                    A Spiritual E-Learning Platform
                  </Typography>
                </Link>

                <Typography gutterBottom variant="subtitle2">
                  Quicksilver Technologies Private Limited, Bangalore,
                  Karnataka, India
                </Typography>
                <Typography gutterBottom variant="subtitle2">
                  <address>
                    <i className="fa fa-phone"></i>: +1 507 460 3594
                    <br />
                    <i className="fa fa-fax"></i>: +1 507 460 3594
                    <br />
                    <i className="fa fa-envelope"></i>:{" "}
                    <a
                      className="text-white text-decoration-none"
                      href="mailto:contact@jaiganpati.com"
                    >
                      contact@jaiganpati.com
                    </a>
                  </address>
                </Typography>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 text-center">
              <Typography gutterBottom variant="h6" className="text-white">
                Useful links
              </Typography>
              <Typography gutterBottom variant="subtitle2">
                <Link
                  className="text-white"
                  style={{ textDecoration: "none" }}
                  to="/blogs"
                >
                  <span className="font-weight-bold">&#8226;</span> Blogs
                </Link>
              </Typography>

              <Typography gutterBottom variant="subtitle2">
                <Link
                  className="text-white"
                  to="/about"
                  style={{ textDecoration: "none" }}
                >
                  <span className="font-weight-bold">&#8226;</span> About Us
                </Link>
              </Typography>

              <Typography gutterBottom variant="subtitle2">
                <Link
                  className="text-white"
                  to="/contact"
                  style={{ textDecoration: "none" }}
                >
                  <span className="font-weight-bold">&#8226;</span> Contact Us
                </Link>
              </Typography>

              <Typography gutterBottom variant="subtitle2">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white mr-3"
                  to={"/privacy"}
                >
                  <span className="font-weight-bold">&#8226;</span> Privacy
                  Policy
                </Link>
              </Typography>

              <Typography gutterBottom variant="subtitle2">
                <Link
                  style={{ textDecoration: "none" }}
                  className="text-white"
                  to={"/tandc"}
                >
                  <span className="font-weight-bold">&#8226;</span> Terms and
                  conditions
                </Link>
              </Typography>
            </div>

            <div className="col-md-4 col-sm-12 text-center">
              <div className="p-2">
                <a
                  href="https://www.facebook.com/groups/brhmaio"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="button"
                  className="btn btn-outline-light "
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.twitter.com/brhmaio"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="button"
                  className="btn btn-outline-light mx-2"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
              <div className="p-2">
                <Link to="/donate" className="btn bg-light ">
                  <i className="fas fa-hand-holding-usd"></i> Donate
                </Link>
              </div>
              <div className="p-2">
                <Typography
                  gutterBottom
                  className="text-white courseFont"
                  variant="subtitle2"
                >
                  © 2021 Copyright Jaiganpati.com
                </Typography>
                <Typography
                  className="text-white courseFont"
                  gutterBottom
                  variant="subtitle2"
                  onClick={handleOpen}
                  style={{ cursor: "pointer" }}
                >
                  Resources Credits
                </Typography>
                <Modal
                  open={open}
                  onClose={handleClose}
                  className={classes.modal}
                  aria-labelledby="resource-credits"
                  aria-describedby="resource-credits-description"
                >
                  {body}
                </Modal>
              </div>
              {/**--- */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
