import React, { Fragment, useEffect } from "react";
//import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { subjectList } from "../../store/actions/courseActions";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "../layout/Tooltip";

const Category = (props) => {
  const subject = useSelector((state) => state.course.subject);
  const dispatch = useDispatch();

  useEffect(() => {
    if (subject.length === 0) {
      dispatch(subjectList());
    } // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Tooltip title="Category" interactive>
        <Button
          data-toggle="dropdown"
          aria-haspopup="true"
          className="text-primary"
          aria-expanded="false"
          variant="outlined"
          color="secondary"
          size="small"
        >
          Browse <ExpandMoreIcon className="text-primary" />
        </Button>
      </Tooltip>
      <div className="dropdown-menu ml-5 dropdown-menu-center border-0 rounded shadow-sm text-center">
        <List dense={true}>
          {subject && subject.length > 0 ? (
            subject.map((dat, i) => {
              return (
                <ListItem
                  button
                  style={{ color: "black", textDecoration: "none" }}
                  component="a"
                  href={`/search?subject=${dat}`}
                  key={i}
                >
                  {dat}
                </ListItem>
              );
            })
          ) : (
            <CircularProgress color="secondary" />
          )}
        </List>
      </div>
    </Fragment>
  );
};

export default React.memo(Category);
