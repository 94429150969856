import React from "react";
import { connect } from "react-redux";
import { getUser } from "../../store/actions/userActions";
import NavHeader from "./NavHeader";
import BetaNotification from "./BetaNotification";
import AuthNav from "./AuthNav";
import NonAuth from "./NonAuth";

const NavMenu = ({ auth }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
      {auth.isAuthenticated && <BetaNotification />}

      <NavHeader />

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ganpatiNavbar"
        aria-controls="ganpatiNavbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      {auth.isAuthenticated ? <AuthNav /> : <NonAuth />}
    </nav>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
