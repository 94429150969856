import React from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  ButtonGroup,
  IconButton,
  CircularProgress,
  Box,
} from "@material-ui/core";
import RichTextArea from "./RichTextArea";
import TagInput from "./TagInput";
import UploadInput from "./UploadInput";

import VideoCallIcon from "@material-ui/icons/VideoCall";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CheckIcon from "@material-ui/icons/Check";
import TitleIcon from "@material-ui/icons/Title";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HttpIcon from "@material-ui/icons/Http";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        padding={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.value === 0 ? (
          <BackupOutlinedIcon fontSize="small" color="primary" />
        ) : props.value === 100 ? (
          <CheckIcon fontSize="small" color="primary" />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="textPrimary"
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

const AddContent = ({
  progress,
  handleVideoUpload,
  setContentType,
  handleChange,
  tags,
  setTags,
  handleAttachment,
  videoFile,
  uploadVideo,
  deleteVideo,
  setContent,
  addContentToSection,
  contentType,
  content,
  vprogress,
  handleVideoThumbnailUpload,
  videoThumbnail,
  uploadVideoThumbnail,
  deleteVideoThumbnail,
  disableUpload,
}) => {
  return (
    <div className="py-2 px-sm-2 px-md-4">
      <div className="form-row">
        <div className="col-md-4 py-1">
          <TextField
            type="number"
            name="sequenceNo"
            label="Content Sequence No"
            id="sequenceNo"
            required
            fullWidth
            margin="dense"
            size="medium"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormatListNumberedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={content.sequenceNo}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 1, max: 250 }}
            onChange={(e) =>
              setContent({
                ...content,
                sequenceNo: e.target.value,
              })
            }
            helperText="Changing sequence no. will change content order."
          />
        </div>
        <div className="col-md-8 py-1">
          <TextField
            fullWidth
            type="text"
            id="videoTitle"
            label="Content Title"
            required
            margin="dense"
            size="medium"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {100 - (content.videoTitle.length ?? 0)}{" "}
                  <TitleIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            inputProps={{ minLength: 4, maxLength: 100 }}
            value={content.videoTitle ?? ""}
            onChange={(e) =>
              setContent({
                ...content,
                videoTitle: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md py-1">
          <TextField
            select
            fullWidth
            variant="outlined"
            id="videoType"
            margin="dense"
            size="medium"
            disabled={videoFile !== null && progress === 100 ? true : false}
            label="Content Type"
            required
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="audio">Audio</MenuItem>
            <MenuItem value="text">Text</MenuItem>
          </TextField>
        </div>
        <div className="col-md py-1">
          {contentType !== "text" ? (
            <TextField
              id="uploadable"
              required
              margin="dense"
              fullWidth
              name="uploadable"
              label="Upload or Type url of video/audio file"
              variant="outlined"
              value={content.uploadable}
              select
              onChange={handleChange}
            >
              <MenuItem value={false}>Url</MenuItem>
              <MenuItem value={true}>Upload</MenuItem>
            </TextField>
          ) : null}
        </div>
      </div>

      {contentType !== "text" && content.uploadable === true ? (
        <div className="form-row">
          <div
            className={
              contentType === "video" ? "col-md-6 py-1" : "col-12 py-1"
            }
          >
            <Typography align="center" gutterBottom variant="subtitle1">
              {contentType === "video"
                ? "Upload Video File"
                : "Upload Audio File"}
            </Typography>
            <UploadInput
              accept="video/mp4,video/x-matroska,audio/mpeg"
              id="videoFile"
              required
              disabled={progress === 100}
              fileName={content.videoFileName}
              icon={
                contentType === "video" ? (
                  <i className="far fa-file-video fa-lg"></i>
                ) : (
                  <i className="far fa-file-audio fa-lg"></i>
                )
              }
              handleFileChange={handleVideoUpload}
            />
            <div className="py-2" align="center">
              <ButtonGroup variant="text" color="primary">
                <IconButton
                  size="small"
                  disabled={
                    progress === 100 || videoFile === null || disableUpload
                  }
                  onClick={uploadVideo}
                >
                  <CircularProgressWithLabel value={progress} />
                </IconButton>

                <IconButton
                  disabled={
                    progress !== 100 || disableUpload === true ? true : false
                  }
                  onClick={deleteVideo}
                >
                  <DeleteForeverOutlinedIcon className="text-primary" />
                </IconButton>
              </ButtonGroup>
            </div>
          </div>

          <div className={contentType === "video" ? "col-md-6 py-1" : "d-none"}>
            <Typography align="center" gutterBottom variant="subtitle1">
              Upload Video Thumbnail
            </Typography>
            <UploadInput
              accept="image/png,image/jpeg"
              id="thumbnailFile"
              required
              disabled={vprogress === 100}
              fileName={content.videoThumbnail.name}
              icon={<i className="fas fa-photo-video" />}
              handleFileChange={handleVideoThumbnailUpload}
            />
            <div className="py-2" align="center">
              <ButtonGroup variant="text" color="primary">
                <IconButton
                  size="small"
                  disabled={
                    vprogress === 100 ||
                    videoThumbnail === null ||
                    disableUpload
                  }
                  onClick={uploadVideoThumbnail}
                >
                  <CircularProgressWithLabel value={vprogress} />
                </IconButton>

                <IconButton
                  disabled={
                    vprogress !== 100 || disableUpload === true ? true : false
                  }
                  onClick={deleteVideoThumbnail}
                >
                  <DeleteForeverOutlinedIcon className="text-primary" />
                </IconButton>
              </ButtonGroup>
            </div>
          </div>
        </div>
      ) : (
        contentType !== "text" && (
          <div className="py-1">
            <TextField
              variant="outlined"
              type="url"
              fullWidth
              id="videoURL"
              margin="dense"
              label="Audio/Video URL"
              size="medium"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {120 - (content.videoURL.length ?? 0)}{" "}
                    <HttpIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              inputProps={{ minLength: 0, maxLength: 120 }}
              value={content.videoURL ?? ""}
              onChange={(e) =>
                setContent({
                  ...content,
                  videoURL: e.target.value,
                })
              }
            />
          </div>
        )
      )}

      <div className="form-row">
        <div className="col-md-8 py-1">
          <TagInput label="Tags" id="tags" tags={tags} setTags={setTags} />
        </div>
        <div className="col-md-4 py-1">
          <TextField
            fullWidth
            type="number"
            required
            size="medium"
            margin="dense"
            variant="outlined"
            label="Duration (minutes)"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 0, max: 450 }}
            id="videoDuration"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTimeIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={content.videoDuration ?? 0}
            helperText="Audio/video duration or in-case of text based content enter average reading duration"
            onChange={(e) =>
              setContent({
                ...content,
                videoDuration: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="py-1">
        <TextField
          type="file"
          fullWidth
          margin="dense"
          variant="outlined"
          size="medium"
          label="Attachment"
          accept="application/pdf,text/plain"
          id="attachment"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AttachFileIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          helperText={
            content.hasAttachment &&
            content.attachment &&
            content.attachment.name ? (
              <Typography gutterBottom variant="caption">
                Selected: {content.attachment.name}
              </Typography>
            ) : (
              "(Optional) Upload PDF and TXT File only."
            )
          }
          onChange={handleAttachment}
        />
      </div>

      <RichTextArea
        name="content"
        label="Content Description"
        placeholder="Write content description."
        value={content.videoDescription}
        maxLength={4050}
        handleRTEChange={(val) => {
          if (val.length > 4050) {
            return;
          } else {
            setContent({ ...content, videoDescription: val });
          }
        }}
      />
      <Typography gutterBottom variant="subtitle2">
        Quiz and exam can be added after course created by visting edit option
        of respective course.
      </Typography>

      <button
        className="btn btn-sm btn-outline-primary"
        onClick={addContentToSection}
      >
        <VideoCallIcon fontSize="small" /> Add Content
      </button>
    </div>
  );
};

export default AddContent;
