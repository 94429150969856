import axios from "axios";
import { Status } from "../../components/layout/Alerts";

export const addFeature = (data, setDisable) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.post("/api/beta/createFeature", { ...data });
      if (res) {
        console.log(res.data);
        dispatch({ type: "REGISTER_FEATURE", payload: res.data });
        Status({
          text: "<h5>Feature Registered and mailed to developer.</h5>",
          type: "success",
        });
      }
      setDisable(false);
    } catch (error) {
      setDisable(false);
      console.log(error);
      Status({
        text: "<h5>Failed to register feature.</h5>",
        type: "error",
      });
    }
  })();
};

export const getFeatures = (skip = 0) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/beta/features?skip=${skip}`);
      if (res) {
        console.log(res.data);
        if (skip > 0) {
          dispatch({ type: "LOAD_MORE_FEATURES", payload: res.data });
        } else {
          dispatch({ type: "GET_FEATURES", payload: res.data });
        }
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const changeFeatureStatus = ({ featureId, status }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.put("/api/beta/changeFeatureStatus", {
        featureId,
        status,
      });
      if (res) {
        console.log(res.data);
        dispatch({ type: "FEATURE_STATUS", payload: { featureId, status } });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const deleteFeature = ({ featureId }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "DELETE",
        url: `/api/beta/deleteFeature`,
        data: {
          featureId,
        },
      });
      if (res) {
        console.log(res.data);
        dispatch({ type: "FEATURE_DELETE", payload: featureId });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const askForBetaAccess = (userId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/beta/askUserToJoin/${userId}`);
      if (res) {
        console.log(res.data);
        dispatch({
          type: "BETA_ACCESS_STATUS",
          payload: { userId },
        });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const revokeUserBetaAccess = (userId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/beta/revokeBetaAccess/${userId}`);
      if (res) {
        console.log(res.data);
        dispatch({
          type: "BETA_ACCESS_REVOKE",
          payload: { userId },
        });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const userAcceptBetaAccess = (userId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/beta/userAcceptBeta/${userId}`);
      if (res) {
        console.log(res.data);
        dispatch({ type: "USER_BETA_ACCESS", payload: true });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const userRejectBetaAccess = (userId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/beta/userRejectBeta/${userId}`);
      if (res) {
        console.log(res.data);
        dispatch({ type: "USER_BETA_ACCESS", payload: false });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

export const feedback = ({ data, userId }) => {
  (async () => {
    try {
      let res = await axios.post(`/api/activity/addFeedback/${userId}`, {
        ...data,
      });
      if (res) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

async function fetchFeedbackByfeature(
  featureId,
  skip,
  dispatch,
  newCache = undefined
) {
  try {
    let res = await axios.get(
      `/api/activity/feedbackByFeature/${featureId}?skip=${skip}`
    );
    if (res) {
      console.log("fetch feedbacks");
      if (skip > 0) {
        dispatch({ type: "LOAD_MORE_FEATURE_FEEDBACK", payload: res.data });
      } else {
        dispatch({ type: "FEATURE_FEEDBACK", payload: res.data });
      }
      if (newCache !== undefined) {
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            date: new Date(),
          }),
        };
        newCache.put(
          `/feedbacks-${featureId}-${skip}`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const feedbackByfeature = ({ featureId, skip = 0 }) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = new Request(`//feedbacks-${featureId}-${skip}`);
        const newCache = await caches.open("admin");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 2 minutes
          if (Date.now() > date.getTime() + 1000 * 60 * 2) {
            console.log("admin feedback cache Expired!");
            newCache.delete(request);
            fetchFeedbackByfeature(featureId, skip, dispatch, newCache);
          } else {
            console.log("cache admin feedback");
            let data = await response.json();
            if (skip > 0) {
              dispatch({ type: "LOAD_MORE_FEATURE_FEEDBACK", payload: data });
            } else {
              dispatch({ type: "FEATURE_FEEDBACK", payload: data });
            }
          }
        } else {
          fetchFeedbackByfeature(featureId, skip, dispatch, newCache);
        }
      } else {
        fetchFeedbackByfeature(featureId, skip, dispatch);
      }
    } catch (error) {
      fetchFeedbackByfeature(featureId, skip, dispatch);
    }
  })();
};
