const initState = {
  terms: "",
  privacy: "",
  courseGuide: "",
};
const policyReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "POLICY_UPDATE":
      if (payload.policyType === "terms") {
        return {
          ...state,
          terms: payload,
        };
      } else if (payload.policyType === "course") {
        return {
          ...state,
          courseGuide: payload,
        };
      } else {
        return {
          ...state,
          privacy: payload,
        };
      }
    case "PRIVACY":
      return {
        ...state,
        privacy: payload,
      };
    case "TERMS":
      return {
        ...state,
        terms: payload,
      };
    case "COURSE_GUIDE":
      return {
        ...state,
        courseGuide: payload,
      };
    default:
      return state;
  }
};

export default policyReducer;
