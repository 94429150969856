const initState = {
  quiz: {},
  loading: false,
  listOfQuiz: null,
  result: null,
};
const quizReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CREATE_QUIZ":
      return {
        ...state,
        quiz: payload,
        loading: false,
      };
    case "GET_QUIZ_BY_INSTRUCTOR":
      return {
        ...state,
        quiz: { ...payload },
        loading: false,
      };
    case "GET_QUIZ_BY_STUDENT":
      return {
        ...state,
        listOfQuiz: payload,
      };
    case "GET_QUIZ":
      return {
        ...state,
        quiz: { ...payload },
      };
    case "DELETE_QUIZ":
      return {
        ...state,
        quiz: null,
      };
    case "RESULT":
      return {
        ...state,
        result: payload,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default quizReducer;
