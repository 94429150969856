const initState = {
  cartCount: 0,
  cartItems: null,
  courses: null,
  errors: "",
  dailyLimit: null,
  totalAmount: 0,
  transactions: null,
  msg: "",
  transaction: null,
  redirect: false, //* use to redirect on transaction complete
};

const paymentReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "ADD_ITEM":
      return {
        ...state,
        cartCount: payload.length,
        cartItems: [...payload],
        redirect: false,
      };
    case "CHECK_CART":
      return {
        ...state,
        cartCount: payload.length,
        cartItems: [...payload],
      };
    case "REMOVE_ITEM":
      //* remove course from courses
      let tmp = state.courses.filter((crs) => crs._id !== payload.courseId);
      console.log(tmp);
      let total2 = 0;
      if (tmp.length > 0) {
        tmp.forEach((crs) => {
          total2 += crs.cost;
        });
      }
      console.log(total2);
      return {
        ...state,
        cartCount: payload.newCartItems.length,
        cartItems: [...payload.newCartItems],
        courses: [...tmp],
        totalAmount: total2,
      };
    case "CLEAR_CART":
      console.log("clear cart");
      localStorage.removeItem("cart");
      return {
        ...state,
        cartCount: 0,
        cartItems: [],
        courses: null,
        errors: "",
        totalAmount: 0,
        dailyLimit: null,
      };
    case "CART_ITEMS":
      let total = 0;
      if (payload.length > 0) {
        payload.forEach((crs) => {
          total += crs.cost;
        });
      }
      return {
        ...state,
        courses: [...payload],
        totalAmount: total,
      };
    case "CART_ERROR":
      return {
        ...state,
        errors: payload.msg,
      };
    case "PURCHASE_LIMIT":
      return {
        ...state,
        dailyLimit: payload,
      };
    case "TRANSACTION_HISTORY":
      return {
        ...state,
        transactions: [...payload],
      };
    case "LOAD_TRANSACTION_HISTORY":
      if (payload.length === 0) {
        return {
          ...state,
          msg: "no more data",
        };
      } else {
        return {
          ...state,
          transactions: [...state.transactions, ...payload],
        };
      }
    case "TRANSACTION_COMPLETE":
      return {
        ...state,
        transaction: payload,
        redirect: true,
      };
    case "DISABLE_REDIRECT":
      return {
        ...state,
        redirect: false,
      };
    case "SET_TRANSACTION":
      console.log(payload);
      return {
        ...state,
        transaction: payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
