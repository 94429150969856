const initState = {
  event: null,
  events: null,
  msg: "",
  eventsByInstructor: null,
  msg2: "",
};

const eventReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CREATE_EVENT":
      return {
        ...state,
        event: payload,
      };
    case "UPDATE_EVENT":
      return {
        ...state,
        event: payload,
      };
    case "GET_EVENT":
      return {
        ...state,
        event: payload,
      };
    case "GET_EVENTS":
      return {
        ...state,
        events: payload,
      };
    case "LOAD_MORE_EVENTS":
      if (payload.length === 0) {
        return {
          ...state,
          msg: "no more events",
        };
      } else {
        return {
          ...state,
          events: [...state.events, ...payload],
        };
      }
    case "GET_INSTRUCTOR_EVENTS":
      return {
        ...state,
        eventsByInstructor: payload,
      };
    case "LOAD_MORE_INSTRUCTOR_EVENTS":
      if (payload.length === 0) {
        return {
          ...state,
          msg2: "no more events",
        };
      } else {
        return {
          ...state,
          eventsByInstructor: [...state.eventsByInstructor, ...payload],
        };
      }
    case "EVENT_DELETE":
      let tmp = state.eventsByInstructor.filter(
        (event) => event._id !== payload
      );
      return {
        ...state,
        events: null,
        eventsByInstructor: [...tmp],
      };
    case "DELETE_EVENT_BY_ADMIN":
      let tmp2 = state.events.filter((evs) => evs._id !== payload);
      return {
        ...state,
        events: [...tmp2],
      };

    default:
      return state;
  }
};

export default eventReducer;
