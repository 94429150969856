import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectedContent from "./SelectedContent";
import {
  Typography,
  Fade,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import {
  getSignUrl,
  deleteUploadedVideo,
  updateCourse,
} from "../../../store/actions/courseActions";
import RichTextArea from "../common/RichTextArea";
import UploadInput from "../common/UploadInput";
import NextPrev from "../common/NextPrev";
import Tooltip from "../../layout/Tooltip";
import SideBar from "../../navbar/SideBar";
import AddContent from "../common/AddContent";

import TranslateIcon from "@material-ui/icons/Translate";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import TitleIcon from "@material-ui/icons/Title";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import SubjectIcon from "@material-ui/icons/Subject";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import LibraryAddOutlinedIcon from "@material-ui/icons/LibraryAddOutlined";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import PolicyOutlinedIcon from "@material-ui/icons/PolicyOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

import CreateExam from "./CreateExam";
import CreateQuiz from "./CreateQuiz";
import axios from "axios";
import CourseGuidelines from "../../policy/CourseGuidelines";
import { Confirm, Status } from "../../layout/Alerts";
import "./styles.scss";

const opt = [
  {
    name: "Basic Details",
    component: "basic",
    icon: <AddToQueueIcon fontSize="small" />,
  },
  {
    name: "Thumbnail",
    component: "thumb",
    icon: <AddPhotoAlternateOutlinedIcon fontSize="small" />,
  },
  {
    name: "Description",
    component: "desc",
    icon: <DescriptionOutlinedIcon fontSize="small" />,
  },
  {
    name: "Section",
    component: "section",
    icon: <LibraryAddOutlinedIcon fontSize="small" />,
  },
  {
    name: "Content",
    component: "content",
    icon: <VideoLibraryOutlinedIcon fontSize="small" />,
  },
  {
    name: "Selected Content",
    component: "selected",
    icon: <PlaylistAddCheckIcon fontSize="small" />,
  },
  {
    name: "Save",
    component: "save",
    icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
  },
  {
    name: "Quiz",
    component: "quiz",
    icon: <AssignmentIndOutlinedIcon fontSize="small" />,
  },
  {
    name: "Exam",
    component: "exam",
    icon: <BallotOutlinedIcon fontSize="small" />,
  },
  {
    name: "Guidelines",
    component: "guide",
    icon: <PolicyOutlinedIcon fontSize="small" />,
  },
];

const CourseEditForm = ({ courseData, courseId }) => {
  const user = useSelector((state) => state.auth.user);
  const subjects = useSelector((state) => state.course.subject);
  const languages = useSelector((state) => state.course.language);
  const history = useHistory();
  const dispatch = useDispatch();
  const [component, setComponent] = useState("basic");
  const [disable, setDisable] = useState(false);
  const [videosToDelete, setVideosToDelete] = useState([]);

  //* form fields
  const [courseTitle, setCourseTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [cost, setCost] = useState(0);
  const [institution, setInstitution] = useState("");
  const [certificate, setCertificate] = useState(true);
  const [subject, setSubject] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailName, setThumbnailName] = useState("");
  const [institutes, setInstitutes] = useState([]);

  //* content fields
  const [contentType, setContentType] = useState("video");
  const [content, setContent] = useState({
    videoTitle: "",
    sequenceNo: 1,
    videoURL: "",
    videoFileName: "",
    videoDuration: 0,
    videoDescription: "",
    hasAttachment: "",
    attachment: { name: "" },
    uploadable: true,
    videoThumbnail: { name: "", url: "" },
    tempCourseId: "",
    tempId: "",
    tempSectionId: "",
    tempCreatedAt: "",
    tempUpdatedAt: "",
    tempQuizId: "",
    tempHasQuiz: "",
  });
  const [tags, setTags] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [attachment, setAttachment] = useState(null); //? hold single attachment till video added
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [vprogress, setVprogress] = useState(0);
  const [attachments, setAttachments] = useState([]); //? contains list of files and there video sequence number
  const [submit, setSubmit] = useState(false);
  const [contentList, setContentList] = useState([]);
  const [disableUpload, setDisableUpload] = useState(false);

  //* sections
  const [section, setSection] = useState({
    sectionNo: 1,
    title: "",
    description: "",
    duration: 0,
    tempSectionId: "",
    tempCourseId: "",
    usedSequencePerSection: [],
    contents: [],
  });
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(0);
  const [usedSequencePerSection, setUsedSequencePerSection] = useState([]);
  const [usedSectionNo, setUsedSectionNo] = useState([]);

  useEffect(() => {
    if (courseData.cData && courseData.vData) {
      console.log(courseData);
      let inst = "";
      if (user.institution) {
        inst = [user.institution];
      }
      if (user.owner) {
        inst = [user.owner];
      }
      if (!user.owner && !user.institution) {
        inst = [user];
      }

      //* handle old course structure cases
      if (
        courseData.sections === undefined ||
        courseData.sections === null ||
        courseData.sections.length === 0
      ) {
        //? here we only have contents , let see create a section and embed contents to section
        let nextSequenceNo = 1;
        let tmp3 = [];
        for (let content of courseData.vData) {
          tmp3.push(content.video.sequenceNo);
        }
        nextSequenceNo = tmp3[tmp3.length - 1] + 1 ?? 1;
        let sectionData = {
          sectionNo: 1,
          title: courseData.cData[0].courseTitle.substr(0, 100),
          description: courseData.cData[0].shortDescription.substr(0, 250),
          duration: 0,
          usedSequencePerSection: [...tmp3],
          contents: [...courseData.vData],
        };
        setContent({ ...content, sequenceNo: nextSequenceNo });
        setSection({ ...section, sectionNo: 2 });
        setUsedSequencePerSection([...tmp3]);
        setSections([sectionData]);
        setUsedSectionNo([1]);
        setSelectedSection(1);
      } else {
        let tmp = courseData.sections;
        let tmp2 = [];
        let nextSequenceNo = 1;
        let nextSectionNo = 1;
        for (let sect of tmp) {
          let tmp3 = [];
          tmp2.push(sect.sectionNo);
          for (let content of sect.contents) {
            tmp3.push(content.video.sequenceNo);
          }
          sect.usedSequencePerSection = tmp3;
        }
        nextSequenceNo =
          tmp[0].usedSequencePerSection[
            tmp[0].usedSequencePerSection.length - 1
          ] + 1 ?? 1;
        nextSectionNo = tmp2[tmp2.length - 1] + 1 ?? 1;
        setUsedSectionNo([...tmp2]);
        setSections(courseData.sections);
        setSection({ ...section, sectionNo: nextSectionNo });
        setContent({ ...content, sequenceNo: nextSequenceNo });
        setUsedSequencePerSection(tmp[0].usedSequencePerSection);
        setSelectedSection(1);
      }

      setCourseTitle(courseData.cData[0].courseTitle);
      setContentList(courseData.vData);
      setThumbnailName(courseData.cData[0].thumbnail.name ?? "");
      setShortDescription(courseData.cData[0].shortDescription);
      setLongDescription(courseData.cData[0].longDescription);
      setSubject(courseData.cData[0].subject);
      setLanguage(courseData.cData[0].language);
      setCost(courseData.cData[0].cost);
      setCertificate(courseData.cData[0].certificate);
      setInstitutes(inst);
      setLongDescription(courseData.cData[0].longDescription);
      setInstitution(inst[0]._id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setContent({ ...content });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const handleChange = (e) => {
    if (e.target.name === "uploadable") {
      if (
        content.uploadable === true &&
        videoFile !== null &&
        progress === 100
      ) {
        Status({
          text:
            "<h5>Please first delete uploaded video then switch to url.</h5>",
          type: "warning",
        });
        return;
      }
      if (content.uploadable === false && content.videoURL.length > 1) {
        Status({
          text: "<h5>Please delete url then switch to video upload.</h5>",
          type: "warning",
        });
        return;
      }
      setContent({
        ...content,
        uploadable: e.target.value === true ? true : false,
      });
      return;
    }
  };

  const handleAttachment = (e) => {
    if (e.target.files[0] === undefined) {
      Status({ text: "<h5>Please select a file.</h5>", type: "warning" });
      return;
    }
    const fsize = e.target.files[0].size;
    const file = Math.round(fsize / 1024);
    const ftype = e.target.files[0].type;
    if (file >= 4096) {
      Status({
        text: "<h5>Please select a file less than 4mb.</h5>",
        type: "warning",
      });
      return;
    }
    if (ftype !== "application/pdf" && ftype !== "text/plain") {
      Status({
        text: "<h5>Only .pdf and .txt files are supported.</h5>",
        type: "warning",
      });
      return;
    }
    setAttachment(e.target.files[0]);
    setContent({
      ...content,
      hasAttachment: true,
      attachment: { name: e.target.files[0].name },
    });
  };

  const thumbnailChange = (e) => {
    if (e.target.files[0] === undefined) {
      Status({ text: "<h5>Please Select a file.</h5>", type: "warning" });
      return;
    }
    const fsize = e.target.files[0].size;
    const type = e.target.files[0].type;
    const file = Math.round(fsize / 1024);
    if (type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
      Status({
        text: "<h5>Only PNG, JPEG/JPG allowed.</h5>",
        type: "warning",
      });
      return;
    }
    if (file >= 4096) {
      Status({
        text: "<h5>Please select a file less than 4mb.</h5>",
        type: "warning",
      });
      return;
    } else {
      setThumbnailName(e.target.files[0].name);
      setThumbnail(e.target.files[0]);
    }
  };

  const addContentToSection = (e) => {
    e.preventDefault();
    if (usedSequencePerSection.includes(Number(content.sequenceNo))) {
      Status({
        text: `<h5>Content Sequence Number ${content.sequenceNo} is all ready used.</h5>`,
        type: "warning",
      });
      return;
    }
    if (
      content.videoTitle === undefined ||
      content.videoTitle.trim().length < 4 ||
      content.videoTitle.length > 100
    ) {
      Status({
        text: `<h5>Content title must have minimum 4 and maximum 100 letters.</h5>`,
        type: "warning",
      });
      return;
    }

    if (
      content.videoDescription === undefined ||
      content.videoDescription.trim().length < 10 ||
      content.videoDescription.length > 4050
    ) {
      Status({
        text: `<h5>Content Description must have minimum 10 and maximum 4050 letters.</h5>`,
        type: "warning",
      });
      return;
    }
    if (
      content.videoDuration === undefined ||
      content.videoDuration <= 0 ||
      content.videoDuration > 450
    ) {
      Status({
        text: `<h5>Content duration must be in between 1 and 450 minutes.</h5>`,
        type: "warning",
      });
      return;
    }
    function isUrl(s) {
      // eslint-disable-next-line
      var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return regexp.test(s);
    }
    console.log(content);
    if (contentType !== "text" && content.videoURL.length < 1) {
      Status({
        text: "<h5>Please upload necessary video/audio file.</h5>",
        type: "warning",
      });
      return;
    }
    if (contentType !== "text") {
      if (content.uploadable === true && progress !== 100) {
        Status({
          text: "<h5>Let video/audio file upload to 100%.</h5>",
          type: "warning",
        });
        return;
      } else if (content.uploadable === false && !isUrl(content.videoURL)) {
        Status({
          text: "<h5>Let video/audio file upload to 100%.</h5>",
          type: "warning",
        });
        return;
      }
    }

    if (
      contentType === "video" &&
      content.uploadable === true &&
      (content.videoFileName === "" || content.videoThumbnail.name === "")
    ) {
      Status({
        text: "<h5>Please upload video file and its thumbnail.</h5>",
        type: "warning",
      });
      return;
    }

    let video = {
      sequenceNo: Number(content.sequenceNo),
      videoTitle: content.videoTitle,
      videoType: contentType,
      videoURL: contentType === "text" ? "" : content.videoURL,
      videoDuration: content.videoDuration,
      videoFileName: content.videoFileName,
      uploadable: contentType === "text" ? false : content.uploadable,
      hasAttachment: attachment
        ? true
        : content.hasAttachment !== undefined
        ? content.hasAttachment
        : false,
      videoThumbnail: content.videoThumbnail,
      attachment: attachment //? handle attachment in case no attachment and prevent old data
        ? { name: attachment.name }
        : content.attachment.name !== undefined
        ? { ...content.attachment }
        : { name: "" },
      videoDescription: content.videoDescription,
      tags: tags,
    };

    // for old videos put back preserve data that temporary store in state
    let myObj = {};
    if (content.tempCourseId) {
      myObj.courseID = content.tempCourseId;
    }
    if (content.tempQuizId.length > 0) {
      myObj.quizId = content.tempQuizId;
    }
    if (content.tempHasQuiz === true || content.tempHasQuiz === false) {
      myObj.quizId = content.tempQuizId;
    }
    if (content.tempId.length > 0) {
      myObj._id = content.tempId;
    }
    if (content.tempSectionId) {
      myObj.sectionId = content.tempSectionId;
    }
    if (content.tempCreatedAt.length > 0) {
      myObj.createdAt = content.tempCreatedAt;
    }
    if (content.tempUpdatedAt.length > 0) {
      myObj.updatedAt = content.tempUpdatedAt;
    }

    //!important wrap old data if exist else it treated as new content
    let singleVideo = {
      ...myObj,
      video: video,
    };
    console.log("after video added ", singleVideo);
    let tempc = usedSequencePerSection;
    tempc.push(parseInt(content.sequenceNo));
    setUsedSequencePerSection([...tempc]);
    //? find max sequence number alloted
    function compare(a, b) {
      const s1 = Number(a.video.sequenceNo);
      const s2 = Number(b.video.sequenceNo);
      let comparison = 0;
      if (s1 > s2) {
        comparison = 1;
      } else if (s1 < s2) {
        comparison = -1;
      }
      return comparison;
    }

    //? work on section
    let selectedSectionData = sections;
    let index = selectedSection - 1;
    let sortedContent = [
      ...selectedSectionData[index].contents,
      singleVideo,
    ].sort(compare);

    selectedSectionData[index] = {
      sectionNo: selectedSection,
      title: selectedSectionData[index].title,
      description: selectedSectionData[index].description,
      contents: sortedContent,
      usedSequencePerSection: [...tempc],
    };
    setSections([...selectedSectionData]);
    let temp = sortedContent;
    let maxSequenceNo = parseInt(temp[temp.length - 1].video.sequenceNo);
    let allAttachments = attachments;
    let emptyVideoFileInput = content.uploadable === true ? true : false;
    if (attachment) {
      allAttachments.push({
        sequenceNo: parseInt(content.sequenceNo),
        file: attachment,
        name: attachment.name,
      });
    }

    setContentType("video");
    setContent({
      ...content,
      tempCourseId: "",
      tempCreatedAt: "",
      tempUpdatedAt: "",
      tempQuizId: "",
      tempHasQuiz: "",
      tempId: "",
      tempSectionId: "",
      videoTitle: "",
      videoFileName: "",
      videoDescription: "",
      sequenceNo: maxSequenceNo + 1,
      videoURL: "",
      videoThumbnail: { name: "", url: "" },
      attachment: { name: "" },
      videoDuration: 0,
    });
    setVideoFile(null);
    setVideoThumbnail(null);
    setProgress(0);
    setVprogress(0);
    setAttachments(allAttachments);
    setAttachment(null);
    setTags([]);
    if (allAttachments && document.getElementById("attachment")) {
      document.getElementById("attachment").value = "";
    }
    if (emptyVideoFileInput && document.getElementById("videoFile")) {
      document.getElementById("videoFile").value = "";
    }
  };

  const editContentFromSection = (sequenceNo, sectionNo) => {
    setSelectedSection(sectionNo);
    setComponent("content");
    let sectionIndex = sections.findIndex(
      (sect) => parseInt(sect.sectionNo) === parseInt(sectionNo)
    );
    if (sectionIndex === -1) {
      return;
    }
    console.log(sequenceNo, sectionIndex, sectionNo, sections[sectionIndex]);
    //* remove content from sections as well
    let tmp22 = sections[sectionIndex];
    let contentIndex = tmp22.contents.findIndex(
      (cnt) => parseInt(cnt.video.sequenceNo) === parseInt(sequenceNo)
    );
    if (contentIndex === -1) {
      return;
    }
    let currentContent = tmp22.contents.splice(contentIndex, 1);
    sections[sectionIndex].contents = [...tmp22.contents];
    setSections([...sections]);
    console.log(currentContent);

    let myObj = {};

    if (currentContent[0]._id) {
      // means it is old video that is going to edit so preserve this information temporary
      // As new video don't have document Id
      myObj = {
        tempCourseId: currentContent[0].courseID,
        tempId: currentContent[0]._id,
        tempSectionId: currentContent[0].sectionId,
        hasQuiz: currentContent[0].hasQuiz,
        quizId: currentContent[0].quizId,
        tempCreatedAt: currentContent[0].createdAt,
        hasAttachment: currentContent[0].video.hasAttachment,
        tempUpdatedAt: currentContent[0].updatedAt,
      };
    }

    //? remove sequence used
    let i = tmp22.usedSequencePerSection.findIndex(
      (i) => i === Number(currentContent[0].video.sequenceNo)
    );
    let tempp = tmp22.usedSequencePerSection;
    if (i !== -1) {
      tempp.splice(i, 1);
    }
    setUsedSequencePerSection([...tempp]);

    let allAttachments = attachments;
    let tempAttachment;
    if (allAttachments) {
      let index = allAttachments.findIndex(
        (at) => Number(at.sequenceNo) === Number(sequenceNo)
      );
      if (index !== -1) {
        tempAttachment = allAttachments.splice(index, 1);
      }
    }

    setSubmit(false);
    let mj = {};
    if (
      currentContent[0].video.attachment &&
      currentContent[0].video.attachment.path !== undefined
    ) {
      mj.path = currentContent[0].video.attachment.path;
      mj.type = currentContent[0].video.attachment.type;
    }
    let data = {
      ...content,
      ...myObj,
      videoTitle: currentContent[0].video.videoTitle,
      sequenceNo: currentContent[0].video.sequenceNo,
      uploadable: currentContent[0].video.uploadable ?? false,
      videoURL: currentContent[0].video.videoURL,
      videoDuration: currentContent[0].video.videoDuration,
      videoFileName: currentContent[0].video.videoFileName ?? "",
      videoDescription: currentContent[0].video.videoDescription,
      hasAttachment: currentContent[0].video.hasAttachment ? true : false,
      videoThumbnail: currentContent[0].video.videoThumbnail
        ? currentContent[0].video.videoThumbnail
        : { name: "", url: "" },
      attachment: currentContent[0].video.attachment
        ? { name: currentContent[0].video.attachment.name, ...mj }
        : { name: "" },
    };
    console.log("video for edit ", data);
    setContent(data);
    setAttachment(
      tempAttachment !== undefined && tempAttachment[0]
        ? tempAttachment[0].file ?? null
        : null
    );
    setProgress(
      currentContent[0].video.uploadable === true &&
        currentContent[0].video.videoFileName
        ? 100
        : 0
    );
    setVprogress(
      currentContent[0].video.uploadable === true &&
        currentContent[0].video.videoThumbnail &&
        currentContent[0].video.videoThumbnail.name
        ? 100
        : 0
    );
    setTags(currentContent[0].video.tags ?? []);
    setAttachments(allAttachments);

    if (
      currentContent[0].video.videoType !== null &&
      currentContent[0].video.videoType !== undefined
    ) {
      setContentType(currentContent[0].video.videoType);
    } else {
      setContentType("video");
    }
  };

  const removeSectionContent = async (sequenceNo, sectionNo) => {
    const ask = await Confirm({
      text: `<h5>Do you want to delete Content Sequence No ${sequenceNo}?</h5>`,
    });
    if (ask === true) {
      //* remove content from section
      let index = sections.findIndex(
        (sect) => parseInt(sect.sectionNo) === parseInt(sectionNo)
      );
      if (index === -1) {
        return;
      }
      let tmp22 = sections[index];
      let vidz = tmp22.contents.filter((conten) => {
        if (
          Number(conten.video.sequenceNo) === Number(sequenceNo) &&
          conten.video.uploadable === true
        ) {
          if (conten.video.videoURL) {
            let filePath = conten.video.videoURL;
            //deleteUploadedVideo(filePath);
            // save id to delete latter when course saved
            setVideosToDelete([...videosToDelete, filePath]);
          }
          if (conten.video.videoThumbnail && conten.video.videoThumbnail.url) {
            let thumbnailPath = conten.video.videoThumbnail.url;
            //deleteUploadedVideo(thumbnailPath);
            // save id to delete latter when course saved
            setVideosToDelete([...videosToDelete, thumbnailPath]);
          }
        }
        return Number(conten.video.sequenceNo) !== Number(sequenceNo);
      });
      vidz.forEach((v) => {
        if (Number(v.video.sequenceNo) > Number(sequenceNo)) {
          v.video.sequenceNo = Number(v.video.sequenceNo - 1);
        }
      });
      let tmpSeq = tmp22.usedSequencePerSection.filter(
        (v) => parseInt(v) !== parseInt(sequenceNo)
      );
      console.log(tmpSeq);
      sections[index].contents = [...vidz];
      sections[index].usedSequencePerSection = tmpSeq;
      setSections([...sections]);

      let allAttachments = attachments;
      let tempp2 = [];
      if (allAttachments) {
        tempp2 = allAttachments.filter(
          (at) => parseInt(at.sequenceNo) !== parseInt(sequenceNo)
        );
      }

      setAttachment(null);
      setAttachments(tempp2);
      setSubmit(false);
    }
  };

  const handleVideoUpload = (e) => {
    if (e.target.files[0] === undefined) {
      Status({ text: "<h5>Please select a file.</h5>", type: "warning" });
      return;
    }
    const fsize = e.target.files[0].size;
    const type = e.target.files[0].type;
    const file = Math.round(fsize / 1024);
    if (
      contentType === "video" &&
      type !== "video/mp4" &&
      type !== "video/x-matroska"
    ) {
      Status({
        text: "<h5>Only MP4, MKV file type allowed.</h5>",
        type: "warning",
      });
      return;
    }
    if (contentType === "audio" && type !== "audio/mpeg") {
      Status({
        text: "<h5>Only MP3 file type allowed.</h5>",
        type: "warning",
      });
      return;
    }
    if (file >= 1048576) {
      //? 1gb max file size
      Status({
        text: "<h5>Please select a file less than 1gb.</h5>",
        type: "warning",
      });
      return;
    } else {
      setVideoFile(e.target.files[0]);
      setContent({
        ...content,
        videoFileName: e.target.files[0].name,
        uploadable: true,
      });
    }
  };

  const handleVideoThumbnailUpload = (e) => {
    if (e.target.files[0] === undefined) {
      Status({ text: "<h5>Please select a file.</h5>", type: "warning" });
      return;
    }
    const fsize = e.target.files[0].size;
    const type = e.target.files[0].type;
    const file = Math.round(fsize / 1024);
    if (
      contentType === "video" &&
      type !== "image/png" &&
      type !== "image/jpeg"
    ) {
      Status({
        text: "<h5>Only PNG, JPEG file type allowed.</h5>",
        type: "warning",
      });
      return;
    }
    if (file > 2096) {
      //? 2mb max file size
      Status({
        text: "<h5>Please select a file less than 2mb.</h5>",
        type: "warning",
      });
      return;
    } else {
      setVideoThumbnail(e.target.files[0]);
      setContent({
        ...content,
        videoThumbnail: {
          name: e.target.files[0].name,
          url: "",
        },
      });
    }
  };

  const uploadVideoThumbnail = async () => {
    if (videoThumbnail === null || videoThumbnail === undefined) {
      return;
    }
    setDisableUpload(true);
    let fileType = videoThumbnail.type;
    let fileName = videoThumbnail.name;
    let accessType = "upload";
    if (
      contentType === "video" &&
      fileType !== "image/png" &&
      fileType !== "image/jpeg"
    ) {
      Status({
        text: "<h5>Only PNG, JPEG file allowed.</h5>",
        type: "error",
      });
      return;
    }
    if (contentType === "audio") {
      Status({
        text: "<h5>No thumbnail upload allowed for audio content.</h5>",
        type: "error",
      });
      return;
    }
    if (contentType === "text") {
      Status({
        text: "<h5>No thumbnail upload allowed for text content.</h5>",
        type: "error",
      });
      return;
    }
    setVprogress(1);
    let res = await getSignUrl({ fileName, fileType, accessType });
    if (res) {
      let returnData = res.returnData;
      let signedRequest = returnData.signedRequest;
      let options = {
        headers: {
          "Content-Type": fileType,
          "Content-Disposition": "inline",
        },
        onUploadProgress: (progressEvent) => {
          let uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          setVprogress(uploadPercentage);
        },
      };
      axios
        .put(signedRequest, videoThumbnail, options)
        .then((result) => {
          setContent({
            ...content,
            videoThumbnail: {
              url: returnData.filePath,
              name: content.videoThumbnail.name,
            },
            uploadable: true,
          });
          setDisableUpload(false);
        })
        .catch((error) => {
          console.log(error);
          setContent({ ...content, videoThumbnail: { name: "", url: "" } });
          setVideoThumbnail(null);
          setDisableUpload(false);
          setVprogress(0);
          Status({
            text: "<h5>Failed to upload video thumbnail.</h5>",
            type: "error",
          });
        });
    } else {
      setContent({ ...content, videoThumbnail: { name: "", url: "" } });
      setVideoThumbnail(null);
      setVprogress(0);
      setDisableUpload(false);
    }
  };

  const deleteVideoThumbnail = async () => {
    if (!content.videoThumbnail.url) {
      return;
    }
    setDisableUpload(true);
    let res = await deleteUploadedVideo(content.videoThumbnail.url);
    if (res) {
      setContent({ ...content, videoThumbnail: { name: "", url: "" } });
      setVideoThumbnail(null);
      setDisableUpload(false);
      setVprogress(0);
    } else {
      setDisableUpload(false);
    }
  };

  const uploadVideo = async () => {
    if (videoFile === null || videoFile === undefined) {
      return;
    }
    setDisableUpload(true);
    let fileType = videoFile.type;
    let fileName = videoFile.name;
    let accessType = "upload";
    if (contentType === "audio" && fileType !== "audio/mpeg") {
      Status({
        text: "<h5>For audio content, only MP3 file allowed.</h5>",
        type: "error",
      });
      return;
    }
    if (
      contentType === "video" &&
      fileType !== "video/mp4" &&
      fileType !== "video/x-matroska"
    ) {
      Status({
        text: "<h5>For video content, only MP4, MKV file allowed.</h5>",
        type: "error",
      });
      return;
    }
    if (contentType === "text") {
      Status({
        text: "<h5>No upload allowed for text content.</h5>",
        type: "error",
      });
      return;
    }
    setProgress(1);
    let res = await getSignUrl({ fileName, fileType, accessType });
    if (res) {
      let returnData = res.returnData;
      let signedRequest = returnData.signedRequest;
      let options = {
        headers: {
          "Content-Type": fileType,
          "Content-Disposition": "inline",
        },
        onUploadProgress: (progressEvent) => {
          let uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          setProgress(uploadPercentage);
        },
      };
      axios
        .put(signedRequest, videoFile, options)
        .then((result) => {
          setContent({
            ...content,
            videoURL: returnData.filePath,
            videoFileName: fileName,
            uploadable: true,
          });
          setDisableUpload(false);
        })
        .catch((error) => {
          console.log(error);
          setContent({ ...content, videoFileName: "", videoURL: "" });
          setVideoFile(null);
          setDisableUpload(false);
          setProgress(0);

          Status({ text: "<h5>Failed to upload video.</h5>", type: "error" });
        });
    } else {
      setContent({ ...content, videoFileName: "", videoURL: "" });
      setVideoFile(null);
      setDisableUpload(false);
      setProgress(0);
    }
  };

  const deleteVideo = async () => {
    setVideosToDelete([...videosToDelete, content.videoURL]);
    setContent({
      ...content,
      videoURL: "",
      videoFileName: "",
    });
    setVideoFile(null);
    setProgress(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submit === false) {
      console.log("trying to submit without submit button click");
      return;
    }
    if (courseTitle.trim().length < 5 || courseTitle.length > 100) {
      Status({
        text: "<h5>Course Title must between 5 to 100 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (shortDescription.trim().length < 10 || shortDescription.length > 250) {
      Status({
        text:
          "<h5>Short Description must be in between 10 to 250 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (longDescription.trim().length < 50 || longDescription.length > 5500) {
      Status({
        text:
          "<h5>Long Description must be in between 50 to 5500 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (subject.trim().length < 2 || subject.length > 50) {
      Status({
        text: "<h5>Subject must be in between 2 and 50 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (institution.length < 2 || institution > 100) {
      Status({
        text: "<h5>Institution must be in between 2 to 100 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (!language) {
      Status({ text: "<h5>Language is required.</h5>", type: "warning" });
      return;
    }

    //* check each section must have atleast 1 content
    let index = sections.findIndex((sect) => {
      return sect.contents.length === 0;
    });
    if (index !== -1) {
      Status({
        text: "<h5>Make sure each section must have atleast 1 content.</h5>",
        type: "warning",
      });
      return;
    }
    let editedCourse = {
      courseTitle: courseTitle,
      shortDescription: shortDescription,
      language: language,
      cost: cost,
      subject: subject,
      certificate: certificate + "",
      institution: institution,
      longDescription: longDescription,
      sections: sections,
    };
    if (thumbnail) {
      setDisable(true);
      let thumbnailData = new FormData();
      thumbnailData.set("thumbnail", thumbnail);
      if (attachments.length > 0) {
        let attachment = new FormData();
        attachments.forEach((el) => {
          attachment.append(
            "attachment",
            el.file,
            el.name + "$$" + el.sequenceNo
          );
        });
        dispatch(
          updateCourse({
            history,
            editedCourse,
            courseId,
            thumbnailData,
            attachment,
            setDisable,
            videosToDelete,
          })
        );
      } else {
        let attachment = null;
        dispatch(
          updateCourse({
            history,
            editedCourse,
            courseId,
            thumbnailData,
            attachment,
            setDisable,
            videosToDelete,
          })
        );
      }
    } else {
      setDisable(true);
      if (attachments.length > 0) {
        let attachment = new FormData();
        let thumbnailData = null;
        attachments.forEach((el) => {
          attachment.append(
            "attachment",
            el.file,
            el.name + "$$" + el.sequenceNo
          );
        });
        dispatch(
          updateCourse({
            history,
            editedCourse,
            courseId,
            thumbnailData,
            attachment,
            setDisable,
            videosToDelete,
          })
        );
      } else {
        let attachment = null;
        let thumbnailData = null;
        dispatch(
          updateCourse({
            history,
            editedCourse,
            courseId,
            thumbnailData,
            attachment,
            setDisable,
            videosToDelete,
          })
        );
      }
    }
  };

  const addSection = () => {
    let { title, description, sectionNo } = section;
    if (!title || title.trim().length < 4 || title.length > 100) {
      Status({
        text: "<h5>Section Title must have 4 to 100 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (
      !description ||
      description.trim().length < 4 ||
      description.length > 250
    ) {
      Status({
        text: "<h5>Section Description must have 4 to 250 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (!sectionNo || sectionNo < 1 || sectionNo > 250) {
      Status({
        text: "<h5>Section No must have 4 to 250.</h5>",
        type: "warning",
      });
      return;
    }
    if (usedSectionNo.includes(sectionNo)) {
      Status({
        text: `<h5>Section number ${sectionNo} is used.</h5>`,
        type: "warning",
      });
      return;
    }
    setUsedSectionNo((prev) => {
      return [...prev, sectionNo];
    });
    let editSectionIndex = sections.findIndex(
      (section) => parseInt(section.sectionNo) === parseInt(sectionNo)
    );

    if (editSectionIndex !== -1) {
      let tempSection = sections;
      tempSection[editSectionIndex] = {
        ...tempSection[editSectionIndex],
        usedSequencePerSection:
          tempSection[editSectionIndex].usedSequencePerSection,
        title: section.title,
        _id: section.tempSectionId,
        courseId: section.tempCourseId,
        description: section.description,
        sectionNo: section.sectionNo,
      };
      //* sort by sequence no
      let tmpsectionsorted = tempSection.sort(function compare(a, b) {
        const s1 = Number(a.sectionNo);
        const s2 = Number(b.sectionNo);
        let comparison = 0;
        if (s1 > s2) {
          comparison = 1;
        } else if (s1 < s2) {
          comparison = -1;
        }
        return comparison;
      });
      setSections([...tmpsectionsorted]);
    } else {
      setSections((prev) => {
        return [...prev, { ...section, usedSequencePerSection: [] }];
      });
    }
    setSection((prev) => {
      return { ...prev, title: "", description: "", sectionNo: sectionNo + 1 };
    });
  };

  const editSection = (sectionNo) => {
    setComponent("section");
    let index = sections.findIndex(
      (sect) => parseInt(sect.sectionNo) === parseInt(sectionNo)
    );
    if (index === -1) {
      return;
    }
    let tmp = sections[index];
    let tmp2 = usedSectionNo.filter(
      (secNo) => parseInt(secNo) !== parseInt(sectionNo)
    );
    setUsedSectionNo([...tmp2]);
    setUsedSequencePerSection(tmp.usedSequencePerSection);
    setSection({
      sectionNo: sectionNo,
      title: tmp.title,
      description: tmp.description,
      duration: tmp.duration,
      tempSectionId: tmp._id,
      tempCourseId: tmp.courseId,
      usedSequencePerSection: tmp.usedSequencePerSection,
      contents: [],
    });
  };

  const deleteSection = async (sectionNo) => {
    const ask = await Confirm({
      text: `<h5>Do you want to delete Section-${sectionNo}. It will remove all content of this section as well?</h5>`,
    });
    if (ask === true) {
      let sectionIndex = sections.findIndex(
        (section) => parseInt(section.sectionNo) === parseInt(sectionNo)
      );
      if (sectionIndex === -1) {
        return;
      }
      let tmp = sections.splice(sectionIndex, 1);
      let attach = attachments;
      for (let content of tmp[0].contents) {
        if (content.video.videoURL) {
          let filePath = content.video.videoURL;
          deleteUploadedVideo(filePath);
        }
        if (content.video.videoThumbnail.url) {
          let thumbnailPath = content.video.videoThumbnail.url;
          deleteUploadedVideo(thumbnailPath);
        }
        if (content.video.hasAttachment) {
          let indexAttach = attach.findIndex(
            (at) => parseInt(at.sequenceNo) === content.video.sequenceNo
          );
          if (indexAttach !== -1) {
            attach.splice(indexAttach, 1);
          }
        }
      }
      sections.forEach((sect) => {
        if (parseInt(sect.sectionNo) > parseInt(sectionNo)) {
          sect.sectionNo = parseInt(sect.sectionNo) - 1;
        }
      });
      setSections([...sections]);
      setAttachments(attach);
      if (sections.length >= 1) {
        let last = sections[sections.length - 1].sectionNo;
        let nextMax = parseInt(last) + 1;
        setSection({ ...section, sectionNo: nextMax });
        //* remove sectionNo form usedSectionNo state as other no are reduced by 1 therefore last one get freed
        let tmpSectionSeq = usedSectionNo.filter(
          (seq) => parseInt(seq) !== nextMax
        );
        setUsedSectionNo([...tmpSectionSeq]);
      } else {
        setUsedSectionNo([]);
        setSection({ ...section, sectionNo: 1 });
      }
    }
  };

  const handleSectionChange = (e) => {
    let index = sections.findIndex((sect) => sect.sectionNo === e.target.value);
    if (index === -1) {
      return;
    }
    let list =
      sections[index].usedSequencePerSection &&
      sections[index].usedSequencePerSection.length > 0
        ? sections[index].usedSequencePerSection.sort((a, b) =>
            a > b ? 1 : -1
          )
        : [];

    let max =
      sections[index].usedSequencePerSection &&
      sections[index].usedSequencePerSection.length > 0
        ? list[list.length - 1] + 1
        : 1;

    setUsedSequencePerSection(list);
    setContent({
      ...content,
      sequenceNo: max,
    });
    setSelectedSection(e.target.value);
  };

  const swapContent = ({
    sectionIndex,
    fromIndex,
    fromSequenceNo,
    toIndex,
    toSequenceNo,
  }) => {
    try {
      let tmp = sections;
      console.log(
        `sectionIndex: ${sectionIndex}| fromIndex: ${fromIndex}|
      fromSequence: ${fromSequenceNo}|
      toIndex: ${toIndex}| toSequence: ${toSequenceNo} `
      );
      tmp[sectionIndex].contents[fromIndex].video.sequenceNo = parseInt(
        toSequenceNo
      );
      tmp[sectionIndex].contents[toIndex].video.sequenceNo = parseInt(
        fromSequenceNo
      );
      let s1 = tmp[sectionIndex].contents[fromIndex];
      tmp[sectionIndex].contents[fromIndex] =
        tmp[sectionIndex].contents[toIndex];
      tmp[sectionIndex].contents[toIndex] = s1;
      console.log(tmp);
      setSections([...tmp]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="my-2">
        <SideBar
          options={opt}
          event={(item) => setComponent(item)}
          active={component}
        />
      </div>
      <div className="my-2">
        <form
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="bg-white rounded"
        >
          {component === "basic" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="py-2 px-sm-2 px-md-4">
                  <div className="form-row">
                    <div className="col-md-8 my-2">
                      <TextField
                        type="text"
                        id="courseTitle"
                        label="Course Title"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {100 - (courseTitle.length ?? 0)}{" "}
                              <TitleIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        size="medium"
                        placeholder="Course Title"
                        inputProps={{ minLength: 4, maxLength: 100 }}
                        required
                        value={courseTitle ?? ""}
                        onChange={(e) => setCourseTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <TextField
                        id="institution"
                        required
                        label="Institution"
                        select
                        fullWidth
                        margin="dense"
                        size="medium"
                        value={institution}
                        variant="outlined"
                        onChange={(e) => setInstitution(e.target.value)}
                      >
                        {institutes &&
                          institutes.map((int, i) => {
                            return (
                              <MenuItem key={i} value={int._id}>
                                {int.name}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md my-2">
                      <TextField
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        label="Language"
                        id="language"
                        required
                        size="medium"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {25 - (language.length ?? 0)}{" "}
                              <TranslateIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          minLength: 1,
                          maxLength: 25,
                          list: "languages",
                        }}
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      />
                      <datalist id="languages">
                        {languages &&
                          languages.map((sub, i) => (
                            <option key={i}>{sub}</option>
                          ))}
                      </datalist>
                    </div>
                    <div className="col-md my-2">
                      <TextField
                        type="text"
                        id="subject"
                        label="Subject"
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        size="medium"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {50 - (subject.length ?? 0)}{" "}
                              <SubjectIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Subject"
                        required
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                          list: "subjects",
                        }}
                        value={subject ?? ""}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <datalist id="subjects">
                        {subjects &&
                          subjects.map((sub, i) => (
                            <option key={i}>{sub}</option>
                          ))}
                      </datalist>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md my-2">
                      <TextField
                        type="number"
                        label="Cost"
                        margin="dense"
                        fullWidth
                        id="cost"
                        variant="outlined"
                        placeholder="Cost in $"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 0, max: 1000 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MonetizationOnIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        required
                        value={cost ?? 0}
                        onChange={(e) => setCost(e.target.value)}
                      />
                    </div>
                    <div className="col-md my-2">
                      <TextField
                        id="certificate"
                        required
                        select
                        fullWidth
                        margin="dense"
                        size="medium"
                        variant="outlined"
                        label="Certificate"
                        value={certificate}
                        onChange={(e) => setCertificate(e.target.value)}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </div>
                  </div>
                </div>
              </Fade>
              <NextPrev
                next={"thumb"}
                prev={"guide"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "thumb" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="py-2 px-sm-2 px-md-4">
                  <div className="pt-4">
                    <Typography align="center" variant="body1" gutterBottom>
                      Select Course Thumbnail
                    </Typography>
                    <UploadInput
                      name="thumbnail"
                      id="thumbnail"
                      required
                      accept="image/png,image/jpeg,image/jpg"
                      fileName={thumbnailName !== null ? thumbnailName : ""}
                      icon={<i className="far fa-image fa-lg"></i>}
                      handleFileChange={thumbnailChange}
                    />
                  </div>
                </div>
              </Fade>
              <NextPrev
                next={"desc"}
                prev={"basic"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "desc" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="py-2 px-sm-2 px-md-4">
                  <div className="py-2">
                    <TextField
                      type="text"
                      id="shortDescription"
                      label="Short Description"
                      fullWidth
                      inputProps={{ minLength: 5, maxLength: 250 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {250 - (shortDescription.length ?? 0)}{" "}
                            <SubtitlesIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      size="medium"
                      margin="dense"
                      variant="outlined"
                      helperText="Write a short description about course."
                      value={shortDescription ?? ""}
                      onChange={(e) => setShortDescription(e.target.value)}
                    />
                  </div>
                  <RichTextArea
                    name="longDesc"
                    label="Long Description"
                    placeholder="Write full description."
                    value={longDescription}
                    maxLength={5500}
                    handleRTEChange={(val) => {
                      if (val.length > 5500) {
                        return;
                      } else {
                        setLongDescription(val);
                      }
                    }}
                  />
                </div>
              </Fade>
              <NextPrev
                next={"section"}
                prev={"thumb"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "section" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="py-2 px-sm-2 px-md-4">
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    Course Section
                  </Typography>
                  <div className="py-2" align="center">
                    <Tooltip interactive title="Add section">
                      <IconButton
                        onClick={() => addSection()}
                        size="small"
                        color="primary"
                      >
                        <LibraryAddOutlinedIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="form-row">
                    <div className="col-md-2 py-2">
                      <TextField
                        id="sectionNo"
                        type="number"
                        label="Section Number"
                        fullWidth
                        required
                        variant="outlined"
                        size="small"
                        margin="dense"
                        value={section.sectionNo}
                        onChange={(e) => {
                          setSection((prev) => {
                            return {
                              ...prev,
                              sectionNo: parseInt(e.target.value),
                            };
                          });
                        }}
                        inputProps={{ min: 1, max: 100 }}
                      />
                    </div>
                    <div className="col-md-10 py-2">
                      <TextField
                        id="sectionTitle"
                        type="text"
                        label="Section Title"
                        fullWidth
                        required
                        variant="outlined"
                        size="small"
                        margin="dense"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {100 - (section.title.length ?? 0)}{" "}
                              <TitleIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        value={section.title}
                        onChange={(e) =>
                          setSection((prev) => {
                            return { ...prev, title: e.target.value };
                          })
                        }
                        inputProps={{ minLength: 4, maxLength: 100 }}
                      />
                    </div>
                  </div>
                  <div className="py-2">
                    <TextField
                      id="sectionDescription"
                      required
                      label="Section description"
                      multiline
                      rows={4}
                      rowsMax={4}
                      margin="dense"
                      inputProps={{ minLength: 0, maxLength: 250 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {250 - (section.description.length ?? 0)}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      variant="outlined"
                      value={section.description}
                      onChange={(e) =>
                        setSection((prev) => {
                          return { ...prev, description: e.target.value };
                        })
                      }
                    />
                  </div>
                </div>
              </Fade>
              <NextPrev
                next={"content"}
                prev={"desc"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "content" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div>
                  <div className="px-md-4 px-sm-2 py-2">
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      id="sections"
                      margin="dense"
                      size="medium"
                      label="Select Course Section"
                      required
                      value={selectedSection ?? 1}
                      onChange={handleSectionChange}
                      helperText="Current content will be added to selected section"
                    >
                      {sections && sections.length > 0 ? (
                        sections.map((sect, i) => (
                          <MenuItem key={i} value={sect.sectionNo}>
                            {sect.title.length > 40
                              ? sect.title.substr(0, 40) + "..."
                              : sect.title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={1}>None</MenuItem>
                      )}
                    </TextField>
                  </div>
                  <AddContent
                    content={content}
                    contentType={contentType}
                    handleAttachment={handleAttachment}
                    handleChange={handleChange}
                    tags={tags}
                    handleVideoUpload={handleVideoUpload}
                    setTags={setTags}
                    videoFile={videoFile}
                    setContentType={setContentType}
                    progress={progress}
                    addContentToSection={addContentToSection}
                    uploadVideo={uploadVideo}
                    deleteVideo={deleteVideo}
                    setContent={setContent}
                    vprogress={vprogress}
                    videoThumbnail={videoThumbnail}
                    handleVideoThumbnailUpload={handleVideoThumbnailUpload}
                    uploadVideoThumbnail={uploadVideoThumbnail}
                    deleteVideoThumbnail={deleteVideoThumbnail}
                    disableUpload={disableUpload}
                  />
                </div>
              </Fade>
              <NextPrev
                next={"selected"}
                prev={"section"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "selected" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="py-2 px-sm-2 px-md-4">
                  <Typography
                    variant="body1"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    <PlaylistAddCheckIcon fontSize="large" /> Selected Content
                  </Typography>
                  <SelectedContent
                    sections={sections}
                    editContentFromSection={editContentFromSection}
                    disableEditButton={
                      content.videoTitle.length > 0 ? true : false
                    }
                    removeSectionContent={removeSectionContent}
                    editSection={editSection}
                    deleteSection={deleteSection}
                    swapContent={swapContent}
                  />
                </div>
              </Fade>
              <NextPrev
                next={"save"}
                prev={"desc"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
          {component === "save" ? (
            <section>
              <Fade in={true} timeout={980}>
                <div className="my-2 px-sm-2 px-md-4">
                  <div className="submit">
                    <button
                      type="submit"
                      disabled={disable}
                      onClick={(e) => setSubmit(true)}
                      className="btn btn-outline-primary btn-md"
                    >
                      <SaveOutlinedIcon />{" "}
                      {disable ? "Saving..." : "Save Changes"}
                    </button>
                  </div>
                </div>
              </Fade>
              <NextPrev
                next={"guide"}
                prev={"selected"}
                changeComponent={(item) => setComponent(item)}
              />
            </section>
          ) : null}
        </form>
        {component === "quiz" ? (
          <section className="bg-white">
            <Fade in={true} timeout={980}>
              <div className="my-2 px-sm-2 px-md-4 bg-white rounded">
                <CreateQuiz courseId={courseId} videos={contentList} />
              </div>
            </Fade>
            <NextPrev
              next={"save"}
              prev={"exam"}
              changeComponent={(item) => setComponent(item)}
            />
          </section>
        ) : null}
        {component === "exam" ? (
          <section className="bg-white">
            <Fade in={true} timeout={980}>
              <div className="my-2 px-sm-2 px-md-4 bg-white rounded">
                <CreateExam courseId={courseId} />
              </div>
            </Fade>
            <NextPrev
              next={"quiz"}
              prev={"guide"}
              changeComponent={(item) => setComponent(item)}
            />
          </section>
        ) : null}
        {component === "guide" ? (
          <section className="bg-white">
            <Fade in={true} timeout={980}>
              <div className="py-2 px-sm-2 px-md-4">
                <CourseGuidelines />
              </div>
            </Fade>
            <NextPrev
              next={"quiz"}
              prev={"basic"}
              changeComponent={(item) => setComponent(item)}
            />
          </section>
        ) : null}
      </div>
    </div>
  );
};

export default CourseEditForm;
