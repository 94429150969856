export default async function checkCacheLimit() {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    navigator.storage.estimate().then(({ usage, quota }) => {
      console.log(`Using ${usage} out of ${quota} bytes.`);
      if (usage >= 52428800) {
        //? x>50mb then free cache
        if ("caches" in window) {
          console.log("clearing cache");
          caches.delete("course-imgs").catch((err) => console.log(err));
          caches.delete("course-details").catch((err) => console.log(err));
          caches.delete("institutes").catch((err) => console.log(err));
          caches.delete("profile").catch((err) => console.log(err));
          caches.delete("instructor").catch((err) => console.log(err));
          caches.delete("events").catch((err) => console.log(err));
          caches.delete("blogs").catch((err) => console.log(err));
        }
      }
    });
  }
}
