import React from "react";
import NotFound from "../../images/404.png";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <div className="container-md my-5" align="center">
        <img
          className="img-fluid"
          loading="lazy"
          src={NotFound}
          alt="404"
          style={{ height: "12rem" }}
        />
        <Typography gutterBottom variant="body1">
          The page you are looking for does not exist,{" "}
          <Link className="text-decoration-none" to="/">
            click here
          </Link>{" "}
          to go to the homepage.
        </Typography>
      </div>
    </React.Fragment>
  );
};

export default ErrorPage;
