import axios from "axios";
import { Status } from "../../components/layout/Alerts";
import GAEvent from "../../helpers/GAEvent";

export const signUp = (newUser, history, setDisable) => (dispatch) => {
  axios
    .post("/api/signup", newUser)
    .then((res) => {
      setDisable(false);
      GAEvent({ category: "Auth", action: "New user signup." });
      history.push("/gotoemail"); // if status is 200
    })
    .catch((err) => {
      dispatch({
        type: "SIGNUP_ERROR",
        err,
      });
      setDisable(false);
      if (err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    });
};

export const signIn = (userData) => (dispatch) => {
  axios
    .post("/api/signin", userData)
    .then((res) => {
      const { user } = res.data;
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("syncSignIn", true);
      GAEvent({ category: "Auth", action: "SignIn" });
      dispatch({
        type: "SET_CURRENT_USER",
        payload: user,
      });
    })
    .catch((err) => {
      dispatch({
        type: "SIGNIN_ERROR",
      });
      if (err.response && err.response.data.msg) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    });
};

export const getCurrentUser = () => (dispatch) => {
  axios
    .get("/api/auth/currentUser")
    .then((res) => {
      localStorage.setItem("loggedIn", true);
      dispatch({ type: "SET_CURRENT_USER", payload: res.data.user });
    })
    .catch((err) => {
      console.log("SignIn cookie expired", err);
      dispatch({ type: "SIGNIN_EXPIRED" });
      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      //localStorage.setItem("loggedIn", false);
      localStorage.setItem("syncSignOut", true);
      if ("caches" in window) {
        caches.delete("course-details").catch((err) => console.log(err));
        caches.delete("course-category").catch((err) => console.log(err));
        caches.delete("institutes").catch((err) => console.log(err));
        caches.delete("blogs").catch((err) => console.log(err));
        caches.delete("profile").catch((err) => console.log(err));
        caches.delete("admin").catch((err) => console.log(err));
        caches.delete("instructor").catch((err) => console.log(err));
        caches.delete("priest").catch((err) => console.log(err));
        caches.delete("events").catch((err) => console.log(err));
      }
    });
};

export const socialLogin = (provider, data, device, setStatus) => (
  dispatch
) => {
  (async () => {
    try {
      let result;
      if (provider === "facebook") {
        result = await axios.post("/api/auth/facebookverify", {
          user: data,
          device,
        });
      }
      if (provider === "github") {
        result = await axios.post("/api/auth/githubverify", {
          code: data.code,
          device,
        });
      }
      if (provider === "google") {
        result = await axios.post("/api/auth/googleverify", {
          tokenId: data.tokenId,
          googleId: data.googleId,
          device,
        });
      }
      if (result) {
        // localStorage.setItem("jwtToken", result.data.token);
        // localStorage.setItem("user", JSON.stringify(result.data.user));
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("syncSignIn", true);
        GAEvent({
          category: "Auth",
          action: "Social Media Login.",
          label: "" + provider,
        });
        setStatus(null);
        dispatch({
          type: "SET_CURRENT_USER",
          payload: result.data.user,
        });
      }
    } catch (err) {
      setStatus(null);
      dispatch({
        type: "SIGNIN_ERROR",
      });
      if (err.response && err.response.data.msg) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    }
  })();
};

export const signOut = () => (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  localStorage.setItem("syncSignOut", true);
  axios
    .get("/api/auth/signout")
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  if ("caches" in window) {
    caches.delete("course-details").catch((err) => console.log(err));
    caches.delete("course-category").catch((err) => console.log(err));
    caches.delete("institutes").catch((err) => console.log(err));
    caches.delete("blogs").catch((err) => console.log(err));
    caches.delete("profile").catch((err) => console.log(err));
    caches.delete("admin").catch((err) => console.log(err));
    caches.delete("instructor").catch((err) => console.log(err));
    caches.delete("priest").catch((err) => console.log(err));
    caches.delete("events").catch((err) => console.log(err));
  }
  dispatch({ type: "SIGNOUT_USER" });
};

export const activate = async (token, setDisable, history) => {
  try {
    let res = await axios.post("/api/auth/account-activation", { token });
    if (res) {
      console.log(res.data);
      setDisable(false);
      Status({ text: `<h5>${res.data.msg}</h5>`, type: "success" });
      GAEvent({ category: "Auth", action: "New user account activated." });
      history.push("/signin");
    }
  } catch (err) {
    setDisable(false);
    if (err && err.response && err.response.data) {
      Status({ text: `<h5>${err.response.data.msg}</h5>`, type: "error" });
    } else {
      Status({
        text: `<h5>Something went wrong, Try again later.</h5>`,
        type: "error",
      });
    }
  }
};

export const forgotPassword = async (values, setValues, setDisable) => {
  try {
    let res = await axios.put("/api/auth/forgot-password", values);
    if (res) {
      // save userinfo in localstorage and token in cookie for safer
      GAEvent({ category: "User", action: "User forgot password." });
      setDisable(true);
      setValues({
        ...values,
        email: "",
        msg: "Link send to your email to reset password",
      });
    }
  } catch (err) {
    console.log(err);
    setDisable(true);
    if (err.response && err.response.data) {
      setValues({
        ...values,
        email: "",
        msg: err.response.data.msg,
      });
    } else {
      setValues({
        ...values,
        email: "",
        msg: "Something went wrong",
      });
    }
  }
};

export const resetPassword = async (data, setMsg, setRedirect, setDisable) => {
  try {
    let res = await axios.put("/api/auth/reset-password", data);
    if (res) {
      setDisable(false);
      setMsg("Password is reset, now login with new password!");
      GAEvent({ category: "User", action: "User reset password." });
      setRedirect(true);
      Status({
        text:
          "<h5>Your password is changed, you can login with new password.</h5>",
        type: "info",
      });
    }
  } catch (err) {
    console.log(err.response);
    setDisable(false);
    if (err.response && err.response.data) {
      Status({ text: `<h5>${err.response.data.msg}</h5>`, type: "error" });
    } else {
      Status({ text: `<h5>Something went wrong./h5>`, type: "error" });
    }
  }
};

export const deleteAccount = (id, history) => (dispatch) => {
  axios({
    method: "delete",
    url: `/api/user/delete/${id}`,
  })
    .then((res) => {
      localStorage.clear();

      if ("caches" in window) {
        caches.delete("profile").catch((err) => console.log(err));
        caches.delete("courses-details").catch((err) => console.log(err));
        caches.delete("courses-category").catch((err) => console.log(err));
        caches.delete("institutes").catch((err) => console.log(err));
        caches.delete("blogs").catch((err) => console.log(err));
      }

      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      dispatch({
        type: "SIGNOUT_USER",
      });
      GAEvent({ category: "User", action: "User account deleted." });
      history.push("/signup");
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    });
};
