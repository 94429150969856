import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import NavMenu from "./components/navbar/NavMenu";
import Router from "./router";
import store from "./store/store";
import Footer from "./components/footer";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Status } from "./components/layout/Alerts";

import "./App.scss";
import { getCurrentUser } from "./store/actions/authActions";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#087e8b" },
  },
});

const App = () => {
  useEffect(() => {
    //! here send current user request after browser open close
    ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`, {
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <CheckLogin>
          <>
            <div className="app">
              <NavMenu />
              <Suspense
                fallback={
                  <div className="container my-5" align="center">
                    <CircularProgress color="primary" />
                  </div>
                }
              >
                <MuiThemeProvider theme={theme}>
                  <Router />
                </MuiThemeProvider>
              </Suspense>
            </div>
            <Footer />
          </>
        </CheckLogin>
      </BrowserRouter>
    </Provider>
  );
};

const CheckLogin = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("loggedIn") && auth.isAuthenticated === false) {
      localStorage.removeItem("syncSignOut");
      dispatch(getCurrentUser());
    }

    window.addEventListener("storage", (event) => {
      console.table(event.key, event);
      if (event.key === "syncSignOut" && event.newValue === "true") {
        console.log("syncSignOut");
        dispatch({
          type: "SIGNOUT_USER",
        });
        window.location.href = "/signin";
      }
      if (event.key === "syncSignIn" && auth.isAuthenticated === false) {
        console.log("syncSignIn");
        window.location.href = "/dashboard";
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.signInExpired) {
      Status({
        text: `<h4>SignIn Session has exipred!</h4>
     <h5>Please <a className='btn btn-md btn-outline-primary text-white text-decoration-none' href='./signin'>
     Sign-In</a> again to continue.</h5>`,
        type: "error",
        showConfirmButton: false,
      });
    }
  }, [auth.signInExpired]);

  return <>{children}</>;
};

export default App;
