import axios from "axios";
import { Status } from "../../components/layout/Alerts";

export const createQuiz = ({ userId, data, courseId, videoId }) => (
  dispatch
) => {
  (async () => {
    try {
      let res = await axios({
        method: "POST",
        url: `/api/quiz/addQuiz/${userId}`,
        data: { courseId, videoId, ...data },
      });
      if (res && res.data) {
        dispatch({
          type: "CREATE_QUIZ",
          payload: res.data.qu,
        });
        Status({ text: res.data.msg, type: "success" });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "danger" });
      }
    }
  })();
};

export const getQuizByInstructor = ({ quizId }) => (dispatch) => {
  (async () => {
    try {
      dispatch({
        type: "LOADING",
      });
      let res = await axios({
        method: "get",
        url: `/api/quiz/getQuizById/${quizId}`,
      });
      if (res.data) {
        dispatch({
          type: "GET_QUIZ_BY_INSTRUCTOR",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: "ERROR" });
    }
  })();
};

export const getQuizByStudent = (videoId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "get",
        url: `/api/quiz/getQuiz/${videoId}`,
      });
      if (res.data) {
        dispatch({
          type: "GET_QUIZ",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  })();
};

export const submitQuiz = ({ userId, quizId, courseId, videoId, response }) => (
  dispatch
) => {
  (async () => {
    try {
      localStorage.removeItem("result");
      let body = { quizId, videoId, courseId, response };
      let res = await axios({
        method: "post",
        url: `/api/quiz/addScoreToStudent/${userId}`,

        data: { ...body },
      });
      if (res.data) {
        localStorage.setItem("result", JSON.stringify(res.data));
        dispatch({
          type: "RESULT",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      }
    }
  })();
};

export const getResult = ({ quizId }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/quiz/getResult/${quizId}`,
        method: "GET",
      });
      if (res.data) {
        dispatch({
          type: "RESULT",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  })();
};

export const getListOfQuiz = (user, skip = 0) => (dispatch) => {
  (async () => {
    let userId = user._id;
    let res = await axios({
      method: "GET",
      url: `/api/quiz/getListOfQuizByStudent/${userId}?skip=${skip}`,
    });
    if (res.data) {
      dispatch({
        type: "GET_QUIZ_BY_STUDENT",
        payload: res.data,
      });
    }
  })();
};

export const deleteQuiz = ({ quizId, courseId }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: "/api/quiz/removeQuiz",
        method: "delete",
        data: { quizId, courseId },
      });
      if (res.data) {
        console.log(res.data);
        dispatch({ type: "DELETE_QUIZ" });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
    }
  })();
};
