import React, { useState } from "react";
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import { Confirm } from "../../layout/Alerts";
import { useSelector, useDispatch } from "react-redux";
import QuizForm from "./QuizForm";
import {
  getQuizByInstructor,
  deleteQuiz,
} from "../../../store/actions/quizAction";

const CreateQuiz = ({ courseId, videos }) => {
  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();
  const [selectedContent, setSelectedContent] = useState(null);
  const quiz = useSelector((state) => state.quiz.quiz);

  const handleChange = (e) => {
    setSelectedId(e.target.value);
    let vidz = videos.find((v) => v._id === e.target.value);
    if (vidz !== undefined) {
      setSelectedContent(vidz);
      vidz.quizId && dispatch(getQuizByInstructor({ quizId: vidz.quizId }));
    } else {
      setSelectedContent(null);
    }
  };

  const removeQuiz = async (quizId) => {
    let ask = await Confirm({
      text: "<h5>Do you want to delete this quiz from this chapter?</h5>",
      type: "warning",
    });
    if (ask && quizId) {
      dispatch(deleteQuiz({ quizId, courseId }));
    }
  };

  return (
    <div>
      <Typography variant="h6" align="center" gutterBottom>
        Add Quiz
      </Typography>
      <Typography align="center" gutterBottom variant="subtitle2">
        Select Content to add Quiz
      </Typography>
      <FormControl fullWidth>
        <InputLabel shrink id="contentSelect">
          Content
        </InputLabel>
        <Select
          labelId="contentSelect"
          id="contentSelect"
          value={selectedId}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="">None</MenuItem>
          {videos &&
            videos.map(
              (vidz) =>
                vidz._id && (
                  <MenuItem key={vidz._id} value={vidz._id}>
                    {vidz.video.videoTitle}
                  </MenuItem>
                )
            )}
        </Select>
        <FormHelperText>
          Quiz can added only for saved video content
        </FormHelperText>
      </FormControl>
      <div>
        {selectedContent &&
        quiz !== null &&
        quiz._id !== undefined &&
        quiz.videoId === selectedId ? (
          <div className="my-2">
            <button
              onClick={() => removeQuiz(quiz._id)}
              className="btn btn-sm btn-outline-primary"
            >
              Remove Quiz <RemoveIcon fontSize="small" />
            </button>
          </div>
        ) : null}
        {selectedContent ? (
          <QuizForm quiz={quiz} content={selectedContent} />
        ) : null}
      </div>
    </div>
  );
};

export default CreateQuiz;
