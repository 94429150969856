import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Input,
  FormControl,
  IconButton,
  Select,
  ButtonGroup,
  TextField,
  Typography,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import EditIcon from "@material-ui/icons/Edit";
import { Status, Confirm } from "../../layout/Alerts";
import DeleteIcon from "@material-ui/icons/Delete";
import { createQuiz } from "../../../store/actions/quizAction";
import SendIcon from "@material-ui/icons/Send";
import AssistantPhotoOutlinedIcon from "@material-ui/icons/AssistantPhotoOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import AddIcon from "@material-ui/icons/Add";

const QuizForm = ({ quiz, content }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.quiz.loading);
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState({
    quizName: "",
    totalMarks: 1,
    passingMarks: 1,
    questions: [],
  });

  const [question, setQuestion] = useState({
    sequenceNo: 1,
    question: "",
    type: "text", // or mcq
    correctAnswer: "",
    mark: 1,
  });

  const [options, setOptions] = useState([]);

  const [usedSequence, setUsedSequence] = useState([]);

  const handleChange1 = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleChange2 = (e) => {
    if (e.target.id) {
      setQuestion({ ...question, [e.target.id]: e.target.value });
    } else {
      setQuestion({ ...question, [e.target.name]: e.target.value });
    }
  };

  const addQuestion = (e) => {
    e.preventDefault();
    if (!question.sequenceNo) {
      Status({ text: "<h5>Sequence no is required</h5>", type: "warning" });
      return;
    }
    if (usedSequence.includes(Number(question.sequenceNo))) {
      Status({
        text: `<h5>Sequence No ${question.sequence} is already used</h5>`,
        type: "warning",
      });
      return;
    }
    if (question.question.trim().length < 1 || question.question.length > 350) {
      Status({
        text: "<h5>Question length should be between 1 to 350 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (
      question.correctAnswer.trim().length < 1 ||
      question.correctAnswer.length > 350
    ) {
      Status({
        text: "<h5>Correct answer should be between 1 to 350 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (question.type === "mcq" && !options.includes(question.correctAnswer)) {
      Status({
        text: "<h5>MCQ Option must a have correct Answer option.</h5>",
        type: "warning",
      });
      return;
    }
    let tmp = data.questions;
    let tmp2 = question;
    tmp2.option = options;
    tmp.push(tmp2);
    function compare(a, b) {
      const s1 = Number(a.sequenceNo);
      const s2 = Number(b.sequenceNo);
      let comparison = 0;
      if (s1 > s2) {
        comparison = 1;
      } else if (s1 < s2) {
        comparison = -1;
      }
      return comparison;
    }

    let temp = tmp.sort(compare);
    setData({ ...data, questions: temp });
    setUsedSequence([...usedSequence, Number(question.sequenceNo)]);
    setQuestion({
      sequenceNo: Number(question.sequenceNo) + 1,
      question: "",
      type: "text", // or mcq
      correctAnswer: "",
      mark: 1,
    });
    setOptions([]);
  };

  const deleteQuestion = async (sequenceNo) => {
    let ask = await Confirm({
      text: "<h5>Do you want to remove this question?</h5>",
    });
    if (ask === true) {
      //remove sequence from used state
      let tmp = usedSequence.filter((u) => u !== Number(sequenceNo));
      setUsedSequence(tmp);
      //remove question from set of question
      let tmp2 = data.questions.filter(
        (ques) => Number(ques.sequenceNo) !== Number(sequenceNo)
      );
      // re-arrange question by sequence no
      tmp2.forEach((ques) => {
        if (Number(ques.sequenceNo) > Number(sequenceNo)) {
          ques.sequenceNo = Number(ques.sequenceNo) - 1;
        }
      });
      let nextMaxSeq =
        tmp2.length > 0 ? Number(tmp2[tmp2.length - 1].sequenceNo) + 1 : 1;
      setData({ ...data, questions: tmp2 });
      setQuestion({ ...question, sequenceNo: nextMaxSeq });
    }
  };

  const editQuestion = async (ques) => {
    let tmp = data.questions.filter(
      (q) => Number(q.sequenceNo) !== Number(ques.sequenceNo)
    );
    let tmp2 = usedSequence.filter((u) => u !== Number(ques.sequenceNo));
    if (tmp) {
      setQuestion({
        sequenceNo: ques.sequenceNo,
        question: ques.question,
        type: ques.type,
        correctAnswer: ques.correctAnswer,
        mark: ques.mark,
      });
      if (ques.type === "mcq") {
        setOptions(ques.option);
      }
      setUsedSequence(tmp2);
      setData({ ...data, questions: tmp });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (data.quizName.trim().length < 4 || data.quizName.length > 100) {
      Status({
        text: "<h5>Quiz Name must be in between 4 to 100 letters.</h5>",
        type: "warning",
      });
      return;
    }
    if (data.totalMarks < 1 || data.totalMarks > 1000) {
      Status({
        text: "<h5>Total marks must be in between 1 to 1000.</h5>",
        type: "warning",
      });
      return;
    }
    if (data.passingMarks === 0) {
      Status({ text: "<h5>Passing marks is required.</h5>", type: "warning" });
      return;
    }
    if (data.totalMarks < data.passingMarks) {
      Status({
        text: "<h5>Passing Marks can not be greater then total marks</h5>",
        type: "warning",
      });
      return;
    }
    if (data.questions.length < 1) {
      Status({
        text: "<h5>Quiz must have atleast 1 question.</h5>",
        type: "warning",
      });
      return;
    }
    if (data.questions) {
      let sum = 0;
      data.questions.forEach((q) => {
        sum = q.mark + sum;
      });
      if (Number(sum) !== Number(data.totalMarks)) {
        Status({
          text: `<h5>Marks are not equivalent. Total Marks = ${data.totalMarks}, Sum of each question weight = ${sum} .</h5>`,
          type: "warning",
        });
        return;
      }
    }

    //? all validation done
    if (content.courseID && content._id) {
      dispatch(
        createQuiz({
          userId: user._id,
          data,
          courseId: content.courseID,
          videoId: content._id,
        })
      );
    }
  };

  useEffect(() => {
    if (quiz && quiz._id) {
      setData({
        quizName: quiz.quizName,
        totalMarks: quiz.totalMarks,
        passingMarks: quiz.passingMarks,
        questions: quiz.questions,
      });
      setQuestion({
        ...question,
        sequenceNo:
          Number(quiz.questions[quiz.questions.length - 1].sequenceNo) + 1,
      });
      let tmp = [];
      quiz.questions.forEach((q) => {
        tmp.push(q.sequenceNo);
      });
      setUsedSequence(tmp);
    } else {
      setData({
        quizName: "",
        totalMarks: 1,
        passingMarks: 1,
        questions: [],
      });
      setQuestion({
        sequenceNo: 1,
        question: "",
        type: "text", // or mcq
        correctAnswer: "",
        mark: 1,
      });
      setUsedSequence([]);
    } // eslint-disable-next-line
  }, [content._id, quiz]);

  return (
    <div className="mt-2">
      {loading ? (
        <div align="center">
          <CircularProgress color="primary" />
        </div>
      ) : null}
      <div className="card">
        {/** Quiz Info */}
        <div className="card-header">
          <FormControl className="my-2" fullWidth>
            <InputLabel htmlFor="quizName">Quiz Name</InputLabel>
            <Input
              id="quizName"
              type="text"
              required
              value={data.quizName}
              endAdornment={
                <InputAdornment position="end">
                  {100 - (data.quizName.length ?? 0)}{" "}
                  <TitleOutlinedIcon fontSize="small" />
                </InputAdornment>
              }
              placeholder="Quiz name"
              inputProps={{ minLength: 4, maxLength: 100 }}
              onChange={handleChange1}
            />
          </FormControl>
          <div className="row">
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="totalMarks">Total Marks</InputLabel>
                <Input
                  id="totalMarks"
                  required
                  value={data.totalMarks}
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">
                      <AssistantPhotoOutlinedIcon fontSize="small" />
                    </InputAdornment>
                  }
                  inputProps={{ min: 1, max: 1000 }}
                  placeholder="Total Quiz Marks"
                  onChange={handleChange1}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel htmlFor="passingMarks">Passing Marks</InputLabel>
                <Input
                  id="passingMarks"
                  type="number"
                  value={data.passingMarks}
                  onChange={handleChange1}
                  endAdornment={
                    <InputAdornment position="end">
                      <EmojiEventsOutlinedIcon fontSize="small" />
                    </InputAdornment>
                  }
                  inputProps={{ min: 1, max: 1000 }}
                  required
                  placeholder="Passing marks"
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="card-body">
          {/** Question Box */}
          <div className="row">
            <div className="col-md-4">
              <FormControl fullWidth className="mb-2">
                <InputLabel htmlFor="sequenceNo">Question No</InputLabel>
                <Input
                  type="number"
                  id="sequenceNo"
                  placeholder="question no"
                  onChange={handleChange2}
                  startAdornment={
                    <InputAdornment position="start">
                      <FormatListNumberedIcon fontSize="small" />
                    </InputAdornment>
                  }
                  inputProps={{ min: 1, max: 1000 }}
                  value={question.sequenceNo}
                  required
                />
              </FormControl>
            </div>
            <div className="col-md-4">
              <FormControl fullWidth className="mb-2">
                <InputLabel htmlFor="mark">Question Weight</InputLabel>
                <Input
                  type="number"
                  id="mark"
                  onChange={handleChange2}
                  startAdornment={
                    <InputAdornment position="start">
                      <DonutLargeIcon fontSize="small" />
                    </InputAdornment>
                  }
                  value={question.mark}
                  inputProps={{ min: 1, max: 1000 }}
                  placeholder="question marks"
                  required
                />
              </FormControl>
            </div>
            <div className="col-md-4">
              <FormControl fullWidth className="mb-2">
                <InputLabel htmlFor="type">Answer Type</InputLabel>
                <Select
                  id="type"
                  label="Answer Type"
                  name="type"
                  onChange={handleChange2}
                  value={question.type}
                  startAdornment={
                    <InputAdornment position="start">
                      <MergeTypeIcon fontSize="small" />
                    </InputAdornment>
                  }
                  placeholder="answer type"
                  required
                >
                  <MenuItem value="text">
                    {"  "}TEXT{"  "}
                  </MenuItem>
                  <MenuItem value="mcq">
                    {"  "}MCQ{"  "}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="p-2">
            <FormHelperText>
              All Text field below are case-sensitive
            </FormHelperText>
            <FormControl fullWidth className="mb-2">
              <InputLabel htmlFor="question">Question</InputLabel>
              <Input
                type="text"
                multiline
                rows="3"
                rowsMax={5}
                required
                onChange={handleChange2}
                inputProps={{ minLength: 8, maxLength: 350 }}
                value={question.question}
                endAdornment={
                  <InputAdornment position="end">
                    {350 - (question.question.length ?? 0)}{" "}
                    <HelpOutlineIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Enter question"
                id="question"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="correctAnswer">Correct Answer</InputLabel>
              <Input
                id="correctAnswer"
                onChange={handleChange2}
                value={question.correctAnswer}
                type="text"
                multiline
                required
                endAdornment={
                  <InputAdornment position="end">
                    {350 - (question.correctAnswer.length ?? 0)}
                  </InputAdornment>
                }
                inputProps={{ minLength: 1, maxLength: 350 }}
                placeholder="try to have small answer"
              />
            </FormControl>
            {question.type === "mcq" ? (
              <div className="my-2">
                <Autocomplete
                  multiple
                  id="options"
                  options={[]}
                  value={options}
                  freeSolo
                  disabled={options.length === 5 ? true : false}
                  name="options"
                  onChange={(e) => {
                    let opt = options;
                    opt.push(e.target.value);
                    setOptions([...opt]);
                  }}
                  renderTags={() => {}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="MCQ Option"
                      placeholder="Type Option then Press Enter"
                    />
                  )}
                />
                <FormHelperText>
                  Atmost five option allowed and must include correct answer
                </FormHelperText>
                {options.length > 0 &&
                  options.map((opt, i) => (
                    <Chip
                      key={opt + i + ""}
                      label={i + 1 + " ). " + opt}
                      clickable
                      color="primary"
                      className="m-1"
                      variant="outlined"
                      deleteIcon={<RemoveIcon fontSize="small" />}
                      onDelete={() => {
                        let sk = options;
                        let nsk = sk.filter((entry) => entry !== opt);
                        setOptions(nsk);
                      }}
                    />
                  ))}
              </div>
            ) : null}
            <button
              className="mt-2 btn btn-sm btn-outline-info ripple"
              onClick={addQuestion}
            >
              <AddIcon fontSize="small" /> Add Question
            </button>
          </div>
        </div>
        <div className="card-footer">
          <button
            className="btn btn-sm btn-outline-primary ripple"
            type="submit"
            onClick={submit}
          >
            Add Quiz <SendIcon fontSize="small" />
          </button>
        </div>
      </div>

      {/** List of question */}
      <div className="my-2">
        {data &&
          data.questions.map((ques, i) => (
            <div className="my-2 card" key={i}>
              <div className="card-header">
                <div className="form-row">
                  <div className="col-sm-11">
                    <Typography gutterBottom variant="subtitle2">
                      {ques.sequenceNo}). {ques.question}
                    </Typography>
                  </div>
                  <div className="col-sm-1">
                    <ButtonGroup variant="text" color="primary" size="small">
                      <IconButton
                        onClick={() => editQuestion(ques)}
                        size="small"
                      >
                        <EditIcon fontSize="small" className="text-info" />
                      </IconButton>
                      <IconButton
                        onClick={() => deleteQuestion(ques.sequenceNo)}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" className="text-primary" />
                      </IconButton>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-10">
                    <Typography gutterBottom variant="subtitle2">
                      Correct Answer: {ques.correctAnswer}
                    </Typography>
                  </div>
                  <div className="col-md-2">
                    <Typography gutterBottom variant="subtitle2">
                      Type: {ques.type}
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      Marks: {ques.mark}
                    </Typography>
                  </div>
                </div>
                {ques.type === "mcq" ? (
                  <div>
                    <Typography gutterBottom variant="subtitle2">
                      MCQ Options
                    </Typography>
                    <ol>
                      {ques.option.map((opt, i) => (
                        <Typography
                          key={i}
                          component="li"
                          gutterBottom
                          variant="subtitle2"
                        >
                          {opt}
                        </Typography>
                      ))}
                    </ol>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default QuizForm;
