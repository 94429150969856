const initState = {
  status: false,
  institutionDetails: null,
  courses: null,
  instructors: null,
  institutes: [],
  topperInstructors: null,
  msg3: null,
  over1: false,
  over2: false,
};
const instituteReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_INSTITUTES":
      return {
        ...state,
        institutes: action.payload,
      };
    case "CREATE_INSTITUTE":
      return {
        status: action.status,
      };
    case "GET_INSTITUTE_TOPPER":
      return {
        ...state,
        topperInstructors: [...action.payload],
      };
    case "LOAD_INSTITUTE_TOPPER":
      if (action.payload.length === 0) {
        return {
          ...state,
          msg3: "no more data",
        };
      } else {
        return {
          ...state,
          topperInstructors: [...state.topperInstructors, ...action.payload],
        };
      }

    case "UPDATE_INSTITUTE":
      return {
        ...state,
        institutionDetails: action.payload,
      };
    case "GET_PUBLIC_INSTITUE_DATA":
      return {
        ...state,
        institutionDetails: action.payload,
      };
    case "GET_INSTITUTION_COURSES":
      return {
        ...state,
        courses: action.payload,
        over1: false,
      };
    case "LOAD_MORE_COURSES":
      if (action.payload.length === 0) {
        return {
          ...state,
          over1: true,
        };
      } else {
        return {
          ...state,
          courses: [...state.courses, ...action.payload],
          over1: false,
        };
      }
    case "ADD_INSTRUCTOR":
      //console.log(action.payload);
      //let posts = [...state.posts];
      let temp = [...state.instructors];
      temp.push(action.payload);
      let temp2 = { ...state.institutionDetails };
      temp2.totalInstructors = temp2.totalInstructors + 1;
      //console.log(temp, temp2);
      return {
        ...state,
        instructors: temp,
        institutionDetails: temp2,
      };
    case "REMOVE_INSTRUCTOR":
      //console.log(action.payload);
      let temp3 = state.instructors.filter((ins) => ins._id !== action.payload);
      let temp4 = { ...state.institutionDetails };
      temp4.totalInstructors = temp4.totalInstructors - 1;
      //console.log(temp3, temp4);
      return {
        ...state,
        instructors: temp3,
        institutionDetails: temp4,
      };
    case "DELETE_INSTITUTE":
      return {
        status: false,
        institutionDetails: null,
        courses: null,
        instructors: null,
      };
    case "GET_INSTITUTION_INSTRUCTORS":
      //console.log(action.payload);
      return {
        ...state,
        instructors: action.payload,
        over2: false,
      };
    case "LOAD_MORE_INSTRUCTORS":
      if (action.payload.length === 0) {
        return {
          ...state,
          over2: true,
        };
      } else {
        return {
          ...state,
          instructors: [...state.instructors, ...action.payload],
          over2: false,
        };
      }
    default:
      return state;
  }
};

export default instituteReducer;
