import axios from "axios";
import { Status } from "../../components/layout/Alerts";

export const createExam = ({ examData, attachments, courseId, setDisable }) => (
  dispatch
) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/exam/create`,
        method: "post",
        data: {
          ...examData,
          courseId,
        },
      });
      if (res.data) {
        if (attachments !== null) {
          let examId = res.data._id;
          let res2 = await axios({
            url: `/api/exam/questionAttachment/${examId}`,
            method: "post",
            data: attachments,
            headers: {
              Authorization: "Bearer " + localStorage.jwtToken,
            },
          });
          if (res2) {
            console.log(res2);
            dispatch({
              type: "CREATE_EXAM",
              payload: res2.data,
            });
            setDisable(false);
            Status({ text: "<h5>Exam Updated.</h5>", type: "success" });
          }
        } else {
          dispatch({
            type: "CREATE_EXAM",
            payload: res.data,
          });
          setDisable(false);
          Status({ text: "<h5>Exam Updated.</h5>", type: "success" });
        }
      }
    } catch (error) {
      console.error(error.response);
      setDisable(false);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
    }
  })();
};

export const getExamByInstructor = ({ courseId }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        method: "GET",
        url: `/api/exam/getExamDetails/${courseId}`,
      });
      if (res.data) {
        dispatch({
          type: "GET_EXAM_DATA",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  })();
};

export const deleteExam = (examId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: "/api/exam/deleteExam",
        method: "delete",
        data: { examId },
      });
      if (res.data) {
        console.log(res.data);
        dispatch({ type: "DELETE_EXAM" });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
    }
  })();
};

export const getExamByStudent = (courseId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/exam/getExamDetails/${courseId}`,
        method: "get",
      });
      if (res.data) {
        dispatch({ type: "GET_EXAM_DATA", payload: res.data });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.invalid !== undefined) {
        Status({
          text: `<h5>${error.response.data.invalid}</h5>`,
          type: "error",
        });
        return;
      }
    }
  })();
};

export const examOver = ({ response, examId, courseId, tabSwitch }) => (
  dispatch
) => {
  (async () => {
    try {
      let body = { response, examId, courseId, tabSwitch };
      localStorage.removeItem("result");
      let res = await axios({
        url: `/api/exam/addScoreToStudent`,
        method: "post",

        data: { ...body },
      });
      if (res) {
        console.log(res.data);
        localStorage.setItem("result", JSON.stringify(res.data));
        dispatch({
          type: "RESULT",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data && error.response.data.msg) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      }
    }
  })();
};

export const getAttachment = ({ fileName, fileType }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios({
        url: `/api/exam/getAttachment/${fileName}`,
        method: "get",
        responseType: "blob",
      });
      if (res) {
        if (fileType === "image/png" || fileType === "image/jpeg") {
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(res.data);
          dispatch({
            type: "FILE",
            payload: { data: imageUrl, name: fileName, type: fileType },
          });
        } else {
          //pdf
          dispatch({
            type: "FILE",
            payload: { data: res.data, name: fileName, type: fileType },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  })();
};

export const getExamResult = ({ examId }) => (dispatch) => {
  (async () => {
    try {
      console.log(examId);
      let res = await axios({
        url: `/api/exam/getExamResult/${examId}`,
        method: "GET",
      });
      if (res.data) {
        dispatch({
          type: "RESULT",
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  })();
};
export const getListOfExam = (user, skip = 0) => (dispatch) => {
  (async () => {
    let userId = user._id;
    let res = await axios({
      method: "GET",
      url: `/api/exam/getListOfExamByStudent/${userId}?skip=${skip}`,
    });
    if (res.data) {
      dispatch({
        type: "GET_EXAM_BY_STUDENT",
        payload: res.data,
      });
    }
  })();
};
