const initState = {
  info: [],
  studentData: [],
  totalStudent: 0,
  blogs: [],
  totalBlog: 0,
  unReadCommentCount: 0,
  feedbacks: [],
  examFeedbacks: [],
  unReadComment: [],
  feedbackMsg: null,
  feedbackMsg2: null,
  students: [],
  studentsMsg: null,
  comments: [],
  commentsMsg: null,
};

const instructorReducer = (state = initState, action) => {
  switch (action.type) {
    case "COMMENT_COUNT":
      return {
        ...state,
        unReadCommentCount: action.payload,
      };
    case "STUDENT_LIST_IN_COURSE":
      return {
        ...state,
        students: action.payload,
        studentsMsg: action.payload.length > 0 ? null : "No record found",
      };
    case "MORE_STUDENT_LIST_IN_COURSE":
      if (action.payload.length > 0) {
        return {
          ...state,
          students: [...state.students, ...action.payload],
          studentsMsg: null,
        };
      } else {
        return {
          ...state,
          studentsMsg: "No more students",
        };
      }

    case "COMMENT_LIST_IN_COURSE":
      return {
        ...state,
        comments: action.payload,
        commentsMsg: action.payload.length > 0 ? null : "No record found",
      };

    case "MORE_COMMENT_LIST_IN_COURSE":
      if (action.payload.length > 0) {
        return {
          ...state,
          comments: [...state.commentss, ...action.payload],
          commentsMsg: null,
        };
      } else {
        return {
          ...state,
          commentsMsg: "No more students",
        };
      }

    case "REMOVE_STUDENT":
      let tmpe = state.students;
      let idx = tmpe.findIndex((stu) => stu._id === action.payload);
      if (idx !== -1) {
        tmpe.splice(idx, 1);
      }
      return { ...state, students: [...tmpe] };
    case "ADD_STUDENT":
      return {
        ...state,
        students: [...state.students, { ...action.payload }],
      };
    case "MARK_AS_READ":
      let tmp = state.unReadComment;
      let index = state.unReadComment.findIndex(
        (cmt) => cmt._id === action.payload
      );
      if (tmp !== -1) {
        tmp.splice(index, 1);
      }
      return {
        ...state,
        unReadComment: [...tmp],
        unReadCommentCount: tmp.length,
      };
    case "UNREAD_COMMENTS":
      return {
        ...state,
        unReadComment: action.payload,
        unReadCommentCount: action.payload.length ?? 0,
      };
    case "DELETE_COMMENT_BY_INSTRUCTOR":
      let tmp2 = state.unReadComment;
      let index2 = state.unReadComment.findIndex(
        (cmt) => cmt._id === action.payload
      );
      if (tmp2 !== -1) {
        tmp2.splice(index2, 1);
      }
      return {
        ...state,
        unReadComment: [...tmp2],
        unReadCommentCount: tmp2.length,
      };
    case "GET_INFO":
      let temp = null;
      if (action.payload.revenue.byDate.length > 7) {
        let temp2 = action.payload.revenue.byDate.reverse();
        let temp3 = temp2.slice(0, 7);
        temp = {
          ...action.payload,
          revenue: {
            total: action.payload.revenue.total,
            byDate: temp3,
            currentMonth: action.payload.revenue.currentMonth,
          },
        };
      } else {
        temp = action.payload;
      }
      let count = 0;
      action.payload.students.forEach((el) => {
        let d1 = new Date(el.dateOfJoining); //student enroll date
        let d2 = new Date(); // today
        let currentMonth = d2.getMonth(); // use to calculate current month transactions
        let currentMonth2 = d1.getMonth();
        if (currentMonth === currentMonth2) {
          count = count + 1;
        }
      });
      let likes = action.payload.blog.totalLikes;
      let dislikes = action.payload.blog.totalDislikes;
      let b = [
        { type: "Likes", value: likes },
        { type: "Dislikes", value: dislikes },
      ];
      return {
        ...state,
        info: temp,
        blogs: b,
        totalBlog: action.payload.blog.totalBlogs,
        studentData: [
          {
            student: "Rest Students",
            val: action.payload.students.length - count,
          },
          { student: "This Month", val: count },
        ],
        totalStudent: action.payload.students.length,
        reload: false,
      };
    case "FEEDBACK_LIST":
      return {
        ...state,
        feedbacks: action.payload,
        feedbackMsg: null,
      };
    case "FEEDBACK_EXAM_LIST":
      return {
        ...state,
        examFeedbacks: action.payload,
        feedbaclMsg2: null,
      };
    case "LOAD_MORE_FEEDBACK":
      if (action.payload.length > 0) {
        let fd = [...state.feedbacks, ...action.payload];
        return {
          ...state,
          feedbacks: fd,
        };
      } else {
        return {
          ...state,
          feedbackMsg: "No more data",
        };
      }
    case "LOAD_MORE_EXAM_FEEDBACK":
      if (action.payload.length > 0) {
        let fd = [...state.examFeedbacks, ...action.payload];
        return {
          ...state,
          examFeedbacks: fd,
        };
      } else {
        return {
          ...state,
          feedbackMsg2: "No more data",
        };
      }
    case "CHANGE_HITS":
      let tmp3 = state.unReadComment;
      let index3 = state.unReadComment.findIndex((cmt2) => {
        return cmt2._id === action.payload.cmt._id;
      });
      if (index3 !== -1) {
        tmp3[index3].likes = action.payload.cmt.likes;
        tmp3[index3].loves = action.payload.cmt.loves;
        tmp3[index3].total = action.payload.cmt.total;
      }
      return {
        ...state,
        unReadComment: [...tmp3],
        unReadCommentCount: tmp3.length,
      };
    default:
      return state;
  }
};

export default instructorReducer;
