const initState = {
  listOfCourse: [],
  courseDetails: {},
  adminCourseData: {},
  courseUploader: {},
  subject: [],
  language: [],
  instructorCourse: {},
  msg: null,
  certificate: null,
  searchedCourse: [],
  msg2: null, //? search course related msg
  comments: [],
  msg3: null,
};

const courseReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_ALL_COURSES":
      return {
        ...state,
        listOfCourse: action.payload,
        certificate: null,
      };
    case "SET_INSTRUCTOR_COURSES":
      return {
        ...state,
        instructorCourse: action.payload,
        certificate: null,
      };
    case "LOAD_INSTRUCTOR_COURSES":
      let tmp = [...state.instructorCourse, ...action.payload];
      let msg = "";
      if (action.payload.length === 0) {
        msg = "No more data";
      }
      return {
        ...state,
        instructorCourse: [...tmp],
        msg: msg,
        certificate: null,
      };
    case "SET_NEW_COURSE":
      let listOfCourse = state.listOfCourse;
      listOfCourse.push(action.payload);
      return {
        ...state,
        listOfCourse,
        certificate: null,
      };
    case "SET_COURSE_DETAILS":
      return {
        ...state,
        courseDetails: action.payload,
        certificate: null,
      };
    case "SET_ADMIN_COURSE_DETAILS":
      return {
        ...state,
        adminCourseData: action.payload,
        certificate: null,
      };
    case "CLEAR_OLD_COURSE_DATA":
      return {
        ...state,
        adminCourseData: {},
      };
    case "SET_INSTRUCTOR_COURSE_DETAILS":
      return {
        ...state,
        adminCourseData: action.payload,
        certificate: null,
      };
    case "SET_UPLOADER":
      return {
        ...state,
        courseUploader: action.payload,
      };
    case "LIST":
      return {
        ...state,
        subject: action.payload.subject,
        language: action.payload.language,
      };
    case "DELETE_COURSE":
      let tmp4 = state.listOfCourse.filter(
        (course) => course._id !== action.payload
      );
      let tmp5 =
        Object.keys(state.instructorCourse).length !== 0
          ? state.instructorCourse.filter(
              (course) => course._id !== action.payload
            )
          : state.instructorCourse;
      return {
        ...state,
        listOfCourse: [...tmp4],
        instructorCourse:
          Object.keys(state.instructorCourse).length !== 0 ? [...tmp5] : tmp5,
      };
    case "CERTIFICATE_CHECK":
      return {
        ...state,
        certificate: action.payload,
      };
    case "UPDATE_COURSE":
      let tmp40 = state.listOfCourse.filter(
        (course) => course._id !== action.payload._id
      );
      tmp40.push(action.payload);
      return {
        ...state,
        listOfCourse: [...tmp40],
      };
    case "COURSE_FOUND":
      return {
        ...state,
        searchedCourse: [...action.payload],
        msg2: null,
      };

    case "NO_COURSE_FOUND":
      return {
        ...state,
        msg2: "No Course found",
        searchedCourse: [],
      };
    case "GET_COMMENTS":
      return {
        ...state,
        comments: [...action.payload],
        msg3: null,
      };
    case "LOAD_MORE_COMMENTS":
      if (action.payload.length > 0) {
        return {
          ...state,
          comments: [...state.comments, ...action.payload],
        };
      } else {
        return {
          ...state,
          msg3: "No more comments",
        };
      }
    case "POST_COMMENT":
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    case "DELETE_COMMENT":
      let index = state.comments.findIndex((cmt) => cmt._id === action.payload);
      let tmp58 = state.comments;

      if (index !== -1) {
        tmp58.splice(index, 1);
      }
      return {
        ...state,
        comments: [...tmp58],
      };
    default:
      return state;
  }
};

export default courseReducer;
