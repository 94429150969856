import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip as BootstrapToolTip } from "@material-ui/core";
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    border: "2px solid #f57e21",
  },
}));

const Tooltip = (props) => {
  const classes = useStylesBootstrap();
  return <BootstrapToolTip arrow classes={classes} {...props} />;
};

export default Tooltip;
