const initState = {
  blogFrom: {}, //use to create blog
  msg: null,
  msg2: null,
  blogs: [], //all public blog
  blog: [], // single public blog
  instructorBlogs: [], // it has all public private bog by instructor
  privateBlog: [], // hold private blog
};

const blogReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_BLOG":
      return {
        ...state,
        blogForm: action.payload,
        msg: "Blog created successfully",
      };
    case "FETCH_BLOGS":
      return {
        ...state,
        blogs: action.payload,
      };
    case "LOAD_MORE_BLOGS": {
      let msg2 = "";
      if (action.payload.length === 0) {
        msg2 = "No more blogs";
      }
      return {
        ...state,
        blogs: [...state.blogs, ...action.payload],
        msg2: msg2,
      };
    }
    case "PUBLIC_BLOG":
      return {
        ...state,
        blog: action.payload,
      };
    case "PRIVATE_BLOG":
      let tep = [];
      tep.push(action.payload);
      return {
        ...state,
        privateBlog: tep,
      };
    case "FETCH_INSTRUCTOR_BLOGS":
      let temp2a = null;
      if (action.status) {
        temp2a = [...action.payload, ...state.instructorBlogs];
      } else {
        temp2a = [...state.instructorBlogs, ...action.payload];
      }
      // remove duplicate blogs which may come after load more blogs option
      let temp2b = temp2a.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );

      return {
        ...state,
        instructorBlogs: temp2b,
      };
    case "LIKE_OR_DISLIKE":
      if (action.payload.visibility === false) {
        let temp = [];
        temp.push(action.payload);
        return {
          ...state,
          privateBlog: temp, //update like or dislike count
        };
      } else {
        let temp = [];
        temp.push(action.payload);
        return {
          ...state,
          blog: temp, //update like or dislike count
        };
      }
    case "UPDATE_BLOG":
      return {
        ...state,
        msg: "Updated Blog Succesfully",
        blogForm: action.payload,
      };
    case "DELETE_BLOG":
      // console.log(action.payload);
      let temp1 = state.blogs.filter((el) => {
        return el._id !== action.payload;
      });
      let temp2 = state.instructorBlogs.filter((el) => {
        return el._id !== action.payload;
      });
      return {
        ...state,
        msg: "Deleted Blog Successfully",
        blogs: temp1,
        instructorBlogs: temp2,
      };
    default:
      return state;
  }
};

export default blogReducer;
