import React from "react";
import { IconButton } from "@material-ui/core";
import "./NextPrev.scss";

const NextPrev = ({ next, prev, changeComponent }) => {
  return (
    <div className="row bg-white rounded py-2">
      <div className="col-6 text-left">
        <IconButton size="small" onClick={() => changeComponent(prev)}>
          <i className="fas fa-long-arrow-alt-left icons text-left"></i>
        </IconButton>
      </div>
      <div className="col-6 text-right">
        <IconButton size="small" onClick={() => changeComponent(next)}>
          <i className="fas fa-long-arrow-alt-right text-right icons2"></i>
        </IconButton>
      </div>
    </div>
  );
};

export default NextPrev;
