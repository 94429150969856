const initState = {
  disapprovedCourses: [],
  msg: null, // it tell that there is no more unapproved courses
  approvedCourses: [],
  course: null,
  sections: null,
  videos: null,
  msg2: null,
  commentList: null,
  msg3: "",
  blogsList: null,
  msg4: "",
  status: "",
  status2: false,
};

const moderatorReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "DISAPPROVED_COURSE":
      return {
        ...state,
        disapprovedCourses: [...payload],
      };
    case "LOAD_DISAPPROVED_COURSE":
      let msg = "";
      if (payload.length === 0) {
        msg = "No more courses";
      }
      return {
        ...state,
        disapprovedCourses: [...state.disapprovedCourses, ...payload],
        msg,
      };
    case "GET_COMMENT_BY_MODERATOR":
      return {
        commentList: [...payload],
      };

    case "LOAD_MORE_COMMENT":
      if (payload.length > 0) {
        return {
          commentList: [...state.commentList, ...payload],
        };
      } else {
        return {
          commentList: [...state.commentList],
          msg3: "No more comments",
        };
      }

    case "GET_BLOGS_BY_MOD":
      return {
        blogsList: [...payload],
      };

    case "LOAD_BLOGS_BY_MOD":
      if (payload.length > 0) {
        return {
          blogsList: [...state.blogsList, ...payload],
        };
      } else {
        return {
          blogsList: [...state.blogsList],
          msg4: "No more blogs",
        };
      }
    case "CLEAR_STATUS2":
      return {
        ...state,
        status2: false,
      };
    case "DELETE_BLOG_BY_MOD":
      let tmp4 = state.blogsList.filter((blog) => blog._id !== payload);
      return {
        ...state,
        blogsList: [...tmp4],
      };

    case "CHANGE_BLOG_STATUS_BY_MOD":
      let index = state.blogsList.findIndex(
        (blog) => blog._id === payload.blogId
      );
      if (index !== -1) {
        state.blogsList[index].disable = payload.status;
        state.blogsList[index].blogStatus = "";
        if (payload.status === false) {
          state.blogsList[index].disapprovalReason = "";
        }
        return {
          ...state,
          blogsList: [...state.blogsList],
        };
      } else {
        return {
          ...state,
        };
      }
    case "DISAPPROVE_BLOG":
      let index2 = state.blogsList.findIndex(
        (blog) => blog._id === payload.blogId
      );
      if (index2 !== -1) {
        state.blogsList[index2].disapprovalReason = payload.changes;
        return {
          ...state,
          blogsList: [...state.blogsList],
          status2: true,
        };
      } else {
        return {
          ...state,
        };
      }
    case "LOAD_APPROVED_COURSE":
      let msg2 = "";
      if (payload.length === 0) {
        msg2 = "No more courses";
      }
      return {
        ...state,
        approvedCourses: [...state.approvedCourses, ...payload],
        msg2,
      };
    case "APPROVED_COURSE":
      return {
        ...state,
        approvedCourses: [...payload],
      };
    case "CHANGE_COURSE_STATUS":
      if (payload.status === true && !payload.reject) {
        //move course from approved to disapproved
        let crs = [];
        let tmp2 = state.approvedCourses;
        let index2 = tmp2.findIndex((crs) => crs._id === payload.courseId);
        if (index2 !== -1) {
          crs = tmp2.splice(index2, 1);
          crs[0].disable = true;
          crs[0].disapprovalReason = payload.reason;
          crs[0].courseStatus = payload.courseStatus;
          crs[0].changes = payload.changes;
        }
        let tmp3 = [...crs, ...state.disapprovedCourses];
        return {
          ...state,
          disapprovedCourses: [...tmp3],
          approvedCourses: [...tmp2],
          status: "Disapproved and info sent to instructor of the course.",
        };
      } else if (payload.status === true && payload.reject) {
        let tmp2 = state.disapprovedCourses;
        let index2 = tmp2.findIndex((c) => c._id === payload.courseId);
        if (index2 !== -1) {
          tmp2[index2].disapprovalReason = payload.reason;
          tmp2[index2].courseStatus = payload.courseStatus;
        }
        return {
          ...state,
          status: "Disapproved and info sent to instructor of the course.",
          disapprovedCourses: [...tmp2],
        };
      } else {
        let crs = [];
        let tmp2 = state.disapprovedCourses;
        let index2 = tmp2.findIndex((crs) => crs._id === payload.courseId);
        if (index2 !== -1) {
          crs = tmp2.splice(index2, 1);
          crs[0].disable = false;
          crs[0].courseStatus = payload.courseStatus;
          crs[0].changes = payload.changes;
          crs[0].disapprovalReason = payload.reason;
        }
        let tmp3 = [...crs, ...state.approvedCourses];
        return {
          ...state,
          disapprovedCourses: [...tmp2],
          approvedCourses: [...tmp3],
          status: "Disapproved and info sent to instructor of the course.",
        };
      }
    case "CLEAR_STATUS":
      return {
        ...state,
        status: "",
      };
    case "VIEW_COURSE":
      return {
        ...state,
        course: payload,
      };
    case "COURSE_SECTION":
      return {
        ...state,
        sections: payload,
        content: null,
      };
    case "COURSE_CONTENT":
      return {
        ...state,
        content: payload,
        sections: null,
      };
    case "DELETE_COMMENT_BY_MOD":
      let tmp5 = state.commentList.filter((cmt) => cmt._id !== payload);
      return {
        ...state,
        commentList: [...tmp5],
      };
    default:
      return {
        ...state,
      };
  }
};
export default moderatorReducer;
