import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { instructorCourseDetails } from "../../../store/actions/courseActions";
import { getUser } from "../../../store/actions/userActions";
import CourseEditForm from "../../course/courseManage/CourseEditForm";
import EditIcon from "@material-ui/icons/Edit";
import { Typography, CircularProgress } from "@material-ui/core";
import Meta from "../../layout/Meta";

const InstructorEditCourse = ({ match }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const courseData = useSelector((state) => state.course.adminCourseData);

  useEffect(() => {
    if (user) {
      dispatch(getUser(user._id));
      dispatch(instructorCourseDetails(match.params.courseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-4 pb-4">
      <div className="container-md py-4">
        <header>
          <Typography
            variant="h6"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Edit course <EditIcon fontSize="small" />
          </Typography>
          <Meta title="Edit course | Jaiganpati.com" />
        </header>
        {courseData.cData && courseData.vData && courseData.vData.length > 0 ? (
          <CourseEditForm
            courseData={courseData}
            courseId={match.params.courseId}
          />
        ) : (
          <div align="center">
            <CircularProgress variant="indeterminate" color="primary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default InstructorEditCourse;
