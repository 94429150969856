import { combineReducers } from "redux";
import authReducer from "./authReducer";
import courseReducer from "./courseReducer";
import adminReducer from "./adminReducer";
import templeReducer from "./templeReducer";
import instructorReducer from "./instructorReducer";
import blogReducer from "./blogReducer";
import quizReducer from "./quizReducer";
import instituteReducer from "./instituteReducer";
import examReducer from "./examReducer";
import betaProgramReducer from "./betaProgramReducer";
import policyReducer from "./policyReducer";
import moderatorReducer from "./moderatorReducer";
import paymentReducer from "./paymentReducer";
import priestReducer from "./priestReducer";
import eventReducer from "./eventReducer";
import yogaBuddyReducer from "./yogaBuddyReducer";
import shareCardReducer from "./shareCardReducer";

export default combineReducers({
  auth: authReducer,
  course: courseReducer,
  admin: adminReducer,
  temple: templeReducer,
  instructor: instructorReducer,
  blog: blogReducer,
  institute: instituteReducer,
  quiz: quizReducer,
  exam: examReducer,
  beta: betaProgramReducer,
  policy: policyReducer,
  moderator: moderatorReducer,
  payment: paymentReducer,
  priest: priestReducer,
  events: eventReducer,
  yogaBuddy: yogaBuddyReducer,
  shareCard: shareCardReducer,
});
