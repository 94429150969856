import axios from "axios";
import { Status } from "../../components/layout/Alerts";
import storageCheck from "../../helpers/storageCheck";

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (
    err.response.status === 401 ||
    err.response.data.message === "401 Unauthorized"
  ) {
    console.log("axios -> jwt expired");
    window.location.href = "/signin";
  }
  return Promise.reject(err);
});

export const getUser = (id) => (dispatch) => {
  axios({
    method: "get",
    url: `/api/user/${id}`,
  })
    .then((res) => {
      //localStorage.removeItem("user");
      localStorage.removeItem("anwser");
      localStorage.removeItem("result");
      //localStorage.setItem("user", JSON.stringify(res.data));
      dispatch({
        type: "SET_PROFILE",
        payload: res.data,
      });
      storageCheck();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editUser = (editedUserData, id, setStatus) => (dispatch) => {
  axios({
    method: "post",
    url: `/api/user/updateProfile/${id}`,
    data: editedUserData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      setStatus({ msg: "Saved Successfully", val: false });
      dispatch({
        type: "SET_CURRENT_USER",
        payload: res.data.data,
      });
      caches.delete("profile");
    })
    .catch((err) => {
      console.log(err);
      setStatus({ msg: err.response.data.msg, val: false });
      dispatch({
        type: "EDIT_ERROR",
        err,
      });
    });
};

function getUserImage(id, dispatch, newCache) {
  axios({
    method: "get",
    url: `/api/user/image/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      dispatch({
        type: "SET_PROFILE_PIC",
        payload: "data:image/png;base64," + res.data,
      });
      if (newCache !== undefined) {
        const option = {
          headers: {
            "Content-Type": "text/plain",
            date: new Date(),
          },
        };
        const imageResponse = new Response(
          "data:image/png;base64," + res.data,
          option
        );
        newCache.put(`/img_${id}`, imageResponse);
      }
    })
    .catch((err) => {
      if ("caches" in window) {
        caches.delete("profile");
      }
      dispatch({
        type: "EDIT_ERROR",
        err,
      });
    });
}

export const getImage = (id) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const cache = await caches.open("profile");
        const request = new Request(`/img_${id}`);
        const response = await cache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 5 minute
          if (Date.now() > date.getTime() + 1000 * 60 * 5) {
            console.log("ProfilePic Cache Expired!");
            const request2 = new Request(`/img_${id}`);
            cache.delete(request2);
            getUserImage(id, dispatch, cache);
          } else {
            console.log("profilePic cache");
            let img = await response.text();
            dispatch({
              type: "SET_PROFILE_PIC",
              payload: img,
            });
          }
        } else {
          getUserImage(id, dispatch, cache);
        }
      } else {
        getUserImage(id, dispatch);
      }
    } catch (error) {
      getUserImage(id, dispatch);
    }
  })();
};

export const getUserSignInLogs = (userId) => (dispatch) => {
  (async () => {
    try {
      let logs = await axios.get(`/api/activity/logs/${userId}`);
      if (logs) {
        //console.log(logs);
        dispatch({ type: "SIGNIN_LOGS", payload: logs.data[0] });
      }
    } catch (error) {
      console.log(error);
    }
  })();
};

async function fetchUserLeaderboard(skip, dispatch, newCache = undefined) {
  try {
    let res = await axios({
      method: "get",
      url: `/api/activity/getToppers?skip=${skip}`,
    });
    if (res) {
      console.log("fetch user leaderboard");
      if (skip > 0) {
        dispatch({ type: "LOAD_USER_LEADERBOARD", payload: res.data });
      } else {
        dispatch({ type: "USER_LEADERBOARD", payload: res.data });
      }
      if (newCache !== undefined) {
        const options = {
          headers: {
            "Content-Type": "application/json",
            date: new Date(),
          },
        };
        newCache.put(
          `/user_leadeerborad_${skip}`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getUserLeaderboard = (skip = 0) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const cache = await caches.open("profile");
        const request = new Request(`/user_leaderboard_${skip}`);
        const response = await cache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 2 minute
          if (Date.now() > date.getTime() + 1000 * 60 * 2) {
            console.log("User leaderboard Cache Expired!");
            cache.delete(request);
            fetchUserLeaderboard(skip, dispatch, cache);
          } else {
            console.log("User leaderboard cache");
            let data = await response.json();
            if (skip > 0) {
              dispatch({ type: "LOAD_USER_LEADERBOARD", payload: data });
            } else {
              dispatch({ type: "USER_LEADERBOARD", payload: data });
            }
          }
        } else {
          fetchUserLeaderboard(skip, dispatch, cache);
        }
      } else {
        fetchUserLeaderboard(skip, dispatch);
      }
    } catch (error) {
      fetchUserLeaderboard(skip, dispatch);
    }
  })();
};

async function fetchInstituteLeaderboard(skip, dispatch, newCache = undefined) {
  try {
    let res = await axios({
      method: "get",
      url: `/api/activity/topInstitutions?skip=${skip}`,
    });
    if (res) {
      console.log("fetch institute leaderboard");
      if (skip > 0) {
        dispatch({ type: "LOAD_INSTITUTE_LEADERBOARD", payload: res.data });
      } else {
        dispatch({ type: "INSTITUTE_LEADERBOARD", payload: res.data });
      }
      if (newCache !== undefined) {
        const options = {
          headers: {
            "Content-Type": "application/json",
            date: new Date(),
          },
        };

        newCache.put(
          `/institute_leaderboard_${skip}`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getInstituteLeaderboard = (skip = 0) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const cache = await caches.open("profile");
        const request = new Request(`/institute_leaderboard_${skip}`);
        const response = await cache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 2 minute
          if (Date.now() > date.getTime() + 1000 * 60 * 2) {
            console.log("Institute leaderboard Cache Expired!");
            cache.delete(request);
            fetchInstituteLeaderboard(skip, dispatch, cache);
          } else {
            console.log("Institute leaderboard cache");
            let data = await response.json();
            if (skip > 0) {
              dispatch({ type: "LOAD_INSTITUTE_LEADERBOARD", payload: data });
            } else {
              dispatch({ type: "INSTITUTE_LEADERBOARD", payload: data });
            }
          }
        } else {
          fetchInstituteLeaderboard(skip, dispatch, cache);
        }
      } else {
        fetchInstituteLeaderboard(skip, dispatch);
      }
    } catch (error) {
      fetchInstituteLeaderboard(skip, dispatch);
    }
  })();
};

export const becomeInstructor = ({ userId, history }) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/user/becomeInstructor/${userId}`);
      if (res) {
        //console.log(res.data);
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(res.data.usr));
        dispatch({ type: "BECOME_INSTRUCTOR" });
        Status({ text: "<h5>Now You are instructor.</h5>", type: "success" });
        history.push("/");
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data) {
        Status({
          text: `<h5>${err.response.data.msg}</h5>`,
          type: "error",
        });
      } else {
        Status({ text: `<h5>Something went wrong.</h5>`, type: "error" });
      }
    }
  })();
};
