const initState = {
  exam: null,
  file: null,
  result: null,
  listOfExam: null,
};

const examReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CREATE_EXAM":
      return {
        ...state,
        exam: payload,
      };
    case "GET_EXAM_DATA":
      return {
        ...state,
        exam: payload,
        result: null,
      };
    case "GET_EXAM_BY_STUDENT":
      return {
        ...state,
        listOfExam: payload,
      };
    case "FILE":
      return {
        ...state,
        file: payload,
      };
    case "DELETE_EXAM":
      return {
        ...state,
        exam: null,
      };
    case "RESULT":
      return {
        ...state,
        result: payload,
      };
    default:
      return state;
  }
};

export default examReducer;
