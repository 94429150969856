import React from "react";
import "./styles.scss";
import { Breadcrumbs, useMediaQuery } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const SideBar = ({ options, event, active }) => {
  let device = useMediaQuery("(max-width:600px)");

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      component="nav"
      separator={<NavigateNextIcon fontSize="small" />}
      maxItems={device ? 4 : 10}
      className="sidenav bg-white rounded py-2 px-1"
    >
      {options.map((el, i) => {
        return (
          <div
            className="side-nav-item"
            onClick={() => event(el.component)}
            key={i}
          >
            <span
              className={
                el.component === active ? "live py-2 pr-1" : "py-2 pr-1"
              }
            >
              {el.icon}
            </span>
            <span className={el.component === active ? "live py-2" : "py-2"}>
              {el.name}
            </span>
          </div>
        );
      })}
    </Breadcrumbs>
  );
};

export default SideBar;
