import React, { useEffect } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { checkCart } from "../../store/actions/paymentAction";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `0px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const Cart = () => {
  const dispatch = useDispatch();
  const cartCount = useSelector((state) => state.payment.cartCount);
  useEffect(() => {
    dispatch(checkCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <IconButton color="secondary" size="small" aria-label="cart">
      <StyledBadge badgeContent={cartCount} color="primary">
        <ShoppingCartOutlinedIcon className="text-primary" />
      </StyledBadge>
    </IconButton>
  );
};

export default Cart;
