import React, { useState } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {
  IconButton,
  Typography,
  ButtonGroup,
  Chip,
  Collapse,
} from "@material-ui/core";
import ReactHtml from "raw-html-react";
import "./styles.scss";

const SelectedContent = ({
  sections,
  editContentFromSection,
  editSection,
  deleteSection,
  removeSectionContent,
  disableEditButton = false,
  swapContent,
}) => {
  return (
    <div className="selectedContent">
      {sections
        ? sections.map((section, k) => (
            <SectionTab
              key={k}
              sectionIndex={k}
              section={section}
              editSection={editSection}
              deleteSection={deleteSection}
              editContentFromSection={editContentFromSection}
              removeSectionContent={removeSectionContent}
              disableEditButton={disableEditButton}
              swapContent={swapContent}
            />
          ))
        : null}
    </div>
  );
};

export default SelectedContent;

const SectionTab = ({
  section,
  editSection,
  deleteSection,
  disableEditButton,
  editContentFromSection,
  removeSectionContent,
  sectionIndex,
  swapContent,
}) => (
  <div className="py-2 px-1 mb-2 card border-1 rounded bg-white">
    <div className="form-row px-2">
      <div className="col-md-10">
        <Typography gutterBottom variant="body1">
          {section.sectionNo + ""}.){" "}
          {section.title.length > 40
            ? section.title.substr(0, 40) + "..."
            : section.title}
        </Typography>
      </div>
      <div className="col-md-2">
        <ButtonGroup
          size="small"
          component="div"
          variant="text"
          color="primary"
        >
          <IconButton
            size="small"
            disabled={disableEditButton}
            onClick={() => {
              editSection(section.sectionNo);
            }}
          >
            <EditOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              deleteSection(section.sectionNo);
            }}
            size="small"
          >
            <DeleteForeverOutlinedIcon fontSize="small" color="error" />
          </IconButton>
        </ButtonGroup>
      </div>
    </div>

    <div className="px-1">
      <ReactHtml
        html={section.description}
        component={<Typography paragraph />}
      />
    </div>

    <ContentList
      editContentFromSection={editContentFromSection}
      removeSectionContent={removeSectionContent}
      contents={section.contents}
      disableEditButton={disableEditButton}
      sectionNo={section.sectionNo}
      sectionIndex={sectionIndex}
      swapContent={swapContent}
    />
  </div>
);

const ContentList = ({
  contents,
  sectionNo,
  disableEditButton,
  editContentFromSection,
  removeSectionContent,
  sectionIndex,
  swapContent,
}) => {
  const handleDrag = (e) => {
    let index = e.target.getAttribute("data-index");
    let sequenceNo = e.target.getAttribute("data-sequenceno");
    let data = JSON.stringify({
      index,
      sequenceNo,
      sectionIndex,
      fromSectionNo: sectionNo,
    });
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", data);
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    let droppedSequenceNo = e.target.getAttribute("data-sequenceno");
    let droppedIndex = e.target.getAttribute("data-index");
    let { index, sequenceNo, sectionIndex, fromSectionNo } = JSON.parse(
      e.dataTransfer.getData("text/plain")
    );
    console.log(fromSectionNo, sectionNo);
    console.log(
      `sectionIndex: ${sectionIndex}| fromIndex: ${index}|
      fromSequence: ${sequenceNo}| toIndex: ${droppedIndex}|
      toSequence: ${droppedSequenceNo} `
    );
    sectionIndex = parseInt(sectionIndex);
    index = parseInt(index);
    droppedIndex = parseInt(droppedIndex);
    droppedSequenceNo = parseInt(droppedSequenceNo);
    sequenceNo = parseInt(sequenceNo);
    if (
      sectionIndex === undefined ||
      sectionIndex === null ||
      isNaN(sectionIndex) ||
      index === undefined ||
      index === null ||
      isNaN(index) ||
      droppedSequenceNo === undefined ||
      droppedSequenceNo === null ||
      isNaN(droppedSequenceNo) ||
      droppedIndex === undefined ||
      droppedIndex === null ||
      isNaN(droppedIndex) ||
      sequenceNo === undefined ||
      sequenceNo === null ||
      isNaN(sequenceNo)
    ) {
      return;
    }
    if (droppedSequenceNo === sequenceNo) {
      return;
      // do not swap on same content
    }
    if (fromSectionNo !== sectionNo) {
      //don't swap with other section content
      return;
    }
    swapContent({
      sectionIndex,
      fromIndex: index,
      fromSequenceNo: sequenceNo,
      toIndex: droppedIndex,
      toSequenceNo: droppedSequenceNo,
    });
    e.dataTransfer.clearData();
  };

  return (
    <div className="py-2">
      {contents &&
        contents.length > 0 &&
        contents.map((content, i) => {
          return (
            <ContentTab
              index={i}
              removeSectionContent={removeSectionContent}
              disableEditButton={disableEditButton}
              editContentFromSection={editContentFromSection}
              sectionNo={sectionNo}
              content={content}
              key={i}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
            />
          );
        })}
    </div>
  );
};

const ContentTab = ({
  content,
  sectionNo,
  index,
  disableEditButton,
  removeSectionContent,
  editContentFromSection,
  handleDrag,
  handleDrop,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <section
      draggable={true}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
      data-index={index}
      className="my-2 px-1 list-item rounded"
    >
      <div
        data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
        data-index={index}
        className="form-row rounded py-1"
        style={{ backgroundColor: "#f5f5f8f8" }}
      >
        <div
          data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
          data-index={index}
          className="col-md-10"
        >
          <Typography
            data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
            data-index={index}
            gutterBottom
            variant="subtitle2"
          >
            {content.sequenceNo ?? content.video.sequenceNo}).{" "}
            {content.videoTitle ?? content.video.videoTitle}
          </Typography>
        </div>
        <div
          data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
          data-index={index}
          className="col-md-2"
        >
          <ButtonGroup
            size="small"
            component="div"
            variant="text"
            color="primary"
          >
            <IconButton
              onClick={() => setOpen(!open)}
              size="small"
              color="primary"
            >
              <span
                className="text-dark"
                style={{ fontSize: "14px", fontWeight: "bolder" }}
              >
                &#8675;
              </span>
            </IconButton>
            <IconButton
              size="small"
              disabled={disableEditButton}
              onClick={() => {
                editContentFromSection(
                  content.sequenceNo ?? content.video.sequenceNo,
                  sectionNo
                );
              }}
            >
              <EditOutlinedIcon color="primary" fontSize="small" />
            </IconButton>
            <IconButton
              data-sequenceno={content.sequenceNo ?? content.video.sequenceNo}
              data-index={index}
              size="small"
              onClick={() => {
                removeSectionContent(
                  content.sequenceNo ?? content.video.sequenceNo,
                  sectionNo
                );
              }}
            >
              <DeleteForeverOutlinedIcon
                fontSize="small"
                className="text-primary"
              />
            </IconButton>
          </ButtonGroup>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <section className="py-2 px-1">
          <Typography gutterBottom variant="overline">
            {content.videoDuration ?? content.video.videoDuration} mins
          </Typography>
          <br />
          <Typography gutterBottom variant="overline">
            Content-Type: {content.videoType ?? content.video.videoType}
          </Typography>
          {(content.videoType ?? content.video.videoType) ===
          "text" ? null : content.uploadable ?? content.video.uploadable ? (
            <Typography gutterBottom variant="subtitle2">
              {content.videoFileName ?? content.video.videoFileName}
            </Typography>
          ) : (
            <Typography gutterBottom variant="subtitle2">
              {content.videoURL ?? content.video.videoURL}
            </Typography>
          )}
          {content.hasAttachment ?? content.video.hasAttachment ? (
            <Typography gutterBottom variant="subtitle2">
              <AttachFileIcon fontSize="small" />{" "}
              {content.attachment
                ? content.attachment.name
                : content.video.attachment.name}
            </Typography>
          ) : null}
          {content.tags !== undefined && content.tags.length > 0
            ? content.tags.map((tag, i) => (
                <Chip
                  key={i}
                  label={tag}
                  clickable
                  size="small"
                  color="primary"
                  className="m-1"
                  variant="outlined"
                />
              ))
            : null}
          {content.video &&
          content.video.tags !== undefined &&
          content.video &&
          content.video.tags.length > 0
            ? content.video.tags.map((tag, i) => (
                <Chip
                  key={i}
                  label={tag}
                  clickable
                  size="small"
                  color="primary"
                  className="m-1"
                  variant="outlined"
                />
              ))
            : null}
          <hr />
          <ReactHtml
            html={content.videoDescription ?? content.video.videoDescription}
            componentMap={
              <Typography
                gutterBottom
                variant="subtitle2"
                className="container courseFont"
              />
            }
          />
        </section>
      </Collapse>
    </section>
  );
};
