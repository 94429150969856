const initState = {
  buddy: null,
  msg: null,
};

const yogaBuddyReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "FIND_BUDDY":
      return {
        ...state,
        buddy: payload,
        msg: null,
      };
    case "NO_BUDDY_FOUND":
      return {
        ...state,
        buddy: null,
        msg: "No yoga buddy found right now, try later.",
      };
    default:
      return state;
  }
};

export default yogaBuddyReducer;
