import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

//! Make Meta tag available for better SEO
const Meta = ({ title, url, content, imageUrl = null }) => {
  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      null,
      title
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  title = title ?? "JaiGanpati | Spiritual E-Learning Platform";
  content =
    content ??
    "Jaiganpati | A Spiritual E-Learning Platform | Explore Live Darshan Of Holy Places Around The World";
  url = url ?? window.location.href;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title data-react-helmet="true">
        {title
          ? title.toString()
          : "Jaiganpati.com | Spiritual E-Learning Platform"}
      </title>
      <meta
        name="theme-color"
        data-react-helmet="true"
        content="#d9230f"
      ></meta>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />

      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:url" content={url} />
      <meta name="twitter:card" value={title + " " + content.toString()} />
      <meta name="description" content={content.toString()} />
      <meta property="og:description" content={content.toString()} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default Meta;
