import React, { useEffect, useState } from "react";
import { IconButton, Button, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BootstrapTooltip from "../layout/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  userAcceptBetaAccess,
  userRejectBetaAccess,
} from "../../store/actions/betaProgramAction";

const BetaNotification = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && user.betaStatus) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(userRejectBetaAccess(user._id));
    setOpen(false);
  };
  const userJoin = () => {
    dispatch(userAcceptBetaAccess(user._id));
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
      message="Join Beta Access and enjoy latest features"
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={userJoin}>
            JOIN
          </Button>
          <BootstrapTooltip title="Deny">
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </BootstrapTooltip>
        </React.Fragment>
      }
    />
  );
};

export default BetaNotification;
