const initState = {
  cards: null,
  card: {},
  cardNotFound: false,
  msg: null,
};

const shareCardReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "MORE_LIST_OF_CARDS":
      if (payload.length > 0) {
        return {
          ...state,
          cards: [...state.cards, ...payload],
        };
      } else {
        return {
          ...state,
          cards: [...state.cards],
          msg: "No more data",
        };
      }
    case "LIST_OF_CARDS":
      return {
        ...state,
        cards: [...payload],
      };
    case "GET_CARD":
      return {
        ...state,
        card: { ...payload },
        cardNotFound: false,
      };
    case "CARD_NOT_FOUND":
      return {
        ...state,
        cardNotFound: true,
        card: {},
      };
    case "CREATE_CARD":
      return {
        ...state,
        cards: [...state.cards, { ...payload }],
      };
    case "EDIT_CARD":
      let temp = state.cards.filter((card) => card._id !== payload);
      return {
        ...state,
        cards: [...temp],
      };
    case "UPDATE_CARD":
      return {
        ...state,
        cards: [...state.cards, payload],
      };

    case "DELETE_CARD":
      let tmp2 = state.cards.filter((card) => card._id !== payload);
      return {
        ...state,
        cards: [...tmp2],
      };

    default:
      return state;
  }
};

export default shareCardReducer;
