import axios from "axios";
import { Status } from "../../components/layout/Alerts";
import ReactGA from "react-ga";

export const addItem = (id) => (dispatch) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cart") !== null) {
      let list = JSON.parse(localStorage.getItem("cart"));
      if (list.includes(id)) {
        console.log("item already exits");
      } else {
        list.push(id);
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(list));
        dispatch({ type: "ADD_ITEM", payload: list });
      }
    } else {
      let list = [];
      list.push(id);
      localStorage.setItem("cart", JSON.stringify(list));
      dispatch({ type: "ADD_ITEM", payload: list });
    }
  }
};

export const removeItem = (id) => (dispatch) => {
  if (localStorage.getItem("cart") !== null) {
    //? remove course from cart and also remove course from courses state
    let cartItems = JSON.parse(localStorage.getItem("cart"));
    let newCartItems = cartItems.filter((el) => {
      return id !== el;
    });
    localStorage.removeItem("cart");
    localStorage.setItem("cart", JSON.stringify(newCartItems));
    dispatch({ type: "REMOVE_ITEM", payload: { newCartItems, courseId: id } });
  }
};

export const checkCart = () => (dispatch) => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cart") !== null) {
      let cartItems = JSON.parse(localStorage.getItem("cart"));
      dispatch({ type: "CHECK_CART", payload: cartItems });
    } else {
      dispatch({ type: "CHECK_CART", payload: [] });
    }
  } else {
    dispatch({ type: "CHECK_CART", payload: [] });
  }
};

async function fetchPurachaseHistory(
  userId,
  skip,
  dispatch,
  newCache = undefined
) {
  try {
    let res = await axios.get(
      `/api/payment/transaction/${userId}?skip=${skip}`
    );
    if (res) {
      console.log("fetch transaction history");
      if (skip > 0) {
        dispatch({ type: "LOAD_TRANSACTION_HISTORY", payload: res.data });
      } else {
        dispatch({ type: "TRANSACTION_HISTORY", payload: res.data });
      }
      if (newCache !== undefined) {
        const options = {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            date: new Date(),
          }),
        };
        newCache.put(
          `/transaction-${userId}-${skip}`,
          new Response(JSON.stringify(res.data), options)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getPurachaseHistory = (userId, skip = 0) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        const request = new Request(`/transaction-${userId}-${skip}`);
        const newCache = await caches.open("profile");
        const response = await newCache.match(request);
        if (response) {
          const date = new Date(response.headers.get("date"));
          // if cached file is older than 50 seconds
          if (Date.now() > date.getTime() + 1000 * 50) {
            console.log("user transaction history overview cache Expired!");
            newCache.delete(request);
            fetchPurachaseHistory(userId, skip, dispatch, newCache);
          } else {
            console.log("cache user transaction history");
            let data = await response.json();
            if (skip > 0) {
              dispatch({ type: "LOAD_TRANSACTION_HISTORY", payload: data });
            } else {
              dispatch({ type: "TRANSACTION_HISTORY", payload: data });
            }
          }
        } else {
          fetchPurachaseHistory(userId, skip, dispatch, newCache);
        }
      } else {
        fetchPurachaseHistory(userId, skip, dispatch);
      }
    } catch (error) {
      fetchPurachaseHistory(userId, skip, dispatch);
    }
  })();
};

export const getCartCourses = () => (dispatch) => {
  (async () => {
    try {
      if (localStorage.getItem("cart") !== null) {
        let list = JSON.parse(localStorage.getItem("cart"));
        let res = await axios.post("/api/course/cartList", { list });
        if (res) {
          dispatch({ type: "CART_ITEMS", payload: res.data });
        } else {
          dispatch({ type: "CART_ERROR", payload: { msg: "No item in cart" } });
        }
      } else {
        dispatch({ type: "CART_ERROR", payload: { msg: "No item in cart" } });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "CART_ERROR",
        payload: { msg: "Something went wrong" },
      });
    }
  })();
};

export const checkCount = (userId) => (dispatch) => {
  (async () => {
    try {
      let res = await axios.get(`/api/user/checkCount/${userId}`);
      if (res.data && res.data.status) {
        console.log(res.data.msg);
        dispatch({ type: "PURCHASE_LIMIT", payload: true });
      } else {
        console.log("daily limit over");
        dispatch({ type: "PURCHASE_LIMIT", payload: false });
        dispatch({ type: "CLEAR_CART" });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({ type: "PURCHASE_LIMIT", payload: false });
      dispatch({ type: "CLEAR_CART" });
      if (error.response && error.response.data) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "info" });
      } else {
        Status({ text: `<h5>Somethign went wrong</h5>`, type: "info" });
      }
    }
  })();
};

export const buyFreeCourse = (userId, cartItems, setLoading) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        caches.delete("profile").catch((err) => console.log(err));
      }
      let res = await axios({
        method: "post",
        url: `/api/payment/freecourse/${userId}`,
        data: {
          product: cartItems,
        },
      });
      if (res.data) {
        console.log(res.data);
        localStorage.removeItem("cart");
        async function fireTracking() {
          ReactGA.plugin.require("ecommerce");
          res.data.trs &&
            res.data.trs.courses &&
            res.data.trs.courses.forEach((crs) => {
              ReactGA.plugin.execute("ecommerce", "addItem", {
                id: res.data.trs._id,
                name: crs.course.courseTitle,
                sku: crs.course._id, // item code
                price: crs.cost,
                category: "Free",
                quantity: 1,
                currency: "USD",
              });
            });
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: res.data.trs._id,
            revenue: 0,
            currency: "USD",
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactGA.plugin.execute("ecommerce", "clear");
        }
        fireTracking();
        dispatch({ type: "CLEAR_CART" });
        dispatch({ type: "TRANSACTION_COMPLETE", payload: res.data.trs });
        setLoading(false);
      } else {
        throw new Error({ msg: "Something went wrong" });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
      setLoading(false);
    }
  })();
};

export const buyCourse = (
  userId,
  cartItems,
  totalAmount,
  nonce,
  deviceData,
  instance,
  setLoading
) => (dispatch) => {
  (async () => {
    try {
      if ("caches" in window) {
        caches.delete("profile").catch((err) => console.log(err));
      }
      let res = await axios({
        method: "post",
        url: `/api/payment/checkout/${userId}`,
        data: {
          payment_method_nonce: nonce,
          total: totalAmount,
          product: cartItems,
          deviceData,
        },
      });
      if (res.data) {
        console.log(res.data);
        instance.clearSelectedPaymentMethod();
        localStorage.removeItem("cart");
        async function fireTracking() {
          ReactGA.plugin.require("ecommerce");
          res.data.trs &&
            res.data.trs.courses &&
            res.data.trs.courses.forEach((crs) => {
              ReactGA.plugin.execute("ecommerce", "addItem", {
                id: res.data.trs._id,
                name: crs.course.courseTitle,
                sku: crs.course._id, // item code
                price: crs.cost,
                category: "Paid",
                quantity: 1,
                currency: "USD",
              });
            });
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: res.data.trs._id,
            revenue: totalAmount,
            currency: "USD",
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactGA.plugin.execute("ecommerce", "clear");
        }
        fireTracking();
        dispatch({ type: "CLEAR_CART" });
        dispatch({ type: "TRANSACTION_COMPLETE", payload: res.data.trs });
        setLoading(false);
      } else {
        throw new Error({ msg: "Something went wrong" });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        Status({ text: `<h5>${error.response.data.msg}</h5>`, type: "error" });
      } else {
        Status({ text: "<h5>Something went wrong.</h5>", type: "error" });
      }
      instance.clearSelectedPaymentMethod();
      setLoading(false);
    }
  })();
};
